import {
  auth0Screens,
  authActionsSigninPath,
  authActionStates,
  authLoginPageFeatures,
  orderAheadBagIdHeader,
  subscriptionBagIdHeader
} from '#src/common/constants'
import { getAuth0Client } from '#src/state/account-state-slice'
import { GetTokenSilentlyOptions, LogoutOptions } from '@auth0/auth0-spa-js'
import { destroyCookie } from 'nookies'
import router from 'next/router'
import { clearCookiesOnLogout, setAccessTokenCookies } from '../session-id'

interface Router {
  push: (path: string) => void
  asPath: string
}

type LoginAndReturnToPath = {
  returnPath: string
  actionState?: any
  loginPageFeatures: any[]
  screen: any
}

const getQueryDelimiter = url => (url.includes('?') ? '&' : '?')

const getAuthPageFeatures = ({
  hideMarketingPreferences = false,
  shouldConfirmEmail = false,
  shouldSkipVerification = false,
  isClickAndCollectJourney = false,
  isCoffeeSubscriptionJourney = false
}): LoginAndReturnToPath['loginPageFeatures'] => {
  return [
    hideMarketingPreferences && authLoginPageFeatures.hideMarketingPreferences,
    shouldSkipVerification && authLoginPageFeatures.shouldSkipValidation,
    shouldConfirmEmail && authLoginPageFeatures.shouldConfirmEmail,
    isClickAndCollectJourney && authLoginPageFeatures.isClickAndCollectJourney,
    isCoffeeSubscriptionJourney &&
      authLoginPageFeatures.isCoffeeSubscriptionJourney
  ].filter(Boolean)
}

const getLogInProps = (
  params: GoToLogIn,
  defaultReturnPath: string
): LoginAndReturnToPath => ({
  actionState: params.sessionExpired ? authActionStates.SIGNIN_REQUIRED : null,
  loginPageFeatures: getAuthPageFeatures(params),
  returnPath: params.returnPath || defaultReturnPath,
  screen: params.passwordLinkExpired
    ? auth0Screens.PASSWORD_RESET_EXPIRED
    : null
})

const getSignUpProps = (
  params: GoToSignUp,
  defaultReturnPath: string
): LoginAndReturnToPath => ({
  loginPageFeatures: getAuthPageFeatures(params),
  returnPath: params.returnPath || defaultReturnPath,
  screen: auth0Screens.SIGNUP
})

type GetAuthUrl = {
  returnPath: string
  actionState: string
  loginPageFeatures: string[]
  screen: string
}

const getAuthUrl = ({
  returnPath,
  actionState,
  loginPageFeatures = [],
  screen
}: LoginAndReturnToPath) => {
  const completionUrl = encodeURIComponent(returnPath)
  let loginUrl = authActionsSigninPath
  if (actionState) loginUrl += `/${actionState}`
  if (completionUrl) loginUrl += `?completion-url=${completionUrl}`
  loginPageFeatures.forEach(feature => {
    loginUrl += `${getQueryDelimiter(loginUrl)}page-feature=${feature}`
  })
  if (screen) loginUrl += `${getQueryDelimiter(loginUrl)}screen=${screen}`
  return loginUrl
}

export const directingUserToLogin = (actionState?: string) => {
  deleteBagIdCookie()
  const authUrl = getAuthUrl({
    actionState: actionState,
    returnPath: router.asPath
  } as GetAuthUrl)
  window.location.assign(authUrl)
}

export const getTokenSilently = async (options?: GetTokenSilentlyOptions) => {
  try {
    const accessToken = await getAuth0Client().getTokenSilently(options)
    setAccessTokenCookies(accessToken)
    return accessToken
  } catch (e) {
    directingUserToLogin(authActionStates.SIGNIN_REQUIRED)
  }
}

type GoToSignUp = {
  returnPath?: string
  shouldSkipVerification?: boolean
  hideMarketingPreferences?: boolean
  shouldConfirmEmail?: boolean
  bagId?: string
  isClickAndCollectJourney?: boolean
  isCoffeeSubscriptionJourney?: boolean
}

export const navigateToSignUpWithRedirectUrl = (
  params: GoToSignUp = {},
  router: Router
) => {
  const signUpProps = getSignUpProps(params, router.asPath)
  const authUrl = getAuthUrl(signUpProps)
  router.push(authUrl)
}

type GoToLogIn = {
  returnPath?: string
  shouldSkipVerification?: boolean
  hideMarketingPreferences?: boolean
  shouldConfirmEmail?: boolean
  passwordLinkExpired?: boolean
  sessionExpired?: boolean
  bagId?: string
  isClickAndCollectJourney?: boolean
  isCoffeeSubscriptionJourney?: boolean
}

export const navigateToSignInWithRedirectUrl = (
  params: GoToLogIn = {},
  router: Router
) => {
  const logInProps = getLogInProps(params, router.asPath)
  const authUrl = getAuthUrl(logInProps)
  router.push(authUrl)
}

export const deleteBagIdCookie = () => {
  destroyCookie(null, orderAheadBagIdHeader, { path: '/' })
  destroyCookie(null, subscriptionBagIdHeader, { path: '/' })
}

export const auth0Logout = async (options?: LogoutOptions['logoutParams']) => {
  deleteBagIdCookie()
  clearCookiesOnLogout()
  await getAuth0Client().logout({ logoutParams: options })
}
