import { useRouter } from 'next/router'
import { Label } from '@pretamanger/component-library'
import Translation from '#src/common/components/translation'
import { quantityOptions } from '#src/common/components/bag/bag-utils'
import { StyledSelect, HiddenSelect } from './styles'

export const BagItemQuantity = ({
  id,
  handleChange,
  quantity,
  visible = true
}: BagItemQuantityProps) => {
  const { locale } = useRouter()
  const htmlId = `quantity-${id}`
  return (
    <>
      <Label id={htmlId} className='sr-only'>
        <Translation id='checkout.line.item.quantity' />
      </Label>
      {visible ? (
        <StyledSelect
          id={htmlId}
          options={quantityOptions(id, locale, quantity)}
          current={quantity}
          onChange={handleChange}
        />
      ) : (
        <HiddenSelect />
      )}
    </>
  )
}

type BagItemQuantityProps = {
  id: string
  handleChange: (string) => void
  quantity: number | string
  visible?: boolean
}
