import styled from '@emotion/styled'
import tw from 'twin.macro'
import {
  getScreenSizes,
  User,
  UserSignedIn
} from '@pretamanger/component-library'

export const SignInButton = styled('button')`
  ${tw`focus:outline-none focus:shadow-outline text-grey-700 rounded py-2 m-0`}
  &:hover {
    ${tw`bg-[#E7E4E4]`}
  }
  @media (min-width: ${getScreenSizes().md}px) {
    min-width: 6rem;
  }
`

export const MyAccountButton = styled('button')`
  ${tw`focus:outline-none focus:shadow-outline text-grey-700 rounded py-2 m-0`}
  &:hover {
    ${tw`bg-[#E7E4E4]`}
  }
  @media (min-width: ${getScreenSizes().md}px) {
    min-width: 9rem;
  }
`

export const SignInIcon = styled(User)`
  ${tw`inline-block align-bottom`}
`

export const MyAccountIcon = styled(UserSignedIn)`
  ${tw`inline-block align-bottom`}
`

export const SignInLabel = styled('span')`
  ${tw`md:inline-block align-bottom hidden pt-0`}
`

export const MyAccountLabel = styled('span')`
  ${tw`md:inline-block align-bottom hidden`}
`
