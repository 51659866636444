import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface OrderDetailsState {
  orderType?: string
  pickupTime?: string
  time?: string
  shop?: {
    address?: string
    name?: string
    city?: string
    timezoneName?: string
  }
}

const initialState: OrderDetailsState = {}

export const orderDetailsStateSlice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    loadOrderDetails: (state, action: PayloadAction<OrderDetailsState>) => ({
      ...state,
      ...action.payload
    })
  }
})

export const orderDetailsSelector = ({
  orderDetails
}: RootState): OrderDetailsState => orderDetails
