export * from './constants'
export * from './flags'

export const marketMap = [{ code: 'UK', alias: 'GB' }]

export const localeMap = [{ code: 'zh-HK', alias: 'zh-Hans-HK' }]

export const enGBCollectionURLPrefix = 'click-and-collect'
export const enUSCollectionURLPrefix = 'pick-up'
export const collectionURLPrefix = {
  'en-GB': enGBCollectionURLPrefix,
  'en-US': enUSCollectionURLPrefix,
  default: enGBCollectionURLPrefix
}
export const subscriptionURLPrefix = 'pretcoffeesub'
export const clubPretURLPrefix = 'club-pret'
export const collectionPaths = {
  checkout: 'checkout',
  'checkout-order-confirmation': 'checkout/order-confirmation',
  'checkout-order-details': 'checkout/order-details',
  'checkout-signin': 'checkout/signin',
  'select-store': 'shop-finder',
  products: 'products',
  'products-categories': 'products/categories'
}
export const outpostURLPrefix = {
  'en-GB': 'click-and-collect/outposts',
  default: 'click-and-collect/outposts'
}
export const routeDefinitionsCommon = [
  { prefix: '/', path: '/[page]' },
  { prefix: '/products', path: '/products' },
  { prefix: '/products', path: '/products/[sku]/[name]' },
  { prefix: '/products/categories', path: '/products/categories/[category]' },
  { prefix: '/shop-finder', path: '/shop-finder' }
]

export const routeDefinitionsCollection = [
  {
    prefix: `/${collectionPaths.checkout}`,
    path: `/${collectionPaths.checkout}`
  },
  {
    prefix: `/${collectionPaths['checkout-order-confirmation']}`,
    path: `/${collectionPaths['checkout-order-confirmation']}`
  },
  {
    prefix: `/${collectionPaths['checkout-order-details']}`,
    path: `/${collectionPaths['checkout-order-details']}`
  },
  {
    prefix: `/${collectionPaths['select-store']}`,
    path: `/${collectionPaths['select-store']}`
  },
  {
    prefix: `/${collectionPaths.products}`,
    path: `/${collectionPaths.products}`
  },
  {
    prefix: `/${collectionPaths.products}`,
    path: `/${collectionPaths.products}/[sku]/[name]`
  },
  {
    prefix: `/${collectionPaths['products-categories']}`,
    path: `/${collectionPaths['products-categories']}/[category]`
  }
]

export const URLRedirect = [
  {
    source: '/pretcoffeesub',
    destination: '/club-pret'
  },
  {
    source: '/us-pretcoffeesub',
    destination: '/club-pret'
  },
  {
    source: '/fr-pretcoffeesub',
    destination: '/club-pret'
  }
]

export const notFoundPath = '/404'
export const successStatusCode = 200

export const serviceFeeFeature = 'x-feature-service-fee'
export const anonymousIdHeader = 'pret-anonymous-id'
export const orderAheadBagIdHeader = 'pret-bag-id'
export const subscriptionBagIdHeader = 'pret-subscription-bag-id'
export const giftBagIdHeader = 'pret-gift-bag-id'
export const loginBackRedirectionUrl = 'login-back-redirection-url'
export const localeHeader = 'pret-locale'
export const marketHeader = 'market-locale'
export const traceId = 'x-cloud-trace-context'
export const geoIPRegionHeader = 'x-pret-geoip-region'
export const marketIdHeader = 'pret-market-id'
export const authCompletionUrl = 'auth-completion-url'
export const authHeader = 'Authorization'
export const authUserPhoneNumberField = 'http://pret.com/phoneNumber'
export const coffeeSubsPlanCookieName = 'pret-subscription-plan'
export const walletTypeHeader = 'wallet-type'
export const contentfulLabEnv = 'lab-equity'

export const timezone = 'Europe/London'
export const loggingContext = 'logging-context'
export const baristaProductType = 'barista_beverage'
export const foodProductType = 'food'
export const fullDayOfWeekFormat = 'EEEE'
export const timeFormat = 'h:mm'
export const timeFormatWithSuffix = 'h:mma'
export const time24HourFormat = 'HH:mm'
export const time24HourFormatWithSuffix = 'H:mma'
export const timeMonthYearExplicitFormat = 'MMMM yyyy'
export const timeDayMonthFormat = 'd MMMM'
export const timeMonthDayFormat = 'MMMM d'
export const timeDayOfMonthYearFormat = 'd MMMM yyyy'
export const timeDayOfMonthYearFormatForUS = 'MMMM d, yyyy'
export const timeMonthYearFormat = 'MM/yy'

export const nutritionalCaloriesKey = {
  'en-US': 'Calories',
  'en-GB': 'Calories'
}

export const caloriesSymbol = 'Kcal'

export const nutritionalTable = {
  default: {
    headings: ['nutrient', 'grams', 'serving'],
    values: {
      nutrient: 'nutrient',
      grams: 'per100g',
      serving: 'perServing'
    }
  },
  us: {
    headings: ['nutrient', 'serving'],
    values: {
      nutrient: 'nutrient',
      serving: 'perServing'
    }
  }
}

export const storeTypes = {
  pret: 'pret',
  veggiePret: 'veggie-pret',
  orderAhead: 'availableForOrderAheadCollection'
}

export const localeToCurrencyMap = {
  'en-GB': 'GBP',
  'en-US': 'USD',
  'fr-FR': 'EUR'
}

export const localeToCookieLanguageMap = {
  'en-GB': 'en',
  'en-US': 'en-us',
  'fr-FR': 'fr-fr',
  'zh-HK': 'zh-hk'
}

export const localeToDefaultShippingAddress = {
  'en-GB': {
    countryCode: 'GB',
    postalCode: 'SW1E 5DU'
  },
  'fr-FR': {
    countryCode: 'FR',
    postalCode: '75015'
  }
}

export const categoryNameMapByLocale = {
  'zh-HK': {
    HK0000: 'Pret at Home',
    HK0063: '窩心濃湯',
    HK0053: '三文治',
    HK0054: '法式長麵包',
    HK0067: '薄餅卷',
    HK0079: '烤焗熱食',
    HK0077: '早餐之選',
    HK0056: '沙律',
    HK0055: '麵包糕點',
    HK0061: '甜品',
    HK0064: '熱飲',
    HK0072: '鮮果',
    HK0001: '冷飲',
    HK0073: '薯片及小食'
  }
}

export const authActionsPath = '/auth/actions'
export const authActionsSigninPath = `${authActionsPath}/signin`
export const authActionsCompleteSigninPath = `${authActionsPath}/complete-signin`
export const faqsPath = {
  'en-GB': '/pret-faqs',
  'fr-FR': '/faq',
  'en-US': '/us-faqs'
}

export const authActionStates = {
  SIGNIN_FAILED: 'signin-failed',
  SIGNIN_REQUIRED: 'signin-required',
  EMAIL_VERIFIED: 'email-verified',
  EMAIL_VERIFICATION_FAILED: 'email-verification-failed',
  GENERIC_ERROR: 'error',
  PASSWORD_RESET_COMPLETE: 'password-reset-complete',
  PASSWORD_ADD_COMPLETE: 'password-add-complete',
  PASSWORD_RESET_FAILED: 'password-reset-failed',
  PASSWORD_ADD_FAILED: 'password-add-failed',
  UNBLOCK_ACCOUNT: 'unblock-account',
  UNBLOCK_ACCOUNT_FAILED: 'unblock-account-failed',
  SIGNUP: 'sign-up'
}

export const authLoginPageFeatures = {
  shouldSkipValidation: 'ssv',
  hideMarketingPreferences: 'hmp',
  shouldConfirmEmail: 'cea',
  isClickAndCollectJourney: 'iccj',
  isCoffeeSubscriptionJourney: 'icsj'
}

export const auth0Screens = {
  SIGNUP: 'signup',
  PASSWORD_RESET_EXPIRED: 'password-reset-expired'
}

export const auth0CodeQueryValues = {
  EMAIL_VERIFICATION_SUCCESS: 'success',
  EMAIL_VERIFICATION_ALREADY_VERIFIED: 'already-verified'
}

export const weekdays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

export const cacheTransactionalSites = [{ market: 'UK', locale: 'en-GB' }]

export const defaultMilkOption = 'canBeBlack'
export const defaultCaffeineOption = 'canBeCaf'
export const defaultCupSizeOption = 'NONE'
export const coffeeCentricStoreType = 'pioneer'

export const subscriptionCheckoutPath = `${subscriptionURLPrefix}/checkout`
export const subscriptionGiftCheckoutPath = `${subscriptionURLPrefix}/gifting/checkout`
export const subscriptionGiftOrderConfirmationPath = `${subscriptionURLPrefix}/gifting/thank-you`
export const signInSubscriptionRedirectPath = `${subscriptionURLPrefix}/signup`
export const myAccountPath = 'my-account'
export const myAccountPathV2 = '/my-account-v2'
export const myAccountLoggedOutPath = `${myAccountPath}/logged-out`
export const pretDeliversPath = 'pret-delivers'
export const pretDeliversMyAccountPath = `${pretDeliversPath}/myaccount`
export const clubPretPath = '/club-pret'

export const orderConfirmationPath = 'pretcoffeesub-thankyou'
export const orderTypeSubscription = 'subscription'
export const orderTypeSubscriptionGift = 'subscriptionGift'

export const orderTypeAuthoriseOnly = 'AuthoriseOnly'

export const perksLandingPath = '/pret-perks-rewards'
export const myPerksPath = `${myAccountPath}/pret-perks`
export const myRewardsPath = `/${myAccountPath}/pret-perks`
export const myPastRewardsPath = `/${myAccountPath}/pret-perks/past-rewards`
export const preferencesPath = `/${myAccountPath}/preferences`
export const personalDetailsPath = `/${myAccountPath}/personal-details`
export const personalDetailsV2Path = `/${myAccountPath}/personal-details-v2`
export const editPersonalDetailsV2Path = `${personalDetailsV2Path}/edit-personal-details`
export const confirmDeleteAccountPath = `${personalDetailsV2Path}/confirm-delete-account`
export const reconfirmDeleteAccountPath = `${personalDetailsV2Path}/reconfirm-delete-account`
export const cancelSubscriptionDeleteAccountPath = `${personalDetailsV2Path}/cancel-subscription-delete-account`
export const waitCancelSubscriptionDeletePath = `${personalDetailsV2Path}/wait-cancel-subscription`
export const employeeDeleteAccountPath = `${personalDetailsV2Path}/employee-delete-account`
export const deleteAccountSentPath = `${personalDetailsV2Path}/delete-account-sent`

export const subscriptionPausePath = `/${myAccountPath}/subscription/pause`
export const subscriptionCancelPath = `/${myAccountPath}/subscription/cancel`
export const subscriptionReactivatePath = `/${myAccountPath}/subscription/reactivate`
export const subscriptionResumePath = `/${myAccountPath}/subscription/resume`
export const subscriptionBilingHistoryPath = `/${myAccountPath}/subscription/billing-history`
export const subscriptionManageCardPath = `/${myAccountPath}/subscription/manage-card`
export const subscriptionDetailPath = `/${myAccountPath}/subscription`

export const pretDeliversCompanyPath = `/${pretDeliversMyAccountPath}/company`
export const pretDeliversOrdersPath = `/${pretDeliversMyAccountPath}/orders`
export const pretDeliversAddressPath = `/${pretDeliversMyAccountPath}/address`
export const pretDeliversPaymentSettingsPath = `/${pretDeliversMyAccountPath}/payment`
export const pretDeliversManageUsersPath = `/${pretDeliversMyAccountPath}/users`
export const pretDeliversTeamOrdersPath = `/${pretDeliversMyAccountPath}/team/orders`
export const pretDeliversUpgradeBusinessPlusPath = `/${pretDeliversMyAccountPath}/upgrade/business-plus`
export const pretDeliversStartOrderPath = `/${pretDeliversPath}/address`
export const pretDeliversAddPackedLunchesPath = `/${pretDeliversMyAccountPath}/upgrade/business-plus/add-ibl`
export const pretDeliversUpgradeAccountPath = `/${pretDeliversMyAccountPath}/upgrade`

export const orderOnlinePath = 'orderonline'
export const giftSelectionPath = 'pretcoffeesub/gifting/gift-selection'

export const appURLDownloaded = 'https://app.adjust.com/yxib2d2'

export const myAccountMessages = {
  emailVerificationSuccess: 'email-verified',
  changePasswordSuccess:
    'You can now login to the application with the new password.',
  resetAccountSuccess: 'unblock-account',
  verificationEmailSendSuccess: 'verification-resend'
}
export const myAccountTransactionalQueryParam = 'transactional'
export const gtmPurchaseEvent = 'purchase'

export const localeToFbDomainVerificationCodeMap = {
  'en-GB': 'ht1b5h16cmopbtcsqlggssm3di9oi8',
  'en-US': 'yh8lrhigmlvt0i2m4a376vk77a05tv',
  'fr-FR': 'dls94cc4021jla5r4624y167rn5jlf'
}

export const fbDomainVerificationMetaKey = 'facebook-domain-verification'

export const nextAvailableSlotMaxDiff = 15
export const cardBrands = ['visa', 'mc', 'amex']
export const paymentTypes = {
  CARD: 'card',
  APPLE: 'apple',
  GOOGLE: 'google_pay'
}

export const walletPaymentTypeNames = {
  // eslint-disable-next-line camelcase
  apple_pay: 'Apple Pay',
  // eslint-disable-next-line camelcase
  google_pay: 'Google Pay'
}

export const paymentMethodSuccessfullyDisabled =
  '[detail-successfully-disabled]'

export const interstitialSignInBannerCampaginNames = {
  'en-GB': 'InterstitialSignInBanner',
  'en-US': 'InterstitialSignInBanner',
  'fr-FR': 'InterstitialSignInBanner-fr'
}

export const giftingActivationBanner = 'GiftingActivationBanner'
export const featuredProductBanner = 'FeaturedProduct'
export const shopSeatingNoSeats = 'no_seats'

export const loyaltyURL = 'loyalty'

export const subscriptionPaymentBrands = {
  visa: 'Visa',
  mastercard: 'Mastercard',
  americanExpress: 'American Express',
  discover: 'Discover',
  jcb: 'JCB',
  dinersClub: 'Diners Club',
  other: 'Other'
}

export const defaultPhoneCountryCode = 'GB'

export const orderAheadCard = 'order_ahead_card'
export const subscriptionCard = 'subscription_card'

export const checkoutRedirectTypes = {
  UPDATE_CARD: 'update-card',
  REACTIVATE: 'reactivate',
  RESUME: 'resume',
  ORDER_AHEAD: 'order-ahead',
  SUBSCRIPTION: 'subscription',
  SUBSCRIPTION_GIFT: 'subscription-gift'
}

export const bagState = {
  ORDERED: 'Ordered',
  ACTIVE: 'Active',
  MERGED: 'Merged'
}

export const adyenShopperInteractions = {
  ECOMMERCE: 'Ecommerce'
}

export const discountTypes = {
  COFFEE_PLAN: 'YourBaristaFree',
  PRET_PERKS: 'STARS_REWARD'
}

export const coffeeSubscriptionPlanIds = {
  US_CLASSIC_COFFEE_PLAN: 'us-classic-coffee-plan',
  US_PREMIUM_COFFEE_PLAN: 'us-premium-coffee-plan',
  UK_COFFEE_PLAN: 'coffee-plan',
  FR_COFFEE_PLAN: 'fr-abonnement-cafe',
  CLUB_PRET_PLAN: 'club-pret-plan',
  US_CLUB_PRET_PLAN: 'us-club-pret-plan',
  UK_CLUB_PRET_PLAN: 'uk-club-pret-plan'
}

export const coffeeSubscriptionPlans = {
  UK: [
    { planId: coffeeSubscriptionPlanIds.UK_COFFEE_PLAN },
    { planId: coffeeSubscriptionPlanIds.CLUB_PRET_PLAN },
    { planId: coffeeSubscriptionPlanIds.UK_CLUB_PRET_PLAN }
  ],
  US: [
    { planId: coffeeSubscriptionPlanIds.US_CLASSIC_COFFEE_PLAN },
    { planId: coffeeSubscriptionPlanIds.US_PREMIUM_COFFEE_PLAN }
  ],
  FR: [{ planId: coffeeSubscriptionPlanIds.FR_COFFEE_PLAN }]
}

export const coffeeSubscriptionStatuses = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  IN_TRIAL: 'inTrial',
  NON_RENEWING: 'nonRenewing',
  PAUSED: 'paused',
  SCHEDULED_TO_PAUSE: 'scheduledToPause',
  SCHEDULED_TO_CANCEL: 'scheduledToCancel',
  SCHEDULED_TO_CHANGE: 'scheduledToChange',
  EXPIRED: 'expired'
}

export const otherCoffeeSubStatuses = {
  inTrial: 'active',
  nonRenewing: 'active',
  scheduledToPause: 'active',
  scheduledToCancel: 'active',
  scheduledToChange: 'active',
  expired: 'inactive'
}

export const subscriptionPeriods = {
  [coffeeSubscriptionStatuses.IN_TRIAL]: 'trial'
}

export const priceType = {
  TAX_INCLUSIVE: 'taxInclusive',
  TAX_EXCLUSIVE: 'taxExclusive'
}

export const marketDetails = {
  UK: {
    countryCode: 'GB',
    defaultTimeSlotLength: 1,
    topLevelDomain: '.co.uk',
    locale: 'en-GB'
  },
  US: {
    countryCode: 'US',
    defaultTimeSlotLength: 6,
    topLevelDomain: '.com',
    locale: 'en-US'
  },
  FR: {
    countryCode: 'FR',
    defaultTimeSlotLength: 1,
    topLevelDomain: '.fr',
    locale: 'fr-FR'
  },
  HK: {
    countryCode: 'HK',
    defaultTimeSlotLength: 1,
    topLevelDomain: '.hk',
    locale: 'zh-HK'
  }
}

export const fallbackMarketCode = 'UK'
export const fallbackLocaleCode = marketDetails.UK.locale

export const nutritionalDataKeys = {
  calories: 'Energy (KCal)'
}

export const usCoffeeSubsLandingPagePath = 'us-pretcoffeesub'
export const frCoffeeSubsLandingPagePath = 'fr-pretcoffeesub'

export const emailResendTimeoutMs = 60 * 60 * 1000 // 1 hour

export const productCustomisationTypes = {
  TOPPERS: 'toppers',
  EXTRAS: 'extras'
}

export const orderTypes = ['collection', 'delivery', 'outpost', 'subscription']

export const googlePayMerchantName = 'Pret A Manger'

export const giftMonthDuration = 3
export const sendOptionImmediate = 'immediate'
export const sendOptionSpecificDate = 'specific-date'

export const regularExpressions = {
  EMOJI:
    '(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])'
}

export const usStateMap = {
  NY: 'New York',
  PA: 'Pennsylvania',
  DC: 'D.C'
}

export const subscriptionCountryMap = {
  GB: 'UK',
  US: 'US',
  FR: 'FR'
}

export const subscriptionLocaleURLPrefix = {
  'en-GB': clubPretURLPrefix,
  'en-US': clubPretURLPrefix,
  'fr-FR': frCoffeeSubsLandingPagePath
}

export const priceChangeNoticeDescriptionId = {
  [coffeeSubscriptionPlanIds.US_CLASSIC_COFFEE_PLAN]:
    'my-account.price-change-notice.description.us-classic',
  [coffeeSubscriptionPlanIds.US_PREMIUM_COFFEE_PLAN]:
    'my-account.price-change-notice.description.us-premium',
  [coffeeSubscriptionPlanIds.UK_COFFEE_PLAN]:
    'coffee.subscription.club.pret.price-change.notice.description-1',
  [coffeeSubscriptionPlanIds.US_CLUB_PRET_PLAN]:
    'my-account.price-change-notice.description.us-club-pret-plan',
  [coffeeSubscriptionPlanIds.UK_CLUB_PRET_PLAN]:
    'coffee.subscription.club.pret.price-change.notice.description-3',
  [coffeeSubscriptionPlanIds.FR_COFFEE_PLAN]:
    'my-account.price-change-notice.description.fr-abonnement-cafe'
}

export const coffeeSubPlanId = Object.values(coffeeSubscriptionPlanIds)

export const modalImageUrl = {
  'en-GB':
    'https://images.ctfassets.net/4zu8gvmtwqss/5NKZYBgWm9g5jjREMSzHIc/5c513e19d8dddf084e040abdd132fc19/UK_NonSubscriber_Web_MyAccount_Overlay_1125x1125.jpg.jpg',
  'en-US':
    'https://images.ctfassets.net/4zu8gvmtwqss/4folE5fqInLLUFfrRpiyfj/2aa422614ba0e925f6912c3658d403d7/US_NonSubscriber_Web_MyAccount_Overlay_1125x1125.jpg',
  'fr-FR':
    'https://images.ctfassets.net/4zu8gvmtwqss/1PVnVbXSNRxX6inJyoZ9ZW/4584ff59f783ae61d62ae822d5bbf04d/qr-code-removal-promo-modal-image.png'
}

export const promoOverlayDefaultImg =
  'https://images.ctfassets.net/4zu8gvmtwqss/5NKZYBgWm9g5jjREMSzHIc/5c513e19d8dddf084e040abdd132fc19/UK_NonSubscriber_Web_MyAccount_Overlay_1125x1125.jpg.jpg'

export const employeeHelpOption = 'Something else...'
export const myAccountTitle = 'Manage My Account'

export const reactivationJourney = 'reactivation'

export const overlayImageSlugByLocale = {
  'en-GB': 'club-pret-overlay-image-uk',
  'en-US': 'club-pret-overlay-image-us',
  'fr-FR': 'club-pret-overlay-image-fr'
}
