import { useSafeSetState } from '#src/common/hooks/useSafeSetState'
import { httpClient } from '#lib/http-client'
import { useEffect } from 'react'
import { accountSelector } from '#src/state/account-state-slice'
import { useAppSelector } from '#src/state/redux-hooks'
import { getTokenSilently } from '#src/common/lib/auth0/auth0-functions'

type ClientProps = Partial<{
  body: Record<string, any>
  apiUrl: string
  token: string
  headers: Record<string, any>
  [key: string]: any
}>

export const client = <T>(
  endpoint: string,
  { body, apiUrl, token, ...customConfig }: ClientProps = {}
): Promise<T> => {
  const headers: Record<string, any> = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  const config: Record<string, any> = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  }
  if (body) {
    config.body = JSON.stringify(body)
  }
  return httpClient(`${apiUrl}${endpoint}`, config).then(async response => {
    if (response.status === 401) {
      return
    }

    if (response.ok) {
      try {
        const contentType = response.headers.get('content-type')

        if (contentType.includes('text/plain')) {
          return await response.text()
        }

        return await response.json()
      } catch {
        return Promise.reject(new Error('Error parsing reponse'))
      }
    } else {
      try {
        const errorResponse = await response.json()
        return Promise.reject(errorResponse)
      } catch {
        const errorMessage = await response.text()
        return Promise.reject(new Error(errorMessage))
      }
    }
  })
}

export const useClient = (apiUrl: string) => {
  const { isLoading } = useAppSelector(accountSelector)
  const [state, dispatch] = useSafeSetState({
    accessToken: null,
    loading: true
  })

  useEffect(() => {
    if (isLoading) return
    getTokenSilently().then((accessToken: string) =>
      dispatch({ accessToken, loading: false })
    )
    // dispatch is safe to omit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    loading: state.loading,
    client: (url: string, args: ClientProps) =>
      client(url, { apiUrl, token: state.accessToken, ...args })
  }
}
