import { httpClient as fetch } from '#lib/http-client'
import { ResponseError } from '../types'
import { RequestExtend } from './types'
import { getTokenSilently } from '../auth0/auth0-functions'

export const getPastRewards = async (baseUrl: string): Promise<any> => {
  const accessToken = await getTokenSilently()

  const params: RequestExtend = {
    method: 'GET',
    json: true,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }

  const response = await fetch(
    `${baseUrl}/v1/wallet/reward?active=false`,
    params
  )

  if (!response.ok) {
    throw new ResponseError(response)
  }

  return await response.json()
}
