import { removeCurrency } from './remove-currency'
import { actionField } from '../../../../topics'
import { GTM_DIMENSION_11_DATA_KEY } from '../../constants'

export const mapProduct = (
  products: any[],
  ctaType?: string,
  useDimension11IfPresent?: boolean
) =>
  products.map(
    ({
      categories = [],
      sku,
      name,
      price,
      quantity,
      recommendationId,
      recommendationPosition,
      variant = '',
      dimension11
    }) => {
      const product = {
        id: sku,
        name,
        quantity: quantity || 1,
        price: removeCurrency(price.localisedPrice),
        brand: 'Pret',
        category: categories.length ? categories[0].id : '',
        ...(ctaType === actionField.crossSell && {
          dimension4: recommendationId,
          position: recommendationPosition
        }),
        variant
      }

      if (useDimension11IfPresent && dimension11) {
        product[GTM_DIMENSION_11_DATA_KEY] = dimension11
      }

      return product
    }
  )
