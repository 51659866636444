import { ChangeLink } from './styles'
import Translation from '#src/common/components/translation'
import { useRouter } from 'next/router'
import { getCollectionPath } from '#src/common/lib/get-collection-path'
import LocaleLink from '#src/common/components/locale-link'

export const ChangeShopLink = () => {
  const router = useRouter()
  const collectionPath = getCollectionPath(router.locale)
  return (
    <LocaleLink href={`${collectionPath}/shop-finder`}>
      <ChangeLink data-testid='change-shop'>
        <Translation id='order-slot-banner.change' />
        <span className='sr-only'>
          <Translation id='order-slot-banner.title' />
        </span>
      </ChangeLink>
    </LocaleLink>
  )
}
