import PropTypes from 'prop-types'
import { Heading as H } from './styles'

const Heading = ({ level, children }) => {
  return <H level={`h${level}`}>{children}</H>
}

Heading.propTypes = {
  level: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
}

export default Heading
