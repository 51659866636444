import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Select } from '@pretamanger/component-library'

export const StyledSelect = styled(Select)`
  ${tw`border-gray-500 py-1`}
  max-width: 5ch;
  min-width: 3em;
`
export const HiddenSelect = styled.div`
  ${tw`border-gray-500 py-1 invisible`}
  max-width: 5ch;
  min-width: 3em;
`
