import { PURCHASE } from '../../../../topics'
import { transformGiftingPurchaseLineItems } from '../data-transformers/purchase'
import { GIFTING_COFFEE_SUBSCRIPTION } from '../../constants'
import { removeCurrency } from '../utils/remove-currency'

export const purchaseAdapter = element => {
  const {
    orderNumber,
    price,
    lineItems,
    currencyCode,
    storeLocation,
    storeCity,
    pickupTime,
    chargebeeSubscriptionId,
    coffeeSubscription
  } = element
  const gtmObj = {
    event: PURCHASE,
    ecommerce: {
      purchase: {}
    },
    pretEcommerce: {}
  }
  let lineItemsTransformed
  let revenue

  if (
    element?.ecommerce &&
    element?.ecommerce?.items &&
    element?.ecommerce?.items.length > 0
  ) {
    return element
  } else {
    if (coffeeSubscription === GIFTING_COFFEE_SUBSCRIPTION) {
      lineItemsTransformed = transformGiftingPurchaseLineItems(lineItems)
      revenue = price
    } else {
      lineItemsTransformed = lineItems
      revenue = removeCurrency(price)
    }

    gtmObj.ecommerce.purchase = {
      actionField: {
        id: orderNumber,
        revenue,
        tax: 0,
        shipping: 0,
        coupon: ''
      },
      products: lineItemsTransformed
    }
    gtmObj.pretEcommerce = {
      currencyCode,
      storeLocation,
      storeCity,
      pickupTime,
      coffeeSubscription,
      checkoutType: 'customer',
      chargebeeSubscriptionId
    }
    return gtmObj
  }
}
