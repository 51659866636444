import { v4 as uuidv4 } from 'uuid'
import cookies from 'js-cookie'
import { parseCookies } from 'nookies'
import { anonymousIdHeader } from '#constants'

export const provideAnonymousId = () => {
  let currentAnonymousId = cookies.get(anonymousIdHeader)
  if (!currentAnonymousId || currentAnonymousId === null) {
    currentAnonymousId = uuidv4()
  }
  return currentAnonymousId
}

export const getAnonymousIdFromCtx = ctx => {
  const cookies = parseCookies(ctx)
  return cookies[anonymousIdHeader]
}
