const parseJwt = (token: string) => {
  try {
    let encodedToken = token
    const secondValueToken = encodedToken.split('.')?.[1]
    if (secondValueToken) {
      encodedToken = secondValueToken
    }
    return JSON.parse(atob(encodedToken))
  } catch (e) {
    return null
  }
}

export { parseJwt }
