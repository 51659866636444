import isEmail from 'validator/lib/isEmail'
import isLength from 'validator/lib/isLength'
import isEmpty from 'validator/lib/isEmpty'
import isNumeric from 'validator/lib/isNumeric'
import isMobilePhone from 'validator/lib/isMobilePhone'
import equals from 'validator/lib/equals'
import isPostalCode, {
  locales as validPostcodeLocales
} from 'validator/lib/isPostalCode'
import { getLogger } from '#api/logger-with-context'
import { customerExists } from '#src/common/lib/api/subscription'
import { httpClient } from '#lib/http-client'

export const validators = {
  isEmail,
  isNumeric,
  isNotEmpty: val => !isEmpty(val),
  isTrue: val => !!val,
  isMatch: equals
}
const getValidLocale = locale =>
  validPostcodeLocales.includes(locale) ? locale : 'any'

const postcodeLTRegex = new RegExp('^(LT-)?\\d{5}$')
const postcodeGBRegex = new RegExp('^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$')

const validateGBPostcode = postcode =>
  postcodeGBRegex.test(postcode.toUpperCase())

const validateLTPostcode = postcode =>
  postcodeLTRegex.test(postcode.toUpperCase())

export const asyncValidators = {
  extIsNotMatch: (val, externalValue) =>
    Promise.resolve(!equals(val, externalValue)),
  // eslint-disable-next-line camelcase
  isEmail: val => Promise.resolve(isEmail(val, { blacklisted_chars: '+' })),
  isMatch: (val, other) => Promise.resolve(equals(val, other)),
  isMobilePhone: val => Promise.resolve(isMobilePhone(val, 'any')),
  isNotEmpty: val => Promise.resolve(!isEmpty(val)),
  isNotExistingCustomer: async email => !(await customerExists(email)),
  isNumeric: val => Promise.resolve(isNumeric(val)),
  extIsLength: (val, min, max) => Promise.resolve(isLength(val, { min, max })),
  isPostCode: (val, locale = 'GB') => {
    if (locale === 'GB') {
      return validateGBPostcode(val)
    } else if (locale === 'LT') {
      return validateLTPostcode(val)
    }
    return isPostalCode(val, getValidLocale(locale))
  },
  isTrue: val => Promise.resolve(!!val),
  extDoesNotContain: (val, regExp) =>
    Promise.resolve(!new RegExp(regExp).test(val))
}

export const post = async (form, fields, locale) => {
  const log = getLogger()
  log.info({ formId: form.id }, 'posting form')
  log.info({ form, fields }, 'form definition')

  const res = await httpClient(`/api/${form.id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify({ form, fields, locale })
  })
  const data = await res.json()

  log.info({ data }, 'Response data')

  return data
}

export const validateForm = (form, fields) => {
  const formState = {}
  let formValid = true
  fields.forEach(({ id, value }) => {
    const isValid = validateField(form, id, value)
    if (formValid && !isValid) {
      formValid = false
    }
    formState[id] = {
      id,
      form,
      dirty: true,
      isValid
    }
  })
  return { formState, formValid }
}

export const validateField = (form, fieldId, value = '') => {
  /**
   * To Do
   *  There should always be a form, however without `form?` we get
   *    `Error: Uncaught [TypeError: Cannot read property 'fields' of undefined]`
   */
  const field = form?.fields?.find(({ id }) => id === fieldId) || {}
  let isValid = true
  if (field.type) {
    field.type.forEach(type => {
      isValid = validators[type](value)
    })
  }
  return isValid
}
