export const getUserError = (user: any, bag: any) => {
  const hasUserError =
    user &&
    !Object.prototype.hasOwnProperty.call(
      bag?.collectionDetails ?? {},
      'eagleEyeTransactionId'
    ) &&
    bag?.collectionDetails?.voucherCodeApplied === false

  return hasUserError
}
