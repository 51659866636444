import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import cookies from 'js-cookie'
import { RootState } from './store'
import { BagState, GiftDetail } from './types'
import { createGiftBag } from '../common/lib/api/bag'
import { giftBagIdHeader } from '#src/common/constants'

const initialState: BagState = {
  isLoading: true
}

interface GiftBagDetails {
  sku: string
  quantity: number
  cardImageUrl: string
  sendDate: string
  recipientName: string
  recipientEmail: string
  cardMessage: string
  senderName: string
  senderEmail: string
}

export const createBag = createAsyncThunk<
  BagState,
  { locale: string; authToken: string; payload: GiftBagDetails },
  { state: RootState }
>('bags/create-gift', async ({ locale, authToken, payload }) => {
  const giftBag = await createGiftBag({ locale, authToken, payload })
  cookies.set(giftBagIdHeader, giftBag.bagId)

  return giftBag
})

export const giftBagStateSlice = createSlice({
  name: 'gift-bag',
  initialState,
  reducers: {
    bagLoading: state => ({
      ...state,
      isLoading: true
    }),
    loadBag: (state, action: PayloadAction<BagState>) => ({
      ...state,
      ...action.payload,
      isLoading: false
    })
  },
  extraReducers: builder => {
    builder.addCase(createBag.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    })
    builder.addCase(createBag.rejected, state => {
      state.isLoading = false
    })
  }
})

export const bagSelector = ({ giftBag }: RootState): BagState => giftBag

export const giftLineItemSelector = ({ giftBag }: RootState): GiftDetail => {
  const lineItem = giftBag.lineItems && giftBag.lineItems[0]

  return lineItem
    ? {
        ...lineItem.giftDetails,
        subscriptionLength: `${lineItem.quantity}`
      }
    : null
}
