import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Button, Dropdown, Grid } from '@pretamanger/component-library'

export const LayoutContainer = styled.div<{ isImpersonation: boolean }>`
  ${({ isImpersonation }) =>
    isImpersonation &&
    tw`fixed inset-0 border-8 border-solid border-[#BD3664] overflow-x-hidden overflow-y-auto`}
`

export const CountrySwitchContainer = styled('div')`
  ${tw`mt-8 lg:my-0 flex flex-wrap justify-start md:w-3/6 lg:w-full`}
`

export const LanguageSwitchContainer = styled('div')`
  ${tw`mt-8 lg:my-0 flex flex-wrap justify-start md:w-3/6 lg:w-full`}
`

export const StyledDropdown = styled(Dropdown)`
  ${tw`w-full`}
  select {
    ${tw`border-gray-500`}
  }
`

export const SmallStyledDropdown = styled(StyledDropdown)`
  select {
    ${tw`pl-2`}
  }

  svg {
    ${tw`right-0 pr-2`}
  }
`

export const FooterLogo = styled('img')`
  ${tw`h-auto`}
  width: 71px;
`

export const FooterInfoContainer = styled('div')`
  ${tw`mt-10 py-8 border-t border-grey-100 flex! flex-col lg:flex-row`}

  > a {
    ${tw`flex-none`}
  }

  > div {
    ${tw`mt-4 lg:ml-4 lg:mt-0 flex-grow`}
  }

  p {
    ${tw`text-xs leading-xs`}
  }
`

export const HeaderGrid = styled(Grid)`
  && {
    ${tw`items-center`}
    ${tw`grid-cols-7 sm:grid-cols-8 lg:grid-cols-9 lg:gap-4 xl:gap-8`}
  }
`

export const LogoLink = styled('a')`
  ${tw`inline-block`}
`

export const HeaderContainer = styled('header')`
  ${tw`py-5 bg-white border-b`}
`

interface StickyContainerProps {
  isSticky: boolean
}

export const StickyContainer = styled('div')<StickyContainerProps>`
  ${props => props.isSticky && tw`sticky top-0 z-30`}
`

export const HeaderLogo = styled('img')`
  ${tw`h-auto`}
  width: 100px;
`

export const HeaderNavigationContainer = styled('div')`
  ${tw`relative`}

  > input {
    ${tw`block absolute top-0 left-0 h-full w-full cursor-pointer opacity-0 z-10 lg:hidden`}

    &:checked {
      ~ nav {
        ${tw`relative`}
        transform: none;
      }

      ~ label span {
        ${tw`opacity-100 bg-grey-700`}
        &:nth-last-of-type(1) {
          transform: rotate(45deg) translate(-5px, -5px);
        }
        &:nth-last-of-type(2) {
          ${tw`opacity-0`}
        }
        &:nth-last-of-type(3) {
          transform: rotate(-45deg) translate(-4px, -6px);
        }
      }
    }
  }

  > label {
    text-indent: -9999px;
    ${tw`block -mt-4 lg:hidden`}

    > span {
      ${tw`block relative w-8 h-1 mb-1 bg-grey-700 mx-auto lg:hidden`}
      &:first-of-type {
        ${tw`origin-bottom-left`}
      }
      &:nth-last-of-type(3) {
        ${tw`origin-top-right`}
      }
    }
  }

  > nav {
    ${tw`absolute w-screen origin-top-left transform -ml-4 z-50 bg-white`}
    height: calc(100vh - 4rem);
    transform: translateX(-200%);
    ${tw`overflow-y-scroll overflow-x-hidden`}
    ${tw`top-0`}
    ${tw`transition duration-75 ease-in-out`}
    ${tw`lg:relative lg:h-auto lg:w-auto lg:translate-x-0 lg:overflow-visible lg:m-0`}

    > ul {
      ${tw`bg-white py-10 sm:mt-8 px-4 h-auto lg:mt-0 lg:p-0 pb-0`}

      > li {
        ${tw`pb-6 sm:pb-10 lg:pb-0`}

        > a {
          ${tw`block lg:inline`}
        }
      }
    }
  }
`

export const SkipToContent = styled('a')`
  ${tw`sr-only text-sm focus:not-sr-only no-underline focus:shadow-outline focus:py-2 focus:px-4 focus:absolute focus:bg-white`}
`

export const HeaderActions = styled('div')`
  ${tw`flex items-center justify-end gap-4`}
`

export const CustomButton = styled(Button)`
  > span {
    ${tw`w-[87px]`}
  }
`
