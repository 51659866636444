import cookies from 'js-cookie'
import {
  marketDetails,
  subscriptionURLPrefix,
  loginBackRedirectionUrl
} from '#constants'
import { fireFeatureClickEvent } from '../core'
import { PRET_UK_DOMAIN_WITH_SCHEME } from '../../../../subscribers/analytics/constants'

export const manageCoffeeSubscriptionEvent = () => {
  cookies.set(loginBackRedirectionUrl, `/en-GB/${subscriptionURLPrefix}`)
  fireFeatureClickEvent({
    type: 'option_button',
    name: 'manage_subscription',
    destination: `${PRET_UK_DOMAIN_WITH_SCHEME}/${marketDetails.UK.locale}/${subscriptionURLPrefix}`
  })
}
