import { ADD_TO_BAG } from '../../../../topics'
import { mapProduct } from '../utils/map-product'
import { hasActionField } from '../utils/has-action-field'
import { doesCtaRepresentGift } from '../utils/does-cta-represent-gift'

export const addToBagAdapter = ({
  ctaType,
  products = [],
  useDimension11IfPresent = true
}) => {
  let mappedProducts = products

  const gtmObj = {
    event: ADD_TO_BAG,
    ecommerce: {}
  }

  if (!doesCtaRepresentGift(ctaType)) {
    mappedProducts = mapProduct(products, ctaType, useDimension11IfPresent)
  }

  gtmObj.ecommerce = {
    currencyCode: products[0]?.price?.currencyCode,
    add: {
      products: mappedProducts,
      ...(hasActionField(ctaType) && {
        actionField: { list: ctaType }
      })
    }
  }

  return gtmObj
}
