import React, { useEffect, useRef, useState } from 'react'
import { useRouteChange } from '#src/common/hooks/useRouteChange'
import { LoadingOverlay } from '.'

const minTimeoutThreshold = 100

export const RouteChangeLoadingOverlay: React.FC = () => {
  const { routeChanging } = useRouteChange()
  const [shouldShowSpinner, setShouldShowSpinner] = useState(false)

  const timer = useRef<NodeJS.Timer>()

  useEffect(() => {
    if (routeChanging) {
      timer.current = setTimeout(
        () => setShouldShowSpinner(true),
        minTimeoutThreshold
      )
    } else {
      setShouldShowSpinner(false)

      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [routeChanging])

  return <LoadingOverlay loading={shouldShowSpinner} />
}
