export const transformAddressToString = ({
  streetNumber = '',
  streetName,
  city,
  postalCode
}) => {
  const streetAddress = [streetNumber, streetName].filter(i => i).join(' ')

  return `${streetAddress}, ${city} ${postalCode}`
}

export const formatShopAddress = shop => {
  const {
    address: { streetName, streetNumber, postalCode, city, region, state } = {},
    name = ''
  } = shop
  return {
    address: [streetNumber, streetName, city, postalCode, region, state]
      .filter(part => !!part)
      .join(', '),
    name,
    city
  }
}
