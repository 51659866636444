import { useEffect } from 'react'

export const useBodyLock = locked => {
  const classes = ['overflow-hidden', 'h-full', 'w-full', 'fixed']
  useEffect(() => {
    if (locked) {
      document.body.classList.add(...classes)
    }
    return () => {
      document.body.classList.remove(...classes)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [locked])
  /* eslint-enable react-hooks/exhaustive-deps */
}
