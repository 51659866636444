import { parseCookies, setCookie } from 'nookies'

const removeDuplicates = arr => [...new Set(arr)]

const getPreferences =
  key =>
  (ctx = {}) => {
    const cookies = parseCookies(ctx)
    const cookieValue = cookies[key] || '[]'
    return JSON.parse(cookieValue)
  }

const updatePreferences = (key, get) => id => {
  const current = get()
  const update = removeDuplicates([...current, id])
  setCookie({}, key, JSON.stringify(update))
}

export const userPreferences = key => {
  const get = getPreferences(key)
  return {
    get,
    update: updatePreferences(key, get)
  }
}
