import PropTypes from 'prop-types'
import { BagWithItem, EmptyBag } from '@pretamanger/component-library'

const BagIcon = ({ itemCount, ...props }) => {
  return itemCount > 0 ? (
    <BagWithItem
      itemCount={itemCount}
      aria-hidden
      className='text-current'
      {...props}
    /> /* Todo BPS-469 remove text-current class once the tailwind version is increased or synced with component library */
  ) : (
    <EmptyBag aria-hidden focusable={false} {...props} />
  )
}

BagIcon.propTypes = {
  itemCount: PropTypes.number
}

BagIcon.defaultProps = {
  itemCount: 0
}

export default BagIcon
