import styled from '@emotion/styled'
import tw, { TwStyle } from 'twin.macro'
import {
  Heading,
  PdLandingBag,
  FileDownloadIcon,
  CookieBag,
  SpilledCoffee,
  EmailPret,
  UserSetting,
  RocketIcon,
  getScreenSizes
} from '@pretamanger/component-library'

export const AccountSection = styled('section')`
  ${tw`border-4 border-grey-100 md:border-8 p-6 md:p-8`}
`
export const AccountSectionV2 = styled('section')`
  ${tw`border-4 border-grey-100 md:border-8 px-6 py-[22px]`}
  ${tw`w-[336px] h-[450px] min-w-[336px] min-h-[426px]`}
  ${tw`md:w-[449px] md:h-[426px] max-w-[449px] max-h-[450px]`}
`
export const Paragraph = styled('p')`
  ${tw`mb-4 md:max-w-md text-grey-500`}
`
export const A11yParagraph = styled('p')`
  ${tw`mt-8 mx-auto text-center text-sm text-grey-700`}
  max-width: 50ch;
`
export const NestedTickList = styled('div')<{ promotion?: boolean }>`
  p {
    ${({ promotion }) => promotion && tw`!mt-4`}
    ${tw`my-2`}
  }

  > * > ul {
    ${tw`pb-4 ml-0`}
  }

  > * > ul > li {
    ${({ promotion }) => promotion && [tw`mb-4`, `display: flex;`]}
    ${tw`list-none`}
  }

  > * > ul > li:before {
    content: '✓';
    ${({ promotion }) => promotion && tw`!mt-4`}
    ${tw`text-pretRed-700 text-lg float-left left-0 mr-2`}
  }

  li li {
    ${tw`list-disc ml-4`}
  }

  li li > p {
    ${tw`my-0`}
  }
`

export const CustomHeader = styled(Heading)`
  ${tw`capitalize !font-bold !mb-0`}
`
export const WrapperContentWelcomeMessage = styled('div')`
  ${tw`mb-6`}
`
export const WrapperManageMyAccountTitle = styled('div')`
  ${tw`mt-11 md:mt-8 mb-8 flex justify-between w-full max-w-4xl`}
`
export const ManageMyAccountTitle = styled(Heading)`
  ${tw`font-bold text-grey-700 mb-2 text-left`}
`
export const CustomShopingBagIcon = styled(PdLandingBag)`
  ${tw`max-w-[50px] max-h-[40px]`}
`
export const WrapperSubscriptionStatus = styled('div')``
export const Space = styled('div')<{ small?: boolean; xs?: boolean }>`
  ${({ small, xs }) => (xs ? tw`h-2 w-2` : small ? tw`h-4 w-4` : tw`mb-11`)}
`
export const WrapperWelcomeMessage = styled('div')`
  ${tw`sm:flex sm:justify-between sm:w-full sm:max-w-4xl`}
`
export const WrapperQRAndPretPerks = styled('div')`
  ${tw`flex flex-col items-center lg:flex-row lg:justify-between lg:w-full lg:max-w-4xl`}
`
export const WrapperContentQRAndPretPerks = styled('div')<{
  addMarginLeft?: boolean
}>`
  ${tw`mb-8`}
  ${({ addMarginLeft }) => addMarginLeft && tw`lg:ml-8`}
`
export const WrapperSubMenuPage = styled('div')`
  ${tw`w-full max-w-4xl`}
`

export const CustomFileDownloadIcon = styled(FileDownloadIcon)`
  ${tw`max-w-[50px] max-h-[40px]`}
`

export const CustomCookieBag = styled(CookieBag)`
  ${tw`max-w-[50px] max-h-[40px]`}
`

export const CustomUserSetting = styled(UserSetting)`
  ${tw`max-w-[50px] max-h-[40px]`}
`

export const CustomSpilledCoffee = styled(SpilledCoffee)`
  ${tw`max-w-[61.25px] max-h-[64px]`}
`

export const CustomUserSettingDeleteAccount = styled(UserSetting)`
  ${tw`max-w-[80px] max-h-[64px]`}
`

export const WrapperActionConfirmDelete = styled('div')`
  ${tw`flex flex-col md:flex-row w-full max-w-4xl `}
`

export const CustomEmailPret = styled(EmailPret)`
  ${tw`max-w-[165px] max-h-[120px]`}
`

export const SectionEmailSentSuccess = styled('section')`
  ${tw`flex flex-col items-center`}
`

export const CustomRocketIcon = styled(RocketIcon)`
  ${tw`max-w-[50px] max-h-[40px]`}
`

type NoticeVariant = 'center' | 'left'
type NoticeVariantTypes = {
  [_key in NoticeVariant]: TwStyle
}
const noticeVariant: NoticeVariantTypes = {
  center: tw`justify-center`,
  left: tw`justify-start`
}

export const NoticeWrapper = styled.div<{ variant: NoticeVariant }>`
  ${tw`w-full mb-4 !text-[#6F5701]`}
  > div {
    ${tw`bg-[#FCFAF2] w-full m-0 gap-4`}
    ${({ variant }) => variant && noticeVariant[variant]}
    @media (min-width: ${getScreenSizes().md}px) {
      ${tw`max-h-[56px]`}
    }
    div {
      ${tw`w-fit`}
    }
    svg {
      path {
        &:first-of-type {
          ${tw`stroke-[#CA9E03] fill-none`}
        }
        &:last-of-type {
          ${tw`fill-[#CA9E03]`}
        }
      }
    }
  }
`

export const AccountTypeBadge = styled.div`
  ${tw`flex justify-center items-center gap-2 px-4 py-2`}
  ${tw`bg-errorRedTint rounded-full border-2 border-solid border-[#E63888]`}
  ${tw`text-[#E63888] text-base font-bold leading-6`}
`
