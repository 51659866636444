import { PURCHASE } from '../../../../topics'
import { removeCurrency } from '../utils/remove-currency'
import {
  transformProductPurchaseName,
  generateDimension11
} from '../data-transformers/products'

export const coffeeSubscriptionPurchaseAdapter = ({
  currencyCode,
  chargebeeSubscriptionID,
  coffeeSubscription,
  orderId,
  price,
  product
}) => {
  const gtmObj = {
    event: PURCHASE,
    ecommerce: {
      purchase: {}
    },
    pretEcommerce: {}
  }
  gtmObj.ecommerce.purchase = {
    actionField: {
      id: orderId,
      revenue: removeCurrency(price),
      tax: 0,
      coupon: ''
    },
    products: [
      {
        name: transformProductPurchaseName(product?.name, product?.sku),
        id: product.sku,
        price: removeCurrency(price),
        brand: 'Pret',
        category: 'Subscriptions',
        variant: 'Coffee',
        quantity: 1,
        dimension11: generateDimension11(product.sku)
      }
    ]
  }
  gtmObj.pretEcommerce = {
    currencyCode,
    coffeeSubscription,
    checkoutType: 'customer',
    chargebeeSubscriptionID
  }

  return gtmObj
}
