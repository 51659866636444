import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Container = styled('div')`
  ${tw`bg-grey-50 py-6`}

  > div {
    ${tw`mx-auto flex flex-col lg:flex-row`}
  }
`

export const Copy = styled('div')`
  ${tw`py-6 px-4 xl:px-0 lg:w-1/2`}
`

export const P = styled('p')`
  ${tw`py-1`}
`

export const Cta = styled('div')`
  ${tw`flex flex-col md:flex-row space-y-2 md:space-y-0 px-4 md:space-x-8 w-full self-center lg:w-1/2`}

  > * {
    ${tw`flex-1`}
  }
`
