import httpClient from '#lib/http-client'
import { parseCookies } from 'nookies'
import cookies from 'js-cookie'
import {
  fallbackLocaleCode,
  fallbackMarketCode,
  marketIdHeader,
  marketMap
} from '#constants'
import { getMappedSettings } from '#lib/get-site-settings'

export const getUserMarketFromClientRequestHeader = async currentMarket => {
  const response = await httpClient('/api/user-market', {
    headers: { [marketIdHeader]: currentMarket }
  })
  return response.json()
}

const getMarketById = (markets, id) => markets.find(m => m.id === id)

const getMarketByLocale = (markets, locale) =>
  markets.find(({ locales }) => locales.find(({ code }) => code === locale))

export const mapMarket = market => {
  const mappedMarket = marketMap.find(l => l.alias === market)
  return mappedMarket ? mappedMarket.code : market
}

const getMarket = (locale, marketIdFromCookies) => {
  const { markets } = getMappedSettings(fallbackLocaleCode)

  return (
    getMarketById(markets, marketIdFromCookies) ||
    getMarketByLocale(markets, locale) ||
    getMarketById(markets, fallbackMarketCode)
  )
}

export const getMarketFromCtx = ctx => {
  const { market } = parseCookies(ctx)
  return getMarket(ctx.locale, market)
}

export const getMarketDetailsFromLocale = locale => {
  const market = cookies.get('market')
  return getMarket(locale, market)
}
