export const DEFAULT_VALUE_TBC = 'TBC'
export const GIFTING_COFFEE_SUBSCRIPTION = 'subscriptionGift'
export const GIFTING_CURRENCY_CODE_DEFAULT = 'GBP'
export const GIFTING_PRET_NAME = 'Gift Pret Coffee Subscription'
export const GIFTING_PRET_NAME_PREFIX = `${GIFTING_PRET_NAME} - `
export const GTM_DIMENSION_11_DATA_KEY = 'dimension11'
export const PRET_UK_DOMAIN_WITH_SCHEME = 'https://www.pret.co.uk'
export const PRODUCT_BRAND = 'Pret'
export const PRODUCT_CATEGORY_COFFEE_SUBSCRIPTION = 'Subscriptions'
export const PRODUCT_INAPPROPRIATE_ITEMS = [
  'metric5',
  'dimension4',
  'dimension5',
  'position'
]
export const PRODUCT_SUBSCRIPTION_NAME_US = 'Coffee Subscription'
export const PRODUCT_VARIANT_COFFEE_SUBSCRIPTION = 'Coffee'
export const SCREEN_TYPE_LANDING = 'landing screen'
export const SCREEN_TYPE_PAYMENT = 'payment'
export const SCREEN_TYPE_SIGNUP = 'subscription setup screen'
export const SCREEN_TYPE_THANK_YOU = 'thankyou'
export const SECTION_TYPE_CLICK_COLLECT = 'Click & Collect'
export const SECTION_TYPE_COFFEE_SUBSCRIPTION = 'coffeeSubscription'
export const US_CLASSIC_PLAN_FRAGMENT = 'Classic'
export const US_PREMIUM_PLAN_FRAGMENT = 'Premium'
