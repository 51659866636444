import { RECOMMENDATIONS_LOAD } from '../../../../topics'
import { mapCrossSellProducts } from '../utils/map-cross-sell-products'

export const recommednationsLoadAdapter = ({ products }) => {
  const gtmObj = {
    event: RECOMMENDATIONS_LOAD,
    ecommerce: {}
  }
  gtmObj.ecommerce = {
    impressions: mapCrossSellProducts(products)
  }

  return gtmObj
}
