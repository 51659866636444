import Head from 'next/head'

type HrefLang = {
  locale: string
  href?: string
}

type HrefLangsType = {
  pages?: HrefLang[]
}

export const HrefLangs: React.FC<HrefLangsType> = ({ pages = [] }) => {
  return (
    <Head>
      {pages.map(({ locale, href }) => (
        <link key={locale} rel='alternate' href={href} hrefLang={locale} />
      ))}
    </Head>
  )
}
