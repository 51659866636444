import { useAsync, useCookie } from 'react-use'
import { useRouter } from 'next/router'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Heading } from '@pretamanger/component-library'
import { market } from '@proptypes'
import { findInArray } from '#lib/array'
import Translation, { translate } from '#src/common/components/translation'
import {
  getMarketDetailsFromLocale,
  getUserMarketFromClientRequestHeader,
  mapMarket
} from '#lib/get-market'
import { Container, Copy, Cta, P } from './styles'

const notificationCookie = 'display-market-notification'

const MarketNotification = ({ markets }) => {
  const { locale } = useRouter()
  const [userMarket, setUserMarket] = useState()
  const [currentMarket, setCurrentMarket] = useState()
  const [fetchingUserMarket, setFetchingUserMarket] = useState(true)
  const [dismissNotification, setDismissNotification] =
    useCookie(notificationCookie)

  useAsync(async () => {
    if (!userMarket && !dismissNotification) {
      setFetchingUserMarket(true)
      const currentMarket = getMarketDetailsFromLocale(locale)
      const { market } = await getUserMarketFromClientRequestHeader(
        currentMarket.id
      )
      setCurrentMarket(currentMarket.id)
      setUserMarket(mapMarket(market))
    }
    setFetchingUserMarket(false)
  }, [])

  const closeNotification = () => setDismissNotification(true)
  const validUserMarket = findInArray(
    market => market.id === userMarket,
    markets
  )

  const shouldDisplayNotification =
    !!validUserMarket &&
    !dismissNotification &&
    !fetchingUserMarket &&
    userMarket &&
    currentMarket &&
    userMarket !== currentMarket

  if (!shouldDisplayNotification) {
    return null
  }

  const currentMarketName = translate(
    `market-notification.countries.${currentMarket}`,
    locale
  )?.value
  const userMarketName = translate(
    `market-notification.countries.${userMarket}`,
    locale
  )?.value

  return (
    <div aria-live='polite'>
      {shouldDisplayNotification && (
        <Container data-testid='market-notification'>
          <div className='container'>
            <Copy>
              <Heading level='h5' styleOverride='headingXs'>
                <Translation id='market-notification.title' />
              </Heading>
              <P>
                <Translation
                  id='market-notification.message'
                  tokens={{
                    currentMarket: currentMarketName,
                    userMarket: userMarketName
                  }}
                />
              </P>
            </Copy>
            <Cta>
              <Button styleType='primary' href={validUserMarket.link}>
                <Translation
                  id='market-notification.action'
                  tokens={{ userMarket: userMarketName }}
                />
              </Button>
              <Button styleType='secondary' onClick={closeNotification}>
                <Translation id='market-notification.dismiss' />
              </Button>
            </Cta>
          </div>
        </Container>
      )}
    </div>
  )
}

MarketNotification.propTypes = {
  markets: PropTypes.arrayOf(market)
}

MarketNotification.defaultProps = {
  markets: []
}

export default MarketNotification
