import PropTypes from 'prop-types'
import { FeeItemContainer } from './styles'

export const FeeItem = ({ localisedPrice, name }) => {
  return (
    <FeeItemContainer>
      <p>{name}</p>
      <span>{localisedPrice}</span>
    </FeeItemContainer>
  )
}

FeeItem.propTypes = {
  name: PropTypes.string.isRequired,
  localisedPrice: PropTypes.string.isRequired
}
