import { fireEvent } from '#src/common/lib/events'
import { filterGiftingOrderConfirmation } from '../../../subscribers/analytics/gtm-adapter/data-transformers/purchase'
import { OrderConfirmation } from '#src/coffee-subscription/gifting/types'
import { PURCHASE } from '#src/common/lib/events/topics'

export const fireGiftingPurchaseEvent = (
  orderConfirmation: OrderConfirmation
) => {
  fireEvent(PURCHASE, filterGiftingOrderConfirmation(orderConfirmation))
}
