import { fireFeatureClickEvent } from '../core'
import { marketDetails } from '#constants'
import { PRET_UK_DOMAIN_WITH_SCHEME } from '../../../../subscribers/analytics/constants'

export const findPretHomePageEvent = () => {
  fireFeatureClickEvent({
    type: 'option_button',
    name: 'find_a_pret',
    destination: `${PRET_UK_DOMAIN_WITH_SCHEME}/${marketDetails.UK.locale}`
  })
}
