import styled from '@emotion/styled'
import tw from 'twin.macro'

export const LabelWrapper = styled.span`
  ${tw`rounded inline-flex items-center py-1 px-2 ml-2 text-left text-pretRed-700 bg-loyaltyGold`}
  svg {
    ${tw`inline mr-2`}
    fill: white,
    path {
      fill: white;
    }
  }
`

export const LabelText = styled.span`
  ${tw`text-sm text-white`}
`

export const IconWrapper = styled.span`
  ${tw`h-3 w-3 flex items-center mr-1`}
  svg {
    ${tw`h-3 w-3`}
    flex-shrink: 0;
  }
`
