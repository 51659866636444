/* eslint-disable no-eval */
import bunyan from 'bunyan'
import bformat from 'bunyan-format'
import {
  anonymousIdHeader,
  orderAheadBagIdHeader,
  traceId,
  loggingContext
} from '#constants'

const prettyOutputStream = bformat({ outputMode: 'short', color: true })
const cachedLoggerHeader = 'cached-logger'
const noopLogger = {
  debug() {},
  error() {},
  fatal() {},
  info() {},
  trace() {},
  warn() {}
}
let feLogger
let localStorage

const wrappedOutputStream = stream => {
  return {
    write: entry => {
      const logObject = JSON.parse(entry)
      logObject.severity = bunyan.nameFromLevel[logObject.level].toUpperCase()
      logObject.message = logObject.msg
      logObject.versions = process.versions

      delete logObject.time
      stream.write(JSON.stringify(logObject) + '\n')
    }
  }
}

export const getLogger = () => {
  const isBrowser = !process.stdout
  const isDevelopment = process.env.NODE_ENV === 'development'

  if (isBrowser && isDevelopment) {
    feLogger = feLogger || bunyan.createLogger({ name: 'web' })
    return feLogger
  }

  if (isBrowser) {
    return noopLogger
  }

  if (!localStorage) {
    localStorage = eval("require('cls-hooked')")
  }

  const context = localStorage.getNamespace(loggingContext)
  const cachedLogger = context?.get(cachedLoggerHeader)
  if (cachedLogger) {
    return cachedLogger
  }

  const bagId = context?.get(orderAheadBagIdHeader)
  const traceID = context?.get(traceId)
  const anonymousId = context?.get(anonymousIdHeader)
  const logStream = isDevelopment
    ? prettyOutputStream
    : wrappedOutputStream(process.stdout)
  const log = bunyan.createLogger({
    name: 'web',
    stream: logStream,
    bagId,
    traceID,
    anonymousId
  })

  if (process.env.NODE_ENV === 'test') {
    log.level(bunyan.FATAL + 1)
  }

  if (context) {
    context.run(() => {
      context.set(cachedLoggerHeader, log)
    })
  }

  return log
}
