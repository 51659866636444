import { REMOVE_FROM_BAG } from '../../../../topics'
import { mapProduct } from '../utils/map-product'
import { hasActionField } from '../utils/has-action-field'

export const removeFromBagAdapter = ({ ctaType, products }) => {
  const gtmObj = {
    event: REMOVE_FROM_BAG,
    ecommerce: {}
  }
  const mappedProducts = mapProduct(products, ctaType)
  gtmObj.ecommerce = {
    currencyCode: products[0].price.currencyCode,
    remove: {
      products: mappedProducts,
      ...(hasActionField(ctaType) && {
        actionField: { list: ctaType }
      })
    }
  }

  return gtmObj
}
