import { httpClient } from '#lib/http-client'
import { ResponseError } from '../types'
import { anonymousIdHeader } from '#constants'
import { getTokenSilently } from '#src/common/lib/auth0/auth0-functions'
import { Subscription } from '#src/state/types'
import { getEnvVars } from '#src/common/lib/get-env-vars'

export const getSubscriptions = async (
  anonymousId: string
): Promise<{
  subscriptions: Subscription[]
}> => {
  const accessToken = await getTokenSilently()
  const { coffeeSubsManageBaseUrl } = await getEnvVars()

  const response = await httpClient(
    `${coffeeSubsManageBaseUrl}/v1/manage/subscriptions`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        [anonymousIdHeader]: anonymousId
      },
      json: true
    } as RequestInit
  )

  if (!response.ok) {
    throw new ResponseError(response)
  }

  const result = await response.json()
  return result
}

/**
 * @deprecated use named export instead
 */
export default getSubscriptions
