import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { __, compose, is, isEmpty, path, split, isNil } from 'ramda'
import { RichText } from '../content'
import translations from '../../../../translations'
import { mapLocale } from '../../lib/locale'

const TEMPLATE = /{{(.*?)}}/g

const substitute = (s = '', tokens = {}) => {
  return s.replace(TEMPLATE, (original, token) => tokens[token] || original)
}

const getTranslation = (key, locale) => {
  const mappedLocale = mapLocale(locale, true)
  if (typeof key !== 'string') {
    return null
  }
  return compose(path(__, translations[mappedLocale]), split('.'))(key)
}

const replaceTokens = (translation, tokens) => {
  if (isNil(translation?.value)) {
    return { value: null }
  }
  const value = substitute(translation.value, tokens)
  return {
    value: value.match(TEMPLATE) ? translation.fallback : value
  }
}

const replaceTokensInRichText = (nodes, tokens) => {
  const recursiveSubstitute = item =>
    item.content
      ? {
          ...item,
          content: item.content.map(recursiveSubstitute)
        }
      : {
          ...item,
          value: item.value && substitute(item.value, tokens)
        }

  return recursiveSubstitute(nodes)
}

export const translate = (key, locale, tokens) => {
  if (!tokens) {
    return getTranslation(key, locale)
  }
  if (!isNil(tokens.count)) {
    const suffixedKey =
      key + (parseFloat(tokens.count) === 1 ? '.singular' : '.plural')
    return replaceTokens(
      getTranslation(suffixedKey, locale) || getTranslation(key, locale),
      tokens
    )
  }
  return replaceTokens(getTranslation(key, locale), tokens)
}

export const hasTranslationFor = (key, locale) => {
  const translation = getTranslation(key, locale)

  if (isNil(translation)) {
    return false
  }

  return (
    (is(String, translation.value) && translation.value.length > 0) ||
    is(Object, translation.value)
  )
}

const Translation = ({
  id,
  tokens = {},
  openEntityLinksInNewWindow,
  withoutWrapper,
  richTextOptions = {},
  isFormatNewLine = false,
  localeOverride = undefined
}) => {
  const { locale } = useRouter()
  let translation = getTranslation(id, localeOverride || locale)
  let string

  if (!isNil(tokens.count)) {
    translation =
      parseFloat(tokens.count) === 1 ? translation.singular : translation.plural
  }

  if (translation?.type === 'rich') {
    const substitutedValue = isEmpty(tokens)
      ? translation.value
      : replaceTokensInRichText(translation.value, tokens)
    string = (
      <RichText
        openEntityLinksInNewWindow={openEntityLinksInNewWindow}
        text={substitutedValue}
        withoutWrapper={withoutWrapper}
        richTextOptions={richTextOptions}
        isFormatNewLine={isFormatNewLine}
      />
    )
  }

  if (translation?.type === 'plain') {
    string = substitute(translation.value, tokens)
    if (string.match(TEMPLATE)) {
      string = translation.fallback
    }
  }

  return <>{string}</>
}

Translation.propTypes = {
  id: PropTypes.string.isRequired,
  tokens: PropTypes.object,
  openEntityLinksInNewWindow: PropTypes.bool,
  withoutWrapper: PropTypes.bool
}

Translation.defaultProps = {
  tokens: {},
  openEntityLinksInNewWindow: false,
  withoutWrapper: false
}

export default Translation
