import { useReducer, useEffect, useRef, Dispatch } from 'react'

type Reducer<S, A> = (prevState: S, action: A) => S

const useSetState = <T>(initialState: T) => {
  return useReducer<Reducer<T, Partial<T>>>(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  )
}

/**
 * @deprecated  Use `useState` or `useReducer` instead. This function
 * no longer suppresses warnings when called on an unmounted component.
 *
 * This function's purpose was to suppress React warnings about
 * calling setState on an unmounted component, which usually happens when
 * you await for a Promise and setState with the result after the component
 * had unmounted. However, it does not solve the underlying memory leak issue,
 * since a reference to the component still needs to be kept around. The proper
 * solution is to either cancel the Promise when the component unmounts, or use
 * a module store (Redux for example) to store the async data.
 *
 * Since these warning are turned off in prod, we keep them so we know
 * where we can find the memory leaks.
 *
 * See https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html for more info.
 *
 */
export const useSafeSetState = <T>(
  initialState: T
): [T, Dispatch<Partial<T>>] => {
  const [state, setState] = useSetState<T>(initialState)
  return [state, setState]
}
