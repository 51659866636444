import { VIEW_ITEM } from '../../../../topics'

/* eslint-disable camelcase */
export const viewItemAdapter = ({ product, category }) => {
  return {
    event: VIEW_ITEM,
    ecommerce: {
      items: [
        {
          item_id: product.sku,
          item_name: product.name,
          affiliation: 'Online Store',
          item_brand: 'Pret A Manger',
          item_category: 'Click and Collect',
          item_category3: category
        }
      ]
    }
  }
}
