/* eslint-disable camelcase */
import { always, ifElse, isEmpty, isNil, pathOr, propOr } from 'ramda'
import { PAGE_VIEW } from '../../../../topics'
import {
  transformScreenType,
  transformSectionType
} from '../data-transformers/screen'
import { getSessionId } from '#lib/session-id'
import { getBrowserEnvironment } from '../../../../../getBrowserEnv'
import { getDiffFromNowInDays } from '#src/common/lib/date-utils'
import {
  otherCoffeeSubStatuses,
  coffeeSubscriptionPlanIds,
  coffeeSubscriptionStatuses,
  marketDetails,
  subscriptionPeriods
} from '#src/common/constants'
import { getLocaleFromUrl } from '#src/common/init/get-current-locale'
import { Subscription } from '#src/state/types'
import { getPageCategory } from '#src/common/page-config/page-config'

const coffePlanPending = {
  [coffeeSubscriptionPlanIds.US_PREMIUM_COFFEE_PLAN]: 'downgrade',
  [coffeeSubscriptionPlanIds.US_CLASSIC_COFFEE_PLAN]: 'upgrade'
}

const getPendingSubStatus = (
  status: Subscription['status'],
  hasScheduledChanges: boolean,
  planId: string
) =>
  ({
    [coffeeSubscriptionStatuses.SCHEDULED_TO_CANCEL]: 'cancellation',
    [coffeeSubscriptionStatuses.SCHEDULED_TO_PAUSE]: 'pause',
    [coffeeSubscriptionStatuses.PAUSED]: 'pause',
    [coffeeSubscriptionStatuses.ACTIVE]: hasScheduledChanges
      ? coffePlanPending[planId]
      : 'none'
  }[status] || 'none')

export const pageViewAdapter = ({
  claims,
  screenType,
  screenName,
  sectionType,
  locale,
  createdAt,
  subscription,
  rewards,
  rewardsSummary,
  user
}) => {
  const gtmObj = {
    event: PAGE_VIEW,
    ecommerce: {},
    user: {},
    screen: {},
    platform: {},
    stars: {},
    perks: {},
    subscription: {}
  }
  const user_id = pathOr('', ['http://pret.com/pretId'], claims)
  const chargebeeCustomerID = pathOr('', ['http://pret.com/cbeeId'], claims)
  const { host, href, pathname } = window.location
  const screenTypeTransformed = transformScreenType(screenType, pathname)
  const sectionTypeTransformed = transformSectionType(sectionType, pathname)
  const propOrEmpty = prop => propOr('', prop)

  const orEmptyString = callback => ifElse(isNil, always(''), callback)

  const {
    status = '',
    startedAt,
    hasScheduledChanges = '',
    planId = ''
  } = subscription || {}

  const daySinceSubscribed = orEmptyString(
    () => getDiffFromNowInDays(new Date(startedAt).toISOString()) * -1
  )(startedAt)

  const statuses =
    otherCoffeeSubStatuses[status] ||
    coffeeSubscriptionStatuses[status.toLowerCase()] ||
    status

  const period = orEmptyString(() => subscriptionPeriods[status] || 'paid')(
    user
  )

  gtmObj.subscription = {
    subscription_plan: planId,
    subscription_status: statuses,
    subscription_period: period,
    days_since_subscribed: daySinceSubscribed,
    pending_sub_status: getPendingSubStatus(status, hasScheduledChanges, planId)
  }

  gtmObj.user = {
    days_since_signup: orEmptyString(
      () => getDiffFromNowInDays(createdAt) * -1
    )(createdAt)
  }

  const userCountry = Object.values(marketDetails).find(
    v => v.locale.toLowerCase() === locale
  )

  const localeFromUrl = getLocaleFromUrl(href).replace(/.+-/g, '')

  const totalNoOfRewards =
    Number(propOrEmpty('noOfRewards')(rewardsSummary)) * 10
  const totalStarsCollected =
    Number(totalNoOfRewards) +
    Number(propOrEmpty('totalNoOfStars')(rewardsSummary))

  gtmObj.ecommerce = undefined
  gtmObj.user = {
    ...gtmObj.user,
    chargebeeCustomerID,
    loggedIn: Boolean(claims),
    user_status: claims ? 'signed_in' : 'signed_out',
    user_id,
    user_country: propOrEmpty('countryCode')(userCountry),
    site_location: localeFromUrl
  }
  gtmObj.screen = {
    screenType: screenTypeTransformed,
    hitTimestamp: Date.now(),
    screenURL: href,
    screenName,
    hostname: host,
    sectionType: sectionTypeTransformed,
    sessionId: getSessionId(),
    page_category: getPageCategory()
  }
  gtmObj.platform = {
    environment: getBrowserEnvironment(),
    type: 'site',
    technology: 'web',
    localeRegion: 'Europe',
    user_location: 'GB',
    localeLanguage: 'eng'
  }

  gtmObj.stars = {
    current_stars_collected: propOrEmpty('totalNoOfStars')(rewardsSummary),
    total_stars_collected: totalStarsCollected
  }

  gtmObj.perks = {
    total_perks_awarded: propOr(0, 'noOfRewards')(rewardsSummary),
    total_perks_redeemed:
      rewards?.rewards?.filter(
        ({ status = '' } = {}) => status?.toUpperCase() === 'USED'
      )?.length || 0
  }

  return gtmObj
}
