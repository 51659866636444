import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { Grid, GridArea } from '@pretamanger/component-library'
import { image, navigationItem, socialNetworks, market } from '@proptypes'
import Translation, { translate } from '../../../components/translation'
import SocialNetworks from '../../../components/social-networks'
import LocaleLink from '../../../components/locale-link'
import {
  FooterInfoContainer,
  FooterLogo,
  CountrySwitchContainer
} from './styles'
import Navigation from '../navigation'
import CountrySwitch from './country-switch'
import { clientSideGetLaunchDarklyFlag } from '#src/common/lib/get-launch-darkly-flag'
import { getMarketDetailsFromLocale } from '#src/common/lib/get-market'
import { provideAnonymousId } from '#src/common/lib/provide-anonymous-id'
import React, { useMemo, useState } from 'react'
import { useAsync } from 'react-use'
import { showEmployeePrivacyPolicy as constShowEmployeePrivacyPolicy } from '#src/common/constants'

const Footer = ({
  logo,
  navigation,
  socialNetworks,
  markets,
  isAuthenticated
}) => {
  const [showEmployeePrivacyPolicy, setShowEmployeePolicy] = useState(false)
  const { locale } = useRouter()
  const market = useMemo(() => {
    return getMarketDetailsFromLocale(locale)
  }, [locale])
  const anonymousId = provideAnonymousId()

  useAsync(async () => {
    const value = await clientSideGetLaunchDarklyFlag(
      constShowEmployeePrivacyPolicy,
      anonymousId,
      market?.id
    )
    setShowEmployeePolicy(value)
  })

  const hasSocialNetworks = socialNetworks.length > 0

  const small = [
    [{ name: 'navigation', start: 1, span: 4 }],
    [{ name: 'socialNetworks', start: 1, span: 4 }],
    [{ name: 'footer', start: 1, span: 4 }]
  ]

  const medium = [
    [{ name: 'navigation', start: 1, span: 6 }],
    [{ name: 'socialNetworks', start: 1, span: 6 }],
    [{ name: 'footer', start: 1, span: 6 }]
  ]

  const large = [
    [
      { name: 'navigation', start: 1, span: 7 },
      { name: 'socialNetworks', start: 9, span: 4 }
    ],
    [{ name: 'footer', start: 1, span: 12 }]
  ]

  const areas = {
    xs: small,
    sm: medium,
    md: medium,
    lg: large,
    xl: large,
    '2xl': large
  }

  return (
    <Grid areas={areas}>
      <GridArea name='navigation' className='mx-2'>
        {navigation.length > 0 && (
          <Navigation
            items={navigation}
            showEmployeePrivacyPolicy={showEmployeePrivacyPolicy}
            isAuthenticated={isAuthenticated}
            ariaLabel={translate('landmark.navigation.footer', locale)?.value}
          />
        )}
      </GridArea>
      <GridArea name='socialNetworks'>
        {hasSocialNetworks && (
          <SocialNetworks showLabel socialNetworks={socialNetworks} />
        )}
        <CountrySwitchContainer>
          <CountrySwitch markets={markets} />
        </CountrySwitchContainer>
      </GridArea>
      <GridArea name='footer'>
        <FooterInfoContainer>
          {logo && (
            <LocaleLink href='/'>
              <a>
                <FooterLogo src={logo.src} alt={logo.alt} />
              </a>
            </LocaleLink>
          )}
          <div>
            <Translation id='footer.text' />
          </div>
        </FooterInfoContainer>
      </GridArea>
    </Grid>
  )
}

Footer.propTypes = {
  logo: image,
  navigation: PropTypes.arrayOf(navigationItem),
  socialNetworks,
  markets: PropTypes.arrayOf(market),
  isAuthenticated: PropTypes.bool
}

Footer.defaultProps = {
  logo: undefined,
  navigation: [],
  markets: [],
  isAuthenticated: false
}

export default Footer
