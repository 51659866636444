/* eslint-disable @next/next/no-sync-scripts */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Head from 'next/head'
import Script from 'next/script'
import { fireEvent } from '#src/common/lib/events'
import { GTM_JS } from '#src/common/lib/events/topics'

const ThirdPartyScripts = ({ gtmId, gtmHost }) => {
  const [origin, setOrigin] = useState('')
  useEffect(() => {
    fireEvent(GTM_JS)
    setOrigin(
      typeof window !== 'undefined' && window.location.origin
        ? window.location.origin
        : ''
    )
  }, [])
  // note on the below eslint-disable: next/script cannot be placed inside next/head, so I am not amending it
  // However, this should probably be refactored.
  return (
    <>
      {/* GTM */}
      {!!gtmId && !!gtmHost && (
        <>
          <Script
            id='google-consent-mode'
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        
                        gtag('consent', 'default', {
                          'ad_storage': 'denied',
                          'analytics_storage': 'denied',
                          'wait_for_update': 500
                        });`
            }}
          />
          <Script
            id='gtm-host-script'
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            '${gtmHost}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmId}');`
            }}
          />
        </>
      )}
      <script
        src='https://locator.uberall.com/locator-assets/storeFinderWidget-v2.js'
        type='text/javascript'
      ></script>
      <Head>
        <style
          dangerouslySetInnerHTML={{
            __html: '.async-hide { opacity: 0 !important}'
          }}
        />
      </Head>
      <Script
        strategy='beforeInteractive'
        id='gtm-container-id-script'
        dangerouslySetInnerHTML={{
          __html: `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
                      h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
                      (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
                      })(window,document.documentElement,'async-hide','dataLayer',4000,
                      {'CONTAINER_ID':'${gtmId}'});`
        }}
      />
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: 'Pret',
            alternateName: ['Pret A Manger', origin],
            url: origin
          })
        }}
      />
    </>
  )
}

ThirdPartyScripts.propTypes = {
  gtmId: PropTypes.string,
  gtmHost: PropTypes.string
}

export default ThirdPartyScripts
