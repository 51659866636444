import { destroyCookie } from 'nookies'
import { collectionPaths, orderAheadBagIdHeader } from '../constants'
import router from 'next/router'
import { getCollectionPath } from './get-collection-path'

export const handleBagError = (error, locale: string) => {
  if ([401].includes(error?.response?.status)) {
    const collectionPath = getCollectionPath(locale)
    destroyCookie(null, orderAheadBagIdHeader, { path: '/' })
    router.replace(
      `/${locale}/${collectionPath}/${collectionPaths['select-store']}`
    )
  } else {
    throw error
  }
}

export const delayAsync = (ms: number) =>
  new Promise(res => setTimeout(res, ms))

export const convertMinstoMilliSecs = (number: number) => 1000 * 60 * number
