import { useRef, useEffect } from 'react'

const KEYCODE_TAB = 9
const KEY_TAB = 'Tab'

const getKeyboardFocusableElements = element => {
  return [
    ...element.querySelectorAll(
      'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
    )
  ].filter(el => !el.hasAttribute('disabled'))
}

const useFocusTrap = () => {
  const elementRef = useRef(null)
  let firstFocusableElement
  let lastFocusableElement

  const handleFirstElementFocus = e => {
    const isTabPressed = e.key === KEY_TAB || e.keyCode === KEYCODE_TAB
    if (!isTabPressed) {
      return
    }
    if (e.shiftKey && isTabPressed) {
      lastFocusableElement.focus()
      e.preventDefault()
    }
  }

  const handleLastElementFocus = e => {
    const isTabPressed = e.key === KEY_TAB || e.keyCode === KEYCODE_TAB
    if (!isTabPressed) {
      return
    }
    if (!e.shiftKey && isTabPressed) {
      firstFocusableElement.focus()
      e.preventDefault()
    }
  }

  useEffect(() => {
    if (elementRef.current) {
      const focusableElements = getKeyboardFocusableElements(elementRef.current)
      // todo: this needs special attention. firstFocusableElement and lastFocusableElement are recalculated at each render, react complains
      // eslint-disable-next-line react-hooks/exhaustive-deps
      firstFocusableElement = focusableElements[0]
      // eslint-disable-next-line react-hooks/exhaustive-deps
      lastFocusableElement = focusableElements[focusableElements.length - 1]

      firstFocusableElement &&
        firstFocusableElement.addEventListener(
          'keydown',
          handleFirstElementFocus
        )
      lastFocusableElement &&
        lastFocusableElement.addEventListener('keydown', handleLastElementFocus)

      return () => {
        firstFocusableElement &&
          firstFocusableElement.removeEventListener(
            'keydown',
            handleFirstElementFocus
          )
        lastFocusableElement &&
          lastFocusableElement.removeEventListener(
            'keydown',
            handleLastElementFocus
          )
      }
    }
  })

  return elementRef
}

export default useFocusTrap
