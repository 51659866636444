import { getLogger } from '#api/logger-with-context'
import ThirdPartyScripts from '#src/common/app/components/third-party-scripts'
import { ThemeProvider } from '@pretamanger/component-library'
import {
  bagProps,
  category,
  navigation,
  orderDetailsProps,
  page,
  product,
  settings
} from '@proptypes'
import cookies from 'js-cookie'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { wrapRouter } from 'oaf-next.js-router'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { getStore } from '#src/state/store'
import { HrefLangs } from '../components/href-langs'
import { RouteChangeLoadingOverlay } from '../components/loading-overlay/RouteChangeLoadingOverlay'
import { PageViewEvent } from '#lib/events/publishers/analytics/fire-events/components'
import { provideAnonymousId } from '#lib/provide-anonymous-id'
import { contentSecurityPolicy } from '../../../scripts/custom-headers'
import SessionTimeoutComponent from '../components/session-modal'
import { PageTemplateSwitcher } from './pageTemplateSwitcher'

const createSetMarket = markets => market => {
  const log = getLogger()
  if (!markets.some(m => m.id === market)) {
    log.info(
      {
        market,
        allowedMarkets: markets.map(({ id }) => id).join(', ')
      },
      'Market not supported'
    )
    return null
  }
  cookies.set('market', market)
  log.info({ market }, 'Market updated successfully')
}

const App = ({ Component, pageProps }) => {
  const { gtmHost, gtmId, settings, theme } = pageProps
  if (!settings) {
    return null
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter()
  const { metaTitleSuffix, markets } = settings
  const pageTitle =
    pageProps.page?.metaTitle ||
    pageProps.currentFilter?.name ||
    pageProps.product?.name
  const metaTitle = pageTitle
    ? `${pageTitle} | ${metaTitleSuffix}`
    : metaTitleSuffix

  const routerSettings = {
    primaryFocusTarget: '',
    shouldHandleAction: () => true,
    announcePageNavigation: true,
    setPageTitle: false
  }
  const alternateLanguagePages =
    pageProps?.alternateLanguagePages || pageProps?.page?.alternateLanguagePages

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    wrapRouter(router, routerSettings)
    global.setMarket = createSetMarket(markets)
    provideAnonymousId()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])
  const pageToNotIndex = pageProps?.page?.pageToNotIndex
  const { locale } = router
  const csp = contentSecurityPolicy(locale)
  const featureFlags = pageProps.featureFlags || {}

  return (
    <Provider store={getStore(featureFlags)}>
      <ThirdPartyScripts gtmId={gtmId} gtmHost={gtmHost} />
      <Head>
        <title>{metaTitle}</title>
        {/* Added below code related to the Content Security Policy handled from meta tag */}
        {csp && <meta httpEquiv='Content-Security-Policy' content={csp} />}
        {pageProps.page?.description && (
          <meta name='description' content={pageProps.page.description} />
        )}
        {pageProps.page?.canonicalLink && (
          <link rel='canonical' href={pageProps.page.canonicalLink} />
        )}
        <link rel='icon' type='image/png' href='/favicon.svg' />
        {pageToNotIndex && <meta name='robots' content='noindex' />}
        <meta property='og:site_name' content='Pret' />
      </Head>
      <HrefLangs pages={alternateLanguagePages} />
      <PageViewEvent gtmPageProps={pageProps.gtmPageProps} />
      <RouteChangeLoadingOverlay />
      <ThemeProvider theme={theme}>
        <PageTemplateSwitcher Component={Component} pageProps={pageProps} />
      </ThemeProvider>
      <SessionTimeoutComponent />
    </Provider>
  )
}

App.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  pageProps: PropTypes.shape({
    bagInitialValue: bagProps,
    currentFilter: category,
    customPage: PropTypes.bool,
    displayBag: PropTypes.bool,
    displayPickupTime: PropTypes.bool,
    displayUserAccountLink: PropTypes.bool,
    gtmHost: PropTypes.string,
    gtmId: PropTypes.string,
    isYourPret: PropTypes.bool,
    isTransactional: PropTypes.bool,
    navigation: navigation,
    orderDetails: orderDetailsProps,
    page,
    product,
    settings: settings.isRequired,
    showOrderSlotBanner: PropTypes.bool,
    showLoyaltyBanner: PropTypes.bool,
    isAuth0Active: PropTypes.bool,
    featureFlags: PropTypes.object
  })
}
App.defaultProps = {
  pageProps: {
    displayUserAccountLink: true,
    displayPickupTime: true,
    isAuth0Active: false,
    navigation: {}
  }
}

export default App
