import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { pageProps } from '@proptypes'
import { useAsync } from 'react-use'
import { clientSideGetLaunchDarklyFlag } from '#src/common/lib/get-launch-darkly-flag'
import OrderAheadBagProvider from '../../context/order-ahead-bag'
import Layout from '../components/layout'
import { OneTrust } from '../../components/one-trust'
import { Ketch } from '../../components/ketch'
import Auth0Provider from '../../context/auth0-context'
import ToastContainer from '../../../auth/my-account-page/components/toast-container'
import { useAppSelector, useAppDispatch } from '../../../state/redux-hooks'
import { bagSelector } from '../../../state/bag-state-slice'
import { subscriptionBagIdHeader, tagWebTrendsFlag } from '#constants'
import { useRouter } from 'next/router'
import {
  loadSubscriptionBag,
  subscriptionBagSelector
} from '#src/state/subscription-bag-state-slice'
import { useCookie } from '#hooks'
import { envSelector } from '#src/state/env-state-slice'
import { ketchFlag, gnattaWebchatFlag } from '#constants'
import { provideAnonymousId } from '#src/common/lib/provide-anonymous-id'
import { getMarketDetailsFromLocale } from '#src/common/lib/get-market'
import { GnattaChatBotComponent } from '#src/common/components/gnatta'
import { WebTrends } from '#src/common/components/webtrends'

const CommonPageTemplate = ({ children, pageProps }) => {
  const dispatch = useAppDispatch()
  const bag = useAppSelector(bagSelector)
  const env = useAppSelector(envSelector)
  const { isLoading: subBagLoading } = useAppSelector(subscriptionBagSelector)
  const { locale } = useRouter()
  const [currentBagId] = useCookie(subscriptionBagIdHeader)
  const anonymousId = provideAnonymousId()
  const market = useMemo(() => {
    return getMarketDetailsFromLocale(locale)
  }, [locale])
  const [currentScript, setCurrentScript] = useState(null)
  const [isEnableGnatta, setEnableGnattaChat] = useState(false)

  useEffect(() => {
    const gnattaChatElem = document?.getElementsByClassName('gnattaChat')
    if (gnattaChatElem[0]) {
      const pageSlug = pageProps?.page?.slug
      const busyButton = document?.getElementById('gwc-chatBusy')
      const offlineButton = document?.getElementById('gwc-chatOffline')
      // always hide button when busy and offline
      if (busyButton && offlineButton) {
        busyButton.setAttribute('hidden', '')
        offlineButton.setAttribute('hidden', '')
      }
      if (pageSlug !== 'talk-to-pret' || pageSlug == undefined) {
        gnattaChatElem[0].setAttribute('hidden', '')
      } else {
        gnattaChatElem[0].removeAttribute('hidden')
      }
    }
  }, [pageProps, isEnableGnatta])
  const [useTagWebTrends, setUseTagWebTrends] = useState(false)

  useEffect(() => {
    if (
      !currentBagId ||
      currentBagId === 'undefined' ||
      subBagLoading ||
      !Object.keys(env).length
    )
      return

    dispatch(
      loadSubscriptionBag({
        locale,
        currentBagId
      })
    )
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentBagId, env])

  useAsync(async () => {
    const value = await clientSideGetLaunchDarklyFlag(
      ketchFlag,
      anonymousId,
      market?.id
    )
    const wtFlagvalue = await clientSideGetLaunchDarklyFlag(
      tagWebTrendsFlag,
      anonymousId,
      market?.id
    )

    if (wtFlagvalue) {
      setUseTagWebTrends(true)
    }

    if (value) {
      setCurrentScript('ketch')
    } else {
      setCurrentScript('oneTrust')
    }
  })

  useAsync(async () => {
    const value = await clientSideGetLaunchDarklyFlag(
      gnattaWebchatFlag,
      anonymousId,
      market?.id
    )
    if (value) {
      setEnableGnattaChat(true)
    }
  }, [pageProps])

  return (
    <Auth0Provider>
      <OrderAheadBagProvider initialValue={bag}>
        {isEnableGnatta && <GnattaChatBotComponent />}
        <WebTrends visible={useTagWebTrends} />
        {currentScript === 'ketch' && <Ketch />}
        {currentScript === 'oneTrust' && <OneTrust />}
        <Layout
          navigation={pageProps.navigation}
          settings={pageProps.settings}
          isTransactional={pageProps.isTransactional}
          isYourPret={pageProps.isYourPret}
          orderDetails={pageProps.orderDetails}
          showOrderSlotBanner={pageProps.showOrderSlotBanner}
          showLoyaltyBanner={pageProps.showLoyaltyBanner}
          displayBag={pageProps.displayBag}
          displayUserAccountLink={pageProps.displayUserAccountLink}
          displayPickupTime={pageProps.displayPickupTime}
          authApiHost={pageProps.authApiHost}
          marketDetails={pageProps.marketDetails}
          cutoffTimeEnabledFlag={pageProps.cutoffTimeEnabledFlag}
          isMyaccount={!!pageProps?.myAccountV3}
        >
          {children}
          <ToastContainer />
        </Layout>
      </OrderAheadBagProvider>
    </Auth0Provider>
  )
}

CommonPageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  pageProps
}
CommonPageTemplate.defaultProps = {
  pageProps: {
    displayUserAccountLink: true,
    displayPickupTime: true
  }
}

export default CommonPageTemplate
