import { PRODUCT_CLICK } from '../../../../topics'
import { removeCurrency } from '../utils/remove-currency'

export const productClickAdapter = ({
  selectedCategoryName,
  position,
  product: { name, sku, price, categories: productSubCategories = [] }
}) => {
  const gtmObj = {
    event: PRODUCT_CLICK,
    ecommerce: {}
  }
  const productSubCategoryName = productSubCategories[0]?.name
  gtmObj.ecommerce = {
    click: {
      actionField: { list: productSubCategoryName },
      products: [
        {
          name,
          id: sku,
          price: removeCurrency(price.localisedPrice),
          category: selectedCategoryName,
          variant: productSubCategoryName,
          brand: 'Pret',
          position
        }
      ]
    }
  }

  return gtmObj
}
