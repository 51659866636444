import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { Wrapper } from './styles'
import Heading from './heading'
import BlockQuote from './block-quote'
import Link from './link'
import Image from './image'
import Bold from './bold'
import Italic from './italic'

const Markdown = ({ text }) => {
  const renderers = {
    heading: Heading,
    blockquote: BlockQuote,
    link: Link,
    image: Image,
    strong: Bold,
    emphasis: Italic
  }

  return (
    <Wrapper>
      <ReactMarkdown source={text} renderers={renderers} />
    </Wrapper>
  )
}

Markdown.propTypes = {
  text: PropTypes.string.isRequired
}

export default Markdown
