import { anonymousIdHeader } from '#constants'
import { client } from '../auth0/client'
import { getTokenSilently } from '../auth0/auth0-functions'
import { getEnvVars } from '#src/common/lib/get-env-vars'

export const getPaymentSources = async anonymousId => {
  const accessToken = await getTokenSilently()
  const headers = anonymousId ? { [anonymousIdHeader]: anonymousId } : {}
  const { coffeeSubsManageBaseUrl } = await getEnvVars()

  return await client('/v1/manage/payment-sources', {
    apiUrl: coffeeSubsManageBaseUrl,
    token: accessToken,
    headers
  })
}
