import { PreferencesResponse } from '#src/auth/my-account-page/types'
import { getTokenSilently } from '../auth0/auth0-functions'
import platformClient from '#lib/platform-client'
import { authHeader } from '#constants'

export const getPreferences = async (): Promise<PreferencesResponse> => {
  const authToken = await getTokenSilently()
  const result = await platformClient(
    '/customers/notifications/preferences/marketing',
    {
      headers: {
        [authHeader]: `Bearer ${authToken}`
      }
    }
  )
  return result
}

export const getPreferencesCenter = async (): Promise<any> => {
  const authToken = await getTokenSilently()
  const result = await platformClient(
    '/customers/notifications/preferences/custom-preferences',
    {
      headers: {
        [authHeader]: `Bearer ${authToken}`
      }
    }
  )
  return result
}

export const getPretStore = async (): Promise<any> => {
  const result = await platformClient('/shops')
  return result
}
