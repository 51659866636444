export const addLastOrderFlag = 'ADD_LAST_ORDER'
export const outpostFlag = 'OUTPOST'
export const previewFlag = 'PREVIEW'
export const auth0Flag = 'AUTH0'
export const strategicalShopFinderFlag = 'UNIFIED_SHOP_FINDER'
export const loyaltyFlag = 'LOYALTY_WEB'
export const loyaltyTrialFlag = 'LOYALTY_WEB_TRIAL'
export const coffeeSubsApplePayFlag = 'CS_APPLE_PAY'
export const mentionMeFlag = 'USE_MENTION_ME'
export const featuredProductBannerFlag = 'FEATURED_PRODUCT_BANNER'
export const inTrialCancellationFlag = 'IN_TRIAL_CANCELLATION'
export const MANAGE_CARDS_APP_PAY_FLAG = 'manage-cards-apple-pay'
export const coffeeSubsGiftingFlag = 'COFFEE_SUBSCRIPTION_GIFTING'
export const subscriptionDisabledFlag = 'SHUTTER_COFFEE_SUBSCRIPTION'
export const recommendationsFlag = 'CROSS_SELL'
export const hideBillingHistoryFlag = 'HIDE_BILLING_HISTORY'
export const useCustomInvoiceGeneration = 'USE_CS_MANAGE_INVOICE_GENERATION'
export const googlePayCoffeeSubsFlag = 'CS_GOOGLE_PAY'
export const googlePayManageCardsFlag = 'MC_GOOGLE_PAY'
export const googlePayCheckoutFlag = 'CC_GOOGLE_PAY'
export const outpostCutoffFlag = 'OUTPOST_CUTOFF_TIME'
export const gnattaFlag = 'USE_GNATTA'
export const egcContactUsFlag = 'USE_CONTACT_US_EGC'
export const clickAndCollectShutter = 'SHUTTER_CLICK_AND_COLLECT'
export const coffeeSubsCommerceToolsRemovalFlag = 'CS_COMMERCE_TOOLS_REMOVAL'
export const myAccountV2Flag = 'WEB_MY_ACCOUNT_V2'
export const myAccountV3Flag = 'USE_MY_ACCOUNT_V3'
export const filterAllergensFlag = 'USE_FILTER_ALLERGENS'
export const priceChangeNoticeFlag = 'CS_PRICE_CHANGE_NOTIFICATION_NEW_SUBS'
export const showUberallWidget = 'SHOW_UBERALL_WIDGET'
export const csRemoveSignUpFlag = 'CS_REMOVE_SIGN_UP'
export const csCbeeRetentionFlag = 'CS_CBEE_RETENTION'
export const csSubscriptionCalculatorFlag = 'SHOW_COFFEESUBS_CALC'
export const enableChangePhoneNumberFlag = 'ENABLE_CHANGE_PHONE_NUMBER'
export const pauseImmediateFlag = 'CS_PAUSE_IMMEDIATELY'
export const showEmployeePrivacyPolicy = 'SHOW_EMPLOYEE_PRIVACY_POLICY'
export const clubPretFlag = 'CS_CLUB_PRET'
export const firstMonthOfferFlag = 'CS_FIRST_MONTH_OFFER'
export const pretDeliversMenuFlag = 'SHOW_PRET_DELIVERS_MENU'
export const ketchFlag = 'USE_KETCH'
export const ENABLE_PRET_DELIVERS_FLAG = 'ENABLE_PRET_DELIVERS'
export const clubPretEmployeeFlag = 'CLUB_PRET_EMPLOYEE'
export const gnattaWebchatFlag = 'USE_GNATTA_WEBCHAT'
export const tagWebTrendsFlag = 'USE_TAG_WEBTRENDS'
export const webShowQRCodeFlag = 'WEB_SHOW_QR_CODE'
export const deleteAccountFlag = 'USE_DELETE_ACCOUNT'
export const showMyAccountOverlayFlag = 'SHOW_MY_ACCOUNT_OVERLAY_BANNER'
export const clubPretFlagV2 = 'CLUB_PRET_2.0'
export const reactivateSubsDisabledFlag = 'SHUTTER_REACTIVATE_SUBSCRIPTION'
export const basketAdjudicationFlag = 'BASKET_ADJUDICATION'
export const shutterPauseSubscriptionFlag = 'SHUTTER_PAUSE_SUBSCRIPTION'
export const shutterRemoveCancellationFlag = 'SHUTTER_REMOVE_CANCELLATION'
