import styled from '@emotion/styled'
import { Heading } from '@pretamanger/component-library'
import tw from 'twin.macro'

export const FourOhFourHeading = styled(Heading)`
  ${tw`text-grey-500! mb-2`}
`

const small = [
  [{ name: 'copy', start: 1, span: 4 }],
  [{ name: 'image', start: 1, span: 4 }]
]

const medium = [
  [{ name: 'copy', start: 1, span: 6 }],
  [
    { name: 'image', start: 2, span: 4 },
    { name: '.', start: 6, span: 1 }
  ]
]

const large = [
  [
    { name: 'copy', start: 2, span: 5 },
    { name: 'image', start: 7, span: 5 },
    { name: '.', start: 12, span: 1 }
  ]
]

export const areas = {
  xs: small,
  sm: medium,
  md: medium,
  lg: large,
  xl: large,
  '2xl': large
}
