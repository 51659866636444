import React, { useEffect, useRef } from 'react'
import { useAppSelector } from '#src/state/redux-hooks'
import { accountSelector } from '#src/state/account-state-slice'
import { firePageViewEvent } from '../page-view'
import { profileSelector } from '#src/state/profile-state-slice'
import { useRouter } from 'next/router'
import { rewardsSelector } from '#src/state/rewards-state-slice'

type Props = {
  gtmPageProps: {
    [key: string]: string
  }
}

// TODO T2D-550 - rename and move to another location?
export const PageViewEvent: React.FC<Props> = ({ gtmPageProps }) => {
  const { claims, isLoading, subscriptions, loaded, user } =
    useAppSelector(accountSelector)
  const {
    locale,
    createdAt,
    isLoading: isProfileLoading,
    isLoaded
  } = useAppSelector(profileSelector)
  const {
    isLoading: isRewardsLoading,
    rewards,
    rewardsSummary,
    isLoaded: isRewardsLoaded
  } = useAppSelector(rewardsSelector)

  const { pathname } = useRouter()

  const isFired = useRef(false)

  // This should be using isLoaded state to make sure the event will be called when all the data loaded
  // We cannot use only isLoading state, since all those initial values are false
  const isAllDataLoaded = loaded && ((isLoaded && isRewardsLoaded) || !user)

  useEffect(() => {
    isFired.current = false
  }, [pathname])

  useEffect(() => {
    if (isAllDataLoaded && !isFired.current) {
      firePageViewEvent({
        gtmPageProps,
        claims,
        isLoading: !isAllDataLoaded,
        locale,
        createdAt,
        subscriptions,
        rewardsSummary,
        rewards,
        user
      })
      isFired.current = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gtmPageProps,
    claims,
    locale,
    createdAt,
    subscriptions,
    isAllDataLoaded,
    rewardsSummary,
    rewards,
    pathname
  ])

  return null
}
