import {
  coffeeSubscriptionPlanIds,
  localeToCurrencyMap
} from '#src/common/constants'
import { actionField } from '#src/common/lib/events/topics'
import {
  GIFTING_CURRENCY_CODE_DEFAULT,
  GIFTING_PRET_NAME,
  PRODUCT_BRAND,
  PRODUCT_CATEGORY_COFFEE_SUBSCRIPTION,
  PRODUCT_VARIANT_COFFEE_SUBSCRIPTION
} from '../../../../subscribers/analytics/constants'

export const getCoffeeSubAddToCartPayload = (
  currencyCode = localeToCurrencyMap['en-GB'],
  sku = coffeeSubscriptionPlanIds.UK_COFFEE_PLAN
) => ({
  event: 'addToCart',
  price: '0',
  products: [
    {
      brand: 'Pret',
      categories: [
        {
          id: 'Subscriptions'
        }
      ],
      name: 'Coffee Subscription',
      price: {
        currencyCode,
        localisedPrice: '0.00'
      },
      quantity: 1,
      recommendationId: '',
      recommendationPosition: '',
      id: sku,
      sku,
      variant: 'Coffee'
    }
  ]
})

export const getCoffeeSubUSClassicAddToCartPayload = () =>
  getCoffeeSubAddToCartPayload(
    'USD',
    coffeeSubscriptionPlanIds.US_CLASSIC_COFFEE_PLAN
  )

export const getCoffeeSubUSPremiumAddToCartPayload = () =>
  getCoffeeSubAddToCartPayload(
    'USD',
    coffeeSubscriptionPlanIds.US_PREMIUM_COFFEE_PLAN
  )

export const getClickEventAddToBagGiftingPayload = () => ({
  ctaType: actionField.gifting,
  products: [
    {
      brand: PRODUCT_BRAND,
      category: PRODUCT_CATEGORY_COFFEE_SUBSCRIPTION,
      name: GIFTING_PRET_NAME,
      price: {
        currencyCode: GIFTING_CURRENCY_CODE_DEFAULT
      },
      variant: PRODUCT_VARIANT_COFFEE_SUBSCRIPTION,

      // This is a temporary value subject to approval by Brainlabs.
      // Friday 19th November 2021.
      dimension11: 'UK'
    }
  ]
})
