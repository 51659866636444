import styled from '@emotion/styled'
import { css } from '@emotion/react'
import tw from 'twin.macro'
import { Cross, Button } from '@pretamanger/component-library'

export const BagOverlay = styled('div')`
  ${tw`min-w-full fixed top-0 right-0 h-full z-50 transition cursor-pointer`}
  background-color: rgba(64, 64, 64, 0.4);
  backdrop-filter: blur(4px);
  margin-top: var(--bag-offset-top, 0);
`
const dynamicStyle = props =>
  css`
    ${tw`max-h-full w-11/12 md:w-8/12 lg:w-6/12 xl:w-5/12 bg-white transform translate-x-full
      transition-transform duration-200 ease-in-out z-50 fixed bottom-0 top-0 right-0 invisible`}
    ${props.isOpened &&
    tw`transform translate-x-0 transition-transform duration-300 ease-in-out visible focus:shadow-outline outline-none`}
    margin-top: var(--bag-offset-top, 0);
  `
export const BagWrapper = styled.div(dynamicStyle)

export const BagCross = styled(Cross)`
  ${tw`inline px-1 cursor-pointer mr-2`}
`
export const StyledButton = styled('button')`
  ${tw`focus:outline-none focus:shadow-outline`}
`
export const BagHeading = styled('div')`
  ${tw`bg-grey-100 p-4 flex items-center`}
  span {
    ${tw`ml-1`}
  }
`
export const BagPanel = styled('div')`
  ${tw`flex flex-col h-full`}
`
export const BagContent = styled('div')`
  ${tw`overflow-y-scroll flex flex-shrink flex-col h-full overflow-auto`}
  h5 {
    ${tw`pt-8 px-4 pb-0`}
  }
`
export const LoadingText = styled('p')`
  ${tw`p-4`}
`
export const EmptyBagText = styled('div')`
  ${tw`text-grey-500 p-4`}
`

export const BagFooter = styled('div')`
  ${tw`w-full bottom-0 p-4`}
`
export const CheckoutButton = styled(Button)`
  ${tw`w-full sm:w-10/12 px-1`}
`

export const BagPriceWrapper = styled('div')`
  ${tw`border-t border-solid border-grey-200 relative md:mb-6`}
`

export const BagTotalPrice = styled('div')`
  ${tw`inline-block w-full sm:w-10/12 bottom-0 p-4 sm:pt-4 text-right`}
  span {
    &:nth-of-type(1) {
      ${tw`pr-4 flex-grow`}
    }
  }
`
export const SignInButton = styled('button')`
  ${tw`focus:outline-none focus:shadow-outline text-grey-700 p-0 m-0 ml-2 mt-2 text-pretRed-700`}
`

export const CutleryContainerWrapper = styled('div')`
  ${tw`w-full sm:w-10/12 mt-4 pl-2 bg-grey-100`}
  label {
    ${tw`bg-white`}
  }
`
