import { getLogger } from '#api/logger-with-context'
import {
  ADD_TO_BAG,
  CHECKOUT,
  COFFEE_SUBSCRIPTION_PURCHASE,
  CUSTOM,
  FEATURE_CLICK,
  GTM_JS,
  LINK_CLICK,
  PAGE_VIEW,
  PURCHASE,
  RECOMMENDATIONS_LOAD,
  REMOVE_FROM_BAG,
  TRANSACTIONAL_PRODUCT_DETAILS,
  PRODUCT_CLICK,
  SELECT_OPTION,
  PRODUCT_LIST_IMPRESSION,
  LOGOUT_LINK_CLICK,
  SUBSCRIPTION_PAUSED,
  SUBSCRIPTION_PAUSE_REMOVED,
  SUBSCRIPTION_CANCELLED,
  SUBSCRIPTION_CANCELLATION_REMOVED,
  SUBSCRIPTION_CHANGED,
  SUBSCRIPTION_REACTIVATED,
  SELECT_ITEM,
  VIEW_ITEM,
  BEGIN_CHECKOUT,
  ADD_PAYMENT_INFO,
  SUBSCRIPTION_ACTIVATED,
  ADD_TO_CART,
  PAYMENT_FAILURE
} from '../../topics'
import { eventSubscribe } from '../../'
import gtmAdapter from './gtm-adapter'

export const eventsKey = 'pret-user-events'

const storeEvent = (name, value) => window.localStorage.setItem(name, value)
const getEvent = name => window.localStorage.getItem(name)

export const subscriber = (event, data, uniqueEventId) => {
  if (typeof window === 'undefined' || !window?.dataLayer) {
    return
  }
  const log = getLogger()

  if (uniqueEventId) {
    const uniqueUserEvents = getEvent(eventsKey)
    const uniqueUserEventsArray = uniqueUserEvents
      ? JSON.parse(uniqueUserEvents)
      : []

    if (uniqueUserEventsArray.includes(uniqueEventId)) {
      return
    }

    if (uniqueUserEventsArray.length >= 10) {
      uniqueUserEventsArray.shift()
    }

    storeEvent(
      eventsKey,
      JSON.stringify([...uniqueUserEventsArray, uniqueEventId])
    )
  }

  const analyticsEvent = gtmAdapter(event, data)

  window.dataLayer.push(analyticsEvent)
  log.info({ event, data }, 'Analytics event fired')
}

eventSubscribe(ADD_TO_BAG, subscriber)
eventSubscribe(CHECKOUT, subscriber)
eventSubscribe(CUSTOM, subscriber)
eventSubscribe(FEATURE_CLICK, subscriber)
eventSubscribe(RECOMMENDATIONS_LOAD, subscriber)
eventSubscribe(PRODUCT_LIST_IMPRESSION, subscriber)
eventSubscribe(PURCHASE, (topic, event) =>
  subscriber(topic, event, event.orderNumber)
)
eventSubscribe(REMOVE_FROM_BAG, subscriber)
eventSubscribe(GTM_JS, subscriber)
eventSubscribe(PAGE_VIEW, subscriber)
eventSubscribe(LINK_CLICK, subscriber)
eventSubscribe(LOGOUT_LINK_CLICK, subscriber)
eventSubscribe(TRANSACTIONAL_PRODUCT_DETAILS, subscriber)
eventSubscribe(PRODUCT_CLICK, subscriber)
eventSubscribe(SELECT_OPTION, subscriber)
eventSubscribe(SELECT_ITEM, subscriber)
eventSubscribe(VIEW_ITEM, subscriber)
eventSubscribe(COFFEE_SUBSCRIPTION_PURCHASE, (topic, event) => {
  subscriber(topic, event, event.orderId)
})
eventSubscribe(SUBSCRIPTION_PAUSED, subscriber)
eventSubscribe(SUBSCRIPTION_PAUSE_REMOVED, subscriber)
eventSubscribe(SUBSCRIPTION_CANCELLED, subscriber)
eventSubscribe(SUBSCRIPTION_CANCELLATION_REMOVED, subscriber)
eventSubscribe(SUBSCRIPTION_CHANGED, subscriber)
eventSubscribe(SUBSCRIPTION_REACTIVATED, subscriber)
eventSubscribe(BEGIN_CHECKOUT, subscriber)
eventSubscribe(ADD_PAYMENT_INFO, subscriber)
eventSubscribe(SUBSCRIPTION_ACTIVATED, subscriber)
eventSubscribe(ADD_TO_CART, subscriber)
eventSubscribe(PAYMENT_FAILURE, subscriber)
