import { asyncValidators } from '#src/common/lib/api/form'

export const mapUntil = async (arr, mapFn, predicateFn) => {
  const result = []
  for (let i = 0; i < arr.length; i++) {
    result.push(await mapFn(arr[i]))
    if (predicateFn(result[i])) {
      break
    }
  }
  return result
}

class ValidateResults {
  constructor() {
    this.result = {}
    this.violation = ''
  }

  addFieldResult(name, isValid) {
    if (isValid) {
      this.violation = ''
    } else {
      this.violation = name
    }
    this.result[name] = { name, isValid }
  }

  isValid() {
    return Object.values(this.result).every(({ isValid }) => isValid)
  }

  async validate(field, value, fields, isFormValidating) {
    if (field.type || (field.validateOnSubmit && isFormValidating)) {
      ;(
        await mapUntil(
          [
            ...(field.type || []),
            ...(isFormValidating && field.validateOnSubmit
              ? field.validateOnSubmit
              : [])
          ],
          async type => {
            const [name, ...params] = type.split(':')
            if (name.startsWith('ext')) {
              return {
                name,
                result: await asyncValidators[name](value, ...params)
              }
            }
            if (params[0]) {
              return {
                name,
                result: await asyncValidators[name](
                  value,
                  fields[params[0]].value
                )
              }
            }
            return { name, result: await asyncValidators[name](value) }
          },
          ({ result }) => result === false
        )
      ).forEach(({ name, result }) => {
        this.addFieldResult(name, result)
      })
    }
    return this
  }

  summary() {
    return { isValid: this.isValid(), violation: this.violation }
  }
}

export default ValidateResults
