import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from './store'
import { getCompanyDetails } from '#src/auth/my-account-page/adapter/my-account.adapter'

type IAddress = {
  id?: string
  building: string
  addressLine1: string
  addressLine2: string
  city: string
  postCode: string
  country: string
  stateOrProvince?: string
}

export interface CompanyDetails {
  companyId: string
  companyNo: string
  name: string
  tradingName: string
  vatNumber: string
  shippingAddress: IAddress
  billingAddress: IAddress
  costCenterInfo?: any
  invoiceEmail?: string
  country?: string
  upgradeTo?: string
  availableCredit?: number
  creditLimit?: number
  isAccountSuspended?: boolean
}

const initialState: CompanyDetails = {
  companyId: '',
  companyNo: '',
  billingAddress: {
    building: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postCode: '',
    country: ''
  },
  name: '',
  shippingAddress: {
    building: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postCode: '',
    country: ''
  },
  tradingName: '',
  vatNumber: '',
  isAccountSuspended: false
}

const getCompanyInformation = createAsyncThunk<
  CompanyDetails,
  { locale: string },
  { state: RootState }
>('company.getCompanyDetails', async ({ locale }) => {
  return await getCompanyDetails(locale)
})

const showLoader = (state: CompanyDetails) => ({ ...state, isLoading: true })

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCompanyInformation.pending, showLoader)
    builder.addCase(getCompanyInformation.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false
    }))
    builder.addCase(getCompanyInformation.rejected, state => ({
      ...state,
      isLoading: false
    }))
  }
})

const { actions } = companySlice
export const companyAction = {
  ...actions,
  getCompanyInformation
}

export const companyInformationSelector = ({ company }: RootState) => company
