import { getPreferencesCenter } from '#src/common/lib/preferences/get-preferences'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export type PreferencesCenterState = {
  productFeedback: boolean
  shareDataFeedback: boolean
  preferredFoods: Array<string>
  preferredStore: string
  dob: string
  statusOk?: boolean
  isLoading?: boolean
  error?: boolean
}

const initialState: PreferencesCenterState = {
  productFeedback: false,
  shareDataFeedback: false,
  preferredFoods: [],
  preferredStore: null,
  dob: null
}

export const fetchPreferencesCenter = createAsyncThunk<
  PreferencesCenterState,
  void,
  { state: RootState }
>('preferences/fetchPreferencesCenter', async () => {
  return await getPreferencesCenter()
})

export const preferencesCenterSlice = createSlice({
  name: 'preferencesCenter',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      fetchPreferencesCenter.pending,
      (state: PreferencesCenterState) => {
        state.isLoading = true
        state.statusOk = false
        state.error = false
      }
    )
    builder.addCase(
      fetchPreferencesCenter.rejected,
      (state: PreferencesCenterState) => {
        state.isLoading = false
        state.statusOk = false
        state.error = true
      }
    )
    builder.addCase(
      fetchPreferencesCenter.fulfilled,
      (state: PreferencesCenterState, action: PayloadAction<any>) => ({
        ...state,
        preferredFoods: action.payload.preferred_foods,
        preferredStore: action.payload.preferred_store,
        dob: action.payload.dob,
        productFeedback: action.payload.product_feedback,
        shareDataFeedback: action.payload.share_data_feedback,
        ...action.payload,
        isLoading: false,
        error: false,
        statusOk: true
      })
    )
  }
})

export const preferencesCenterSelector = ({
  preferencesCenter
}: RootState): PreferencesCenterState => preferencesCenter
