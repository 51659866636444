import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

export interface EnvState {
  platformApiHost?: string
  authApiBaseUrl?: string
  apiHost?: string
  checkoutApiHost?: string
  auth0Audience?: string
  auth0ClientId?: string
  auth0Domain?: string
  clickAndCollectUrl?: string
  clickAndCollectToken?: string
  mentionMeTag?: string
  sessionTimeout?: string
  promptTimeout?: string
  impersonationSessionTimeout?: string
}

const initialState: EnvState = {}

export const envStateSlice = createSlice({
  name: 'env',
  initialState,
  reducers: {
    loadEnv: (state, action: PayloadAction<EnvState>) => ({
      ...state,
      ...action.payload
    })
  }
})

export const envSelector = ({ env }: RootState): EnvState => env
