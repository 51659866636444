declare global {
  namespace NodeJS {
    interface Global {}
  }
}

export class ResponseError extends Error {
  response: any

  constructor(res: any) {
    super(res.statusText)

    this.response = res

    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export interface Global extends NodeJS.Global {
  document: Document
  window: Window
  AdyenCheckout: any
}
