import { useEffect, useState, useMemo } from 'react'
import { useAsync } from 'react-use'
import pipe from 'ramda/src/pipe'
import { useCookie } from '#hooks'
import * as bagApi from '#src/common/lib/api/bag'
import { fireEvent } from '#lib/events'
import { ADD_TO_BAG, REMOVE_FROM_BAG } from '#lib/events/topics'
import { actionField } from '../lib/events/topics'
import { caffeineOptions } from '#src/coffee-wizard/constants'
import { useAppDispatch, useAppSelector } from '../../state/redux-hooks'
import { envSelector } from '../../state/env-state-slice'
import { bagStateSlice } from '../../state/bag-state-slice'
import { getOrderDetails, processTaxOnBag } from '#src/checkout/api/client'
import { orderDetailsStateSlice } from '../../state/order-details-state-slice'
import { accountSelector } from '#src/state/account-state-slice'
import { subscriptionBagIdHeader } from '../constants'
import { getTokenSilently } from '#lib/auth0/auth0-functions'
import { getMarketDetailsFromLocale } from '#lib/get-market'

const mapCustomisedProducts = products =>
  products
    .filter(product => product.subLineItems?.length)
    .map(customisedProduct => {
      return {
        productSku: customisedProduct.sku,
        productQuantity: customisedProduct.quantity,
        subLineItems: customisedProduct.subLineItems,
        milkOption: customisedProduct.attributes?.milkType,
        cupSizeOption: customisedProduct.attributes?.cupSize,
        caffeineOption:
          customisedProduct.attributes?.decaf || caffeineOptions.CAFFEINATED
      }
    })

const mapNonCustomisedProducts = products =>
  products
    .filter(
      product => !product.subLineItems || product.subLineItems.length === 0
    )
    .map(({ sku, quantity }) => ({ sku, quantity }))

const getProductQuantities = (lineItems = []) =>
  lineItems.reduce((totalCount, item) => {
    const currentCount = totalCount[item.productId] || 0
    totalCount[item.productId] = currentCount + item.quantity
    return totalCount
  }, {})

const addProductQuantities = bag => {
  if (bag.lineItems) {
    bag.productQuantities = getProductQuantities(bag.lineItems)
  }
  return bag
}

const fireUpdateQuantityEvent = (
  product,
  ctaType,
  quantity,
  previousQuantity
) => {
  if (quantity > previousQuantity) {
    fireEvent(ADD_TO_BAG, {
      products: [
        {
          ...product,
          quantity: quantity - previousQuantity
        }
      ],
      ctaType
    })
  } else if (quantity < previousQuantity) {
    fireEvent(REMOVE_FROM_BAG, {
      products: [
        {
          ...product,
          quantity: previousQuantity - quantity
        }
      ],
      ctaType
    })
  }
}

const emptyBag = {
  lineItems: [],
  totalPrice: { localisedPrice: '' },
  collectionDetails: {},
  customerDetails: {}
}

export const isCrossSellProduct = product => {
  return !!(product.recommendationId && product.recommendationPosition)
}

const useBag = (locale, initialValue, bagIdHeader) => {
  const [currentBagId, setCurrentBagIdCookie, deleteCurrentBagIdCookie] =
    useCookie(bagIdHeader)
  const { isAuthenticated, isLoading: loadingAuthState } =
    useAppSelector(accountSelector)
  const dispatch = useAppDispatch()
  const env = useAppSelector(envSelector)
  const [bag, setBag] = useState(initialValue || emptyBag)
  const [loading, setLoading] = useState(!initialValue || loadingAuthState)
  const [addItemState, setAddItemState] = useState(null)
  const [hasExistingBag, setHasExistingBag] = useState(false)

  const marketId = useMemo(() => {
    const market = getMarketDetailsFromLocale(locale)

    return market?.id
  }, [locale])

  const useSecureCookies = !process.env.INSECURE_COOKIES
  const setCurrentBagId = bagId =>
    setCurrentBagIdCookie(bagId, { secure: useSecureCookies })

  const updateBagState = bag => {
    setBag(bag)
    setLoading(false)
    return bag
  }

  const complete = pipe(addProductQuantities, updateBagState)

  useEffect(() => {
    setHasExistingBag(currentBagId !== null)
  }, [currentBagId])

  const createBag = async (fulfillingChannelKey, orderType, outpostId) => {
    setLoading(true)
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const createdBag = await bagApi.createBag(locale, {
      authToken,
      fulfillingChannelKey,
      orderType,
      ...(outpostId && { outpostId })
    })
    setCurrentBagId(createdBag.bagId)
    complete(createdBag)
    dispatch(bagStateSlice.actions.loadBag(createdBag))
  }

  const createSubscriptionBag = async (subscriptionSku, shippingAddress) => {
    setLoading(true)
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const createdBag = await bagApi.createSubscriptionBag(
      locale,
      authToken,
      subscriptionSku,
      shippingAddress
    )
    setCurrentBagId(createdBag.bagId)
    complete(createdBag)
  }

  const addItemToBag = async (product, ctaType) => {
    setLoading(true)
    const { sku, recommendationId, recommendationPosition } = product
    setAddItemState({ sku, isAdding: true })
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const platformProduct = { sku }
    if (ctaType === actionField.crossSell) {
      platformProduct.recommendationId = recommendationId
      platformProduct.recommendationPosition = recommendationPosition
    }

    const updatedBag = await bagApi.addItemToBag(
      currentBagId,
      locale,
      platformProduct,
      authToken
    )
    setAddItemState({ sku, isAdding: false })
    setTimeout(() => setAddItemState(null), 2000)
    fireEvent(ADD_TO_BAG, { products: [product], ctaType })
    complete(updatedBag)
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
  }

  const addItems = async ({ payload, ctaType, variantSkus = [] }) => {
    const productsAdded = [
      ...(payload.customisedItems?.map(p => p.productSku) || []),
      ...(payload.nonCustomisedItems?.map(p => p.productSku) || [])
    ]
    setAddItemState({ sku: productsAdded, isAdding: true })
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const updatedBag = await bagApi.addItemsToBag(
      currentBagId,
      locale,
      payload,
      authToken
    )
    setAddItemState({ sku: productsAdded, isAdding: false })
    setTimeout(() => setAddItemState(null), 2000)

    // in case of customized product we will receive variantSkus
    // and updatedBag returns variant sku and not the master sku
    const variantsToUse = variantSkus.length ? variantSkus : productsAdded
    const recentlyAddedItems =
      updatedBag.lineItems?.filter(item => variantsToUse.includes(item.sku)) ||
      []

    const products = recentlyAddedItems.map(recentlyAddedItem => ({
      id: recentlyAddedItem?.id,
      sku: recentlyAddedItem?.sku,
      name: recentlyAddedItem?.name,
      price: recentlyAddedItem?.price,
      quantity: recentlyAddedItem?.quantity
    }))
    fireEvent(ADD_TO_BAG, { products, ctaType })
    complete(updatedBag)
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
  }

  const addItemsToBag = async (products, ctaType) => {
    setLoading(true)
    const customisedItems = mapCustomisedProducts(products)
    const nonCustomisedItems = mapNonCustomisedProducts(products)
    const payload = { customisedItems, nonCustomisedItems }
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const updatedBag = await bagApi.addItemsToBag(
      currentBagId,
      locale,
      payload,
      authToken
    )
    fireEvent(ADD_TO_BAG, { products, ctaType })
    complete(updatedBag)
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
  }

  const addCustomisedItemToBag = async payload => {
    setLoading(true)
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const updatedBag = await bagApi.addCustomisedItemToBag(
      currentBagId,
      locale,
      payload,
      authToken
    )
    const recentlyAddedItem = updatedBag.lineItems.find(
      item => item.sku === payload.productSku
    )
    const product = {
      id: recentlyAddedItem?.id,
      name: recentlyAddedItem?.name,
      price: recentlyAddedItem?.price,
      quantity: recentlyAddedItem?.quantity
    }
    fireEvent(ADD_TO_BAG, { products: [product] })
    complete(updatedBag)
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
  }

  const updateCustomerDetails = async customerDetails => {
    setLoading(true)
    const updatedBag = await bagApi.setGuestCustomerDetails(
      currentBagId,
      locale,
      customerDetails
    )
    complete(updatedBag)
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
  }

  const checkZeroValueBagOrder = async customerDetails => {
    setLoading(true)
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const createBagOrder = await bagApi.createZeroValueOrder(
      currentBagId,
      locale,
      customerDetails,
      authToken
    )
    return createBagOrder
  }

  const updateSubscriptionCustomerDetails = async customerDetails => {
    const updatedBag = await bagApi.setSubscriptionGuestCustomerDetails(
      currentBagId,
      locale,
      customerDetails
    )
    setBag(updatedBag)
  }

  const updateCutleryOption = async isCutleryAdded => {
    setLoading(true)
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const updatedBag = await bagApi.updateCutleryOption(
      currentBagId,
      locale,
      isCutleryAdded,
      authToken
    )
    updateBagState(updatedBag)
  }

  const updateFulfillmentDetails = async fulfillmentDetails => {
    const { fulfillingChannelKey, orderType, outpostId } = fulfillmentDetails
    setLoading(true)
    if (!hasExistingBag) {
      await createBag(fulfillingChannelKey, orderType, outpostId)
    } else {
      const authToken = isAuthenticated ? await getTokenSilently() : null
      const updatedBag = await bagApi.setFulfillingShop(
        currentBagId,
        locale,
        fulfillingChannelKey,
        orderType,
        outpostId,
        authToken
      )
      complete(updatedBag)
      const orderDetails = await getOrderDetails(
        updatedBag.orderDetails,
        locale
      )
      dispatch(orderDetailsStateSlice.actions.loadOrderDetails(orderDetails))
      dispatch(bagStateSlice.actions.loadBag(updatedBag))
    }
  }

  const updatePickUpTimeSlot = async slot => {
    setLoading(true)
    const [startTime, endTime] = slot.replace(/ /g, '').split('-')
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const updatedBag = await bagApi.setTimeSlot(
      currentBagId,
      locale,
      startTime,
      endTime,
      authToken
    )
    complete(updatedBag)
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
    const orderDetails = await getOrderDetails(updatedBag.orderDetails, locale)
    dispatch(orderDetailsStateSlice.actions.loadOrderDetails(orderDetails))
  }

  const updateVoucherCodeStatus = async voucherCodeStatus => {
    setLoading(true)
    const updatedBag = await bagApi.setVoucherCodeStatus(
      currentBagId,
      locale,
      voucherCodeStatus
    )
    complete(updatedBag)
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
  }

  const updateOutpostTimeSlot = async ({ startTime, endTime }) => {
    setLoading(true)
    const updatedBag = await bagApi.setTimeSlot(
      currentBagId,
      locale,
      startTime,
      endTime
    )
    complete(updatedBag)
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
  }

  const updateItemQuantity = async (product, quantity, previousQuantity) => {
    setLoading(true)
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const updatedBag = await bagApi.setItemQuantity(
      currentBagId,
      locale,
      {
        itemId: product.id,
        quantity
      },
      authToken
    )

    let ctaType
    if (isCrossSellProduct(product)) {
      ctaType = actionField.crossSell
    }
    fireUpdateQuantityEvent(product, ctaType, quantity, previousQuantity)
    complete(updatedBag)
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
  }

  const processTax = async () => {
    setLoading(true)
    const authToken = isAuthenticated ? await getTokenSilently() : null
    const updatedBag = await processTaxOnBag(
      currentBagId,
      locale,
      marketId,
      authToken
    )
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
    return complete(updatedBag)
  }

  const refreshBag = async ({ showLoading = true } = {}) => {
    if (showLoading) {
      setLoading(true)
    }

    if (currentBagId) {
      const authToken = isAuthenticated ? await getTokenSilently() : null
      let updatedBag = await bagApi.refreshBag(currentBagId, locale, authToken)
      dispatch(bagStateSlice.actions.loadBag(updatedBag))
      return complete(updatedBag)
    }

    setLoading(false)
  }

  const removeItems = async (items = []) => {
    setLoading(true)
    const updatedBag = await bagApi.removeItems(currentBagId, locale, items)
    fireEvent(REMOVE_FROM_BAG, { products: items })
    complete(updatedBag)
    dispatch(bagStateSlice.actions.loadBag(updatedBag))
  }

  const clearBag = async () => {
    complete(emptyBag)
    dispatch(bagStateSlice.actions.loadBag(emptyBag))
    deleteCurrentBagIdCookie()
  }

  useAsync(async () => {
    /**
     * Due to a race condition between the bag provider and redux state, and given now that we
     * only get envVars once, we need to wait for the envVars to be available before we
     * try to get the bag.
     */
    if (!Object.keys(env).length) {
      return
    }

    if (!currentBagId || currentBagId === initialValue?.id) {
      setLoading(false)
    } else {
      dispatch(bagStateSlice.actions.bagLoading())
      const bag = await bagApi.getBagById(locale, currentBagId)
      if (bagIdHeader !== subscriptionBagIdHeader) {
        dispatch(bagStateSlice.actions.loadBag(bag))
        const orderDetails = await getOrderDetails(bag.orderDetails, locale)
        dispatch(orderDetailsStateSlice.actions.loadOrderDetails(orderDetails))
      }
      complete(bag)
    }
  }, [currentBagId, env])

  return {
    addCustomisedItemToBag,
    addItemState,
    addItemToBag,
    addItems,
    addItemsToBag,
    bag,
    clearBag,
    createBag,
    createSubscriptionBag,
    hasExistingBag,
    isLoading: loading,
    processTax,
    refreshBag,
    removeItems,
    updateCustomerDetails,
    checkZeroValueBagOrder,
    updateCutleryOption,
    updateFulfillmentDetails,
    updateItemQuantity,
    updateOutpostTimeSlot,
    updatePickUpTimeSlot,
    updateSubscriptionCustomerDetails,
    updateVoucherCodeStatus
  }
}

export default useBag
