import { httpClient } from '#lib/http-client'
import { encodeURIComponent } from 'encodeuri-safe'
import { getLogger } from '#api/logger-with-context'
import { localeHeader, orderAheadBagIdHeader } from '#constants'
import platformClient, {
  targetServiceEnum
} from '#src/common/lib/platform-client'
import { getTokenSilently } from '#src/common/lib/auth0/auth0-functions'

const logRef = 'Subscription API - Platform-API::'

export const customerExists = async email => {
  const response = await httpClient(
    `/api/subscriptions/customerExists/${encodeURIComponent(email)}`
  )
  if (response.status === 200) {
    const data = await response.text()
    return JSON.parse(data).customerIds.length > 0
  } else {
    return false
  }
}

export const loginUserAccountVerifySubscription = async (locale, bagId) => {
  const log = getLogger()
  log.info(`${logRef} start loginUserAccountVerifySubscription`)
  const url = '/subscription/account-verify'
  const accessToken = await getTokenSilently()

  const verifyAccountSubscription = await platformClient(url, {
    targetService: targetServiceEnum.CHECKOUT_API_SERVICE,
    method: 'POST',
    headers: {
      [localeHeader]: locale,
      [orderAheadBagIdHeader]: bagId,
      Authorization: `Bearer ${accessToken}`
    }
  })
  log.info(
    {
      verifyAccountSubscription
    },
    `${logRef} end loginUserAccountVerifySubscription`
  )
  return verifyAccountSubscription
}
