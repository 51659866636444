import { httpClient } from './http-client'
import { anonymousIdHeader } from '#constants'

export const clientSideGetLaunchDarklyFlag = async (name, user, country) => {
  const response = await httpClient('/api/flags', {
    method: 'POST',
    headers: { [anonymousIdHeader]: user },
    body: JSON.stringify({
      name,
      country
    })
  })
  if (!response.ok) {
    const error = new Error(response.statusText)
    throw error
  }
  const result = await response.json()
  return result
}
