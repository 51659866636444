import Link from 'next/link'

const LinkAsset = (node, children, locale) => {
  const href =
    node.data.target.fields.file[locale]?.url ||
    node.data.target.fields.file.url

  return (
    <Link href={href}>
      <a rel='noopener noreferrer' target='_blank'>
        {children}
      </a>
    </Link>
  )
}

export default LinkAsset
