import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { CustomPages } from '../constants/pages'
import CommonPageTemplate from './page-templates/common-page-template'

export const PageTemplateSwitcher = ({ Component, pageProps }) => {
  const [isStoreFinderBundleLoaded, setIsStoreFinderBundleLoaded] =
    useState(false)
  const [pageReady, setPageReady] = useState(false)

  const { query, isReady } = useRouter()
  const hideLayout = query.hideLayout === 'true'

  const customPages = [
    CustomPages.CP_CALCULATOR,
    CustomPages.US_CP_CALCULATOR,
    CustomPages.ORDER
  ]

  useEffect(() => {
    if (isReady) {
      setPageReady(true)
    }
  }, [isReady])
  const isCustomPage =
    pageProps.customPage || customPages.includes(pageProps?.page?.slug)

  if (pageReady) {
    if (isCustomPage || hideLayout) {
      return <Component {...pageProps} />
    }
    return (
      <CommonPageTemplate pageProps={pageProps}>
        <Component
          {...pageProps}
          isStoreFinderBundleLoaded={isStoreFinderBundleLoaded}
          setIsStoreFinderBundleLoaded={setIsStoreFinderBundleLoaded}
        />
      </CommonPageTemplate>
    )
  }

  // Return an empty fragment while the page is not ready
  return <></>
}
