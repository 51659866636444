import {
  frCoffeeSubLandingPageRegEx,
  frCoffeeSubSignupRegEx,
  frCoffeeSubCheckoutRegEx,
  frCoffeeSubThankYouRegEx,
  ukClickCollectCheckoutRegEx,
  ukCoffeeSubCheckoutRegEx,
  usCoffeeSubLandingPageRegEx,
  usCoffeeSubSignupRegEx,
  usCoffeeSubCheckoutRegEx,
  usCoffeeSubThankYouRegEx
} from './utils/regex-paths'
import {
  SCREEN_TYPE_LANDING,
  SCREEN_TYPE_PAYMENT,
  SCREEN_TYPE_SIGNUP,
  SCREEN_TYPE_THANK_YOU,
  SECTION_TYPE_CLICK_COLLECT,
  SECTION_TYPE_COFFEE_SUBSCRIPTION
} from '../../constants'

export const pathnameMaps = [
  {
    pathname: frCoffeeSubLandingPageRegEx,
    screenType: SCREEN_TYPE_LANDING,
    sectionType: SECTION_TYPE_COFFEE_SUBSCRIPTION
  },
  {
    pathname: frCoffeeSubSignupRegEx,
    screenType: SCREEN_TYPE_SIGNUP,
    sectionType: SECTION_TYPE_COFFEE_SUBSCRIPTION
  },
  {
    pathname: frCoffeeSubCheckoutRegEx,
    screenType: SCREEN_TYPE_PAYMENT,
    sectionType: SECTION_TYPE_COFFEE_SUBSCRIPTION
  },
  {
    pathname: frCoffeeSubThankYouRegEx,
    screenType: SCREEN_TYPE_THANK_YOU,
    sectionType: SECTION_TYPE_COFFEE_SUBSCRIPTION
  },
  {
    pathname: ukClickCollectCheckoutRegEx,
    screenType: SCREEN_TYPE_PAYMENT,
    sectionType: SECTION_TYPE_CLICK_COLLECT
  },
  {
    pathname: ukCoffeeSubCheckoutRegEx,
    screenType: SCREEN_TYPE_PAYMENT,
    sectionType: SECTION_TYPE_COFFEE_SUBSCRIPTION
  },
  {
    pathname: usCoffeeSubLandingPageRegEx,
    screenType: SCREEN_TYPE_LANDING,
    sectionType: SECTION_TYPE_COFFEE_SUBSCRIPTION
  },
  {
    pathname: usCoffeeSubSignupRegEx,
    screenType: SCREEN_TYPE_SIGNUP,
    sectionType: SECTION_TYPE_COFFEE_SUBSCRIPTION
  },
  {
    pathname: usCoffeeSubCheckoutRegEx,
    screenType: SCREEN_TYPE_PAYMENT,
    sectionType: SECTION_TYPE_COFFEE_SUBSCRIPTION
  },
  {
    pathname: usCoffeeSubThankYouRegEx,
    screenType: SCREEN_TYPE_THANK_YOU,
    sectionType: SECTION_TYPE_COFFEE_SUBSCRIPTION
  }
]

export const getPathnameMap = pathname =>
  pathnameMaps.find(item => item.pathname.test(pathname))

export const getScreenType = pathname => getPathnameMap(pathname)?.screenType

export const getSectionType = pathname => getPathnameMap(pathname)?.sectionType

export const transformScreenType = (currentScreenType, pathname) =>
  currentScreenType === ''
    ? getScreenType(pathname) || currentScreenType
    : currentScreenType

export const transformSectionType = (currentSectionType, pathname) =>
  currentSectionType === ''
    ? getSectionType(pathname) || currentSectionType
    : currentSectionType
