import React from 'react'
import { LabelWrapper, LabelText, IconWrapper } from './styles'
import { Star } from '@pretamanger/component-library'
import Translation from '#src/common/components/translation'

export const LoyaltyLabel = () => {
  return (
    <LabelWrapper data-testid='perk-discount-label'>
      <IconWrapper>
        <Star colour='white' />
      </IconWrapper>
      <LabelText>
        <Translation id='loyalty.discount.label' />
      </LabelText>
    </LabelWrapper>
  )
}
