import { SELECT_ITEM } from '../../../../topics'

/* eslint-disable camelcase */
export const selectItemAdapter = ({
  product,
  name,
  selectedCategory,
  selectedSubCategory
}) => {
  const category_name = selectedCategory.name
  const item_list_name =
    selectedSubCategory?.name?.toLowerCase() === 'all'
      ? category_name
      : selectedSubCategory.name

  return {
    event: SELECT_ITEM,
    ecommerce: {
      item_list_id: selectedSubCategory?.id || '',
      item_list_name,
      items: [
        {
          item_id: product.sku,
          item_name: product.name,
          affiliation: 'Online Store',
          item_brand: 'Pret A Manger',
          item_category: 'Click and Collect',
          item_category3: name
        }
      ]
    }
  }
}
