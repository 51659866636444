import PropTypes from 'prop-types'
import { market } from '@proptypes'
import { useRouter } from 'next/router'
import { StyledDropdown } from './styles'
import { translate } from '../../../components/translation'
import { getMarketDetailsFromLocale } from '../../../lib/get-market'
import { mapLocale } from '../../../lib/locale'

const CountrySwitch = ({ markets }) => {
  const { locale } = useRouter()
  const mappedLocale = mapLocale(locale, true)
  const currentMarket = getMarketDetailsFromLocale(locale)

  const onSelect = link => {
    window.location.assign(link)
  }

  const options = markets
    .map(m => ({
      id: m.id,
      value: m.link,
      label: translate(`market.${m.id}`, mappedLocale)?.value
    }))
    .filter(o => o.label)

  return (
    <StyledDropdown
      id='markets'
      name='markets'
      current={currentMarket.link}
      label={translate('footer.country.label', mappedLocale)?.value}
      onSelect={onSelect}
      options={options}
    />
  )
}

CountrySwitch.propTypes = {
  markets: PropTypes.arrayOf(market)
}

CountrySwitch.defaultProps = {
  markets: []
}

export default CountrySwitch
