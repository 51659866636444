import styled from '@emotion/styled'
import { css } from '@emotion/react'
import tw from 'twin.macro'

const variants = {
  red: css`
    ${tw`bg-pretRed-700`}
  `,
  grey: css`
    ${tw`bg-grey-700`}
  `,
  yellow: css`
    ${tw`bg-warningYellowTint`}
  `,
  lightYellow: css`
    ${tw`bg-yellow-300 items-center`}
  `,
  gold: css`
    ${tw`bg-loyaltyGold`}
  `
}

const colorVariants = {
  red: css`
    ${tw`text-pretRed-700`}
  `,
  grey: css`
    ${tw`text-grey-700`}
  `,
  white: css`
    ${tw`text-white`}
  `,
  yellow: css`
    ${tw`text-warningYellowText`}
  `
}

const paddingVariants = {
  informationIcon: css`
    ${tw`py-1 pr-1`}
  `,
  loyaltyIcon: css`
    ${tw`py-0 pl-2 pr-3`}
  `
}

export const NotificationBannerContainer = styled.div`
  ${tw`rounded-b px-2 py-4`}

  ${({ variant }) => variants[variant]}
`

export const IconContainer = styled.div`
  ${({ iconVariant }) => paddingVariants[iconVariant]}
`

export const BannerText = styled.p`
  ${({ colorVariant }) => colorVariants[colorVariant]}
`

export const InnerWrapper = styled.div`
  display: inherit;
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;
`
