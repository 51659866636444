import PropTypes from 'prop-types'
import { navigationItem } from '@proptypes'
import Submenu from './submenu'
import { useAppSelector } from '#src/state/redux-hooks'
import { profileSelector } from '#src/state/profile-state-slice'
import { useRouter } from 'next/router'
import { employee } from '#src/common/constants/userRoles'
import LocaleLink from '#src/common/components/locale-link'
import { directingUserToLogin } from '#src/common/lib/auth0/auth0-functions'
import { myAccountPath, myAccountTitle } from '#src/common/constants'

const NavigationItem = ({
  item: { id, link, subMenu, title },
  onNavigate,
  showEmployeePrivacyPolicy,
  isAuthenticated
}) => {
  const { userRole } = useAppSelector(profileSelector)
  const { locale } = useRouter()
  if (
    showEmployeePrivacyPolicy &&
    userRole &&
    userRole[0]?.name === employee &&
    link?.url === '/privacy-policy' &&
    locale === 'en-GB'
  ) {
    link.url = '/employee-privacy-policy'
  }
  if (!link && !subMenu) {
    return null
  }
  const target = link?.openInANewWindow ? { target: '_blank' } : {}
  const isToReloadPage =
    link?.url === '/cookies' || link?.url === '/cookie-policy' ? true : false
  const onClick = () => {
    if (
      !isAuthenticated &&
      ((link && link.url === `/${myAccountPath}`) || title === myAccountTitle)
    )
      directingUserToLogin()
    else onNavigate()
  }
  return (
    <li className={link?.active ? 'active-link' : undefined}>
      {link ? (
        link.openInANewWindow || !link.path ? (
          <a
            href={link.url}
            {...target}
            rel='noopener noreferrer'
            onClick={onNavigate}
          >
            {title}
          </a>
        ) : (
          <LocaleLink
            title={title}
            href={link.type === 'page' ? link.path : link.url}
            as={link.url}
            isToReloadPage={isToReloadPage}
          >
            <a onClick={onClick}>{title}</a>
          </LocaleLink>
        )
      ) : (
        <Submenu id={id} title={title} items={subMenu} />
      )}
    </li>
  )
}

NavigationItem.propTypes = {
  item: navigationItem.isRequired,
  onNavigate: PropTypes.func,
  isAuthenticated: PropTypes.bool
}

NavigationItem.defaultProps = {
  onNavigate: () => {},
  isAuthenticated: false
}

export default NavigationItem
