import { PRET_SESSION_ID_KEY } from '#src/common/lib/session-id'
import { v4 as uuidv4 } from 'uuid'
import { setCookie, parseCookies, destroyCookie } from 'nookies'
import cookies from 'js-cookie'
import { pathOr } from 'ramda'
import { anonymousIdHeader } from '#src/common/constants'

export const setPretSessionId = () => {
  const sessionId = pathOr<string>(
    uuidv4(),
    [PRET_SESSION_ID_KEY],
    parseCookies()
  )
  const expires = new Date()
  expires.setTime(expires.getTime() + 30 * 60 * 1000)

  const secure = !process.env.INSECURE_COOKIES
  setCookie(null, PRET_SESSION_ID_KEY, sessionId, {
    sameSite: true,
    expires,
    secure,
    path: '/'
  })
}

export const destroyPretSessionId = () => {
  destroyCookie(null, PRET_SESSION_ID_KEY, { path: '/' })
}

export const setPretAnonymousId = () => {
  const currentAnonymousId = uuidv4()
  const secure = !process.env.INSECURE_COOKIES
  cookies.set(anonymousIdHeader, currentAnonymousId, { secure, expires: 365 })
}

export const destroyPretAnonymousId = () => {
  cookies.remove(anonymousIdHeader)
}
