import { useState } from 'react'
import ValidationResult from './validation-result'

const useForm = fieldsDefinition => {
  const [fields, _setFields] = useState(
    fieldsDefinition.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: {
          ...curr,
          dirty: false,
          isValid: true,
          value: curr.value || ''
        }
      }
    }, {})
  )

  const [errorList, setErrorList] = useState([])
  const [isValid, setValid] = useState(true)
  const [isDirty, setDirty] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [formHasBeenValidated, setFormHasBeenValidated] = useState(false)

  const setField = async (id, field) => {
    return set({ ...fields, [id]: { ...fields[id], ...field } })
  }

  const setFields = newFields => {
    const updatedFields = {
      ...fields,
      ...Object.keys(newFields).reduce((acc, curr) => {
        return { ...acc, [curr]: { ...fields[curr], ...newFields[curr] } }
      }, {})
    }
    return set(updatedFields)
  }

  const set = newFields => {
    _setFields(newFields)
    const isValid = Object.keys(newFields).every(id => newFields[id].isValid)
    setErrorList(
      Object.values(newFields)
        .filter(field => !field.isValid)
        .map(field => field.id)
    )
    setValid(isValid)
    setDirty(Object.keys(newFields).every(id => newFields[id].dirty))
    return isValid
  }

  const validateForm = () => {
    const validationResult = Object.values(fields).reduce(
      async (acc, { id, value }) => {
        const [_acc, result] = await Promise.all([
          acc,
          validateField(id, value, true)
        ])
        return { ..._acc, [id]: { ...result.summary(), dirty: true } }
      },
      Promise.resolve({})
    )
    setFormHasBeenValidated(true)
    return validationResult
  }

  const validateField = (id, value, isFormValidating) => {
    return new ValidationResult().validate(
      fields[id],
      value,
      fields,
      isFormValidating
    )
  }

  const validateFieldWithDependent = async (id, value, isFormValidating) => {
    setField(id, { value })
    const results = [
      {
        id,
        value,
        result: await new ValidationResult().validate(
          fields[id],
          value,
          fields,
          isFormValidating
        )
      }
    ]

    if (fields[id].dependentField) {
      results.push(await validateDependent(id, value, isFormValidating))
    }

    setFields(
      results.reduce((acc, curr) => {
        acc[curr.id] = { value: curr.value, ...curr.result.summary() }
        return acc
      }, {})
    )
  }

  const validateDependent = async (id, value, isFormValidating) => {
    const depId = fields[id].dependentField
    const depValue = fields[depId].value
    const newFields = { ...fields }
    newFields[id].value = value
    const result = await new ValidationResult().validate(
      fields[depId],
      depValue,
      newFields,
      isFormValidating
    )
    return { id: depId, value: depValue, result }
  }

  return {
    fields,
    isValid,
    isDirty,
    errorList,
    formHasBeenValidated,
    setField,
    setFields,
    isSubmitting,
    setSubmitting,
    validateField,
    validateFieldWithDependent,
    validateForm
  }
}

export default useForm
