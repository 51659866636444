import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { useBag } from '@hooks'
import { orderAheadBagIdHeader } from '#constants'
import { bagProps } from '@proptypes'

const OrderAheadBagContext = createContext()

const OrderAheadBagProvider = ({ children, initialValue }) => {
  const { locale } = useRouter()
  const bag = useBag(locale, initialValue, orderAheadBagIdHeader)

  return (
    <OrderAheadBagContext.Provider value={bag}>
      {children}
    </OrderAheadBagContext.Provider>
  )
}

OrderAheadBagProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialValue: bagProps
}

export const useOrderAheadBagContext = () => {
  const utils = useContext(OrderAheadBagContext)
  return utils
}

const OrderAheadBagConsumer = OrderAheadBagContext.Consumer

export default OrderAheadBagProvider
export { OrderAheadBagConsumer, OrderAheadBagContext }
