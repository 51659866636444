import { useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { lineItemProps } from '@proptypes'
import { Button } from '@pretamanger/component-library'
import RedemptionCodeDiscount from '../../redemption-code/discount-price'
import { BagItemQuantity } from '../bag-item-quantity'
import {
  BagItemContainer,
  BagItemContainerRow,
  BagItemTitle,
  BagItemTitleContainer,
  BagItemQuantityContainer,
  OriginalBagItemPrice,
  TotalBagItemPrice,
  RedemptionCodeDiscountContainer,
  BagItemSubTitle,
  UnavailableBagItem
} from './styles'
import Translation from '#src/common/components/translation'
import {
  getAdditionalAttributes,
  renderTotalPriceLabel,
  renderAdditionalAttributesString,
  isItemAvailable
} from '../../bag-utils'

const BagItem = ({ item, onQuantityChange, refreshBag }) => {
  const { locale } = useRouter()
  const [quantity, setQuantity] = useState(item.quantity)
  const {
    id,
    name,
    totalPrice,
    originalPrice,
    totalDiscount,
    discountType = 'YourBaristaFree'
  } = item

  const onSelect = useCallback(
    value => {
      const previousQuantity = quantity
      setQuantity(value)
      onQuantityChange(item, parseInt(value, 10), previousQuantity)
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [id]
  )

  const additionalAttributes = getAdditionalAttributes(item, locale)
  const itemIsAvailable = isItemAvailable(item)

  return (
    <BagItemContainer data-testid='bag-item-container'>
      <BagItemContainerRow>
        {itemIsAvailable ? (
          <BagItemQuantityContainer>
            <BagItemQuantity
              id={id}
              handleChange={onSelect}
              quantity={quantity}
            />
          </BagItemQuantityContainer>
        ) : (
          <UnavailableBagItem>
            <Button styleType='quaternary' disabled compact>
              <Translation id='bag.item.unavailable' />
            </Button>
          </UnavailableBagItem>
        )}
        <BagItemTitleContainer>
          <BagItemTitle id={`bag-title-${id}`}>{name}</BagItemTitle>
          {additionalAttributes.length > 0 && (
            <BagItemSubTitle>
              {renderAdditionalAttributesString(additionalAttributes)}
            </BagItemSubTitle>
          )}
          {totalDiscount && (
            <RedemptionCodeDiscountContainer>
              <RedemptionCodeDiscount
                discountType={discountType}
                refreshBag={refreshBag}
                allowRemoveDiscount
              />
            </RedemptionCodeDiscountContainer>
          )}
        </BagItemTitleContainer>
        {originalPrice && (
          <OriginalBagItemPrice>
            {originalPrice.localisedPrice}
          </OriginalBagItemPrice>
        )}
        <TotalBagItemPrice
          discount={totalDiscount}
          data-testid='total-bag-discount'
        >
          {renderTotalPriceLabel(totalPrice, locale)}
        </TotalBagItemPrice>
      </BagItemContainerRow>
    </BagItemContainer>
  )
}

BagItem.propTypes = {
  item: lineItemProps.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  refreshBag: PropTypes.func.isRequired
}

export default BagItem
