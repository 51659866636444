import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Heading as H, Quote } from '@pretamanger/component-library'

export const Wrapper = styled.div`
  ul,
  ol {
    ${tw`ml-8 tracking-normal`}
  }

  ul {
    ${tw`list-disc`}
  }

  ol {
    ${tw`list-decimal`}
  }

  p {
    ${tw`my-4 tracking-normal`}
  }

  p:first-of-type {
    ${tw`mt-0`}
  }

  p:last-child {
    ${tw`mb-0`}
  }
`

export const Heading = styled(H)`
  ${tw`mb-4`}
`

export const BlockQuote = styled('blockquote')`
  ${tw`mb-4 border-pretRed-700 border-solid border-l-2 pt-1 pl-4 flex`}
  * {
    ${tw`font-heading text-lg leading-lg tracking-lg md:text-xl md:leading-xl md:tracking-xl`}
  }
`

export const QuoteIcon = styled(Quote)`
  ${tw`flex-shrink-0 mr-2 mt-1`}
`
