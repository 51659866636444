import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Chevron, Theme } from '@pretamanger/component-library'
import tw from 'twin.macro'
import NavigationGroup from './group'

const groupStyles = css`
  ${tw`-mx-2`}
  > li {
    ${tw`lg:mr-1 xl:mr-4`}
    &.active-link {
      > a {
        ${tw`text-pretRed-700`}
      }
    }
  }
`

export const FirstLevelGroup = styled(NavigationGroup)`
  ${groupStyles}
  ${props =>
    props.isPrimary &&
    css`
      > li {
        ${tw`text-center whitespace-nowrap`}
        > a {
          ${tw`text-2xl sm:text-3xl lg:text-base py-1`}
        }
      }
    `}
  ${tw`flex flex-col lg:flex-row`}
  a {
    ${tw`border px-2 border-transparent hover:text-pretRed-700 focus:text-pretRed-700 text-grey-700 no-underline`}
  }
`
const hidden = css`
  ${tw`hidden lg:block`}
`

export const SecondLevelGroup = styled(NavigationGroup)`
  ${groupStyles}
  > li {
    ${tw`pb-2`}
  }

  a {
    ${tw`text-sm leading-sm tracking-sm text-grey-500`}
  }

  ${({ open }) => (open ? '' : hidden)}
`

export const ExpandableItem = styled('div')`
  ${tw`border-b border-grey-200 lg:border-none`}
`

export const columnHeading = props => css`
  ${props.theme === Theme.HotPink
    ? tw`text-grey-700 font-semibold`
    : tw`text-base font-normal`};
`

export const ExpandableItemTitle = styled('div')`
  ${tw`flex justify-between items-center py-3`}
  ${columnHeading}

  > svg path {
    ${tw`stroke-current`}
  }
`

export const ToggleIcon = styled(Chevron)`
  ${tw`mx-2 lg:hidden`}
`

export const BackButtonContainer = styled(Button)`
  ${tw`text-lg mb-6 outline-none focus:shadow-outline`}

  &:focus {
    ${tw`outline-none`}
  }
`

export const AuthCTAContainer = styled('div')`
  ${tw`mt-8 sm:mt-12`}
  > button {
    ${tw`py-1 flex flex-col items-center`}
    > span {
      ${tw`pt-2 inline-block m-0 text-2xl sm:text-3xl`}
    }
    > svg {
      ${tw`w-8 h-8 sm:h-10 sm:w-10`}
    }
  }
`

export const LanguageSwitchContainer = styled('div')`
  ${tw`w-24 sm:w-32 mt-8 sm:mt-12 text-2xl sm:text-3xl`}
`

export const Container = styled('div')`
  ${tw`flex flex-col items-center lg:hidden space-y-16`}
`
