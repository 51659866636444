import { clickAndCollectEvent } from './click-and-collect-event'
import { clickAndCollectHomePageEvent } from './click-and-collect-home-page-event'
import { clickEventAddToBagGifting } from './click-event-add-to-bag-gifting'
import { coffeeSubsUKGetStartedEvent } from './coffee-subs-uk-get-started-event'
import { coffeeSubsUSGetStartedEvent } from './coffee-subs-us-get-started-event'
import { deliverooEvent } from './deliveroo-event'
import { findPretHomePageEvent } from './find-pret-home-page-event'
import { justEatEvent } from './just-eat-event'
import { manageCoffeeSubscriptionEvent } from './manage-coffee-subscription-event'
import { orderDeliveryHomePageEvent } from './order-delivery-home-page-events'
import { pretFoundationDonateEvent } from './pret-foundation-donate-event'
import { risingStarsDonateEvent } from './rising-stars-donate-event'
import { uberEatsEvent } from './uber-eats-event'

const clickEvents = {
  clickAndCollectEvent,
  clickAndCollectHomePageEvent,
  clickEventAddToBagGifting,
  coffeeSubsUKGetStartedEvent,
  coffeeSubsUSGetStartedEvent,
  deliverooEvent,
  findPretHomePageEvent,
  justEatEvent,
  manageCoffeeSubscriptionEvent,
  orderDeliveryHomePageEvent,
  pretFoundationDonateEvent,
  risingStarsDonateEvent,
  uberEatsEvent
}

export default clickEvents
