import PropTypes from 'prop-types'
import PaperCup from './paper-cup'
import {
  Inner,
  InPage,
  CallToAction,
  IconContainer,
  Text,
  Title
} from './styles'

const icons = {
  'paper-cup': PaperCup
}

export const Promotion = ({
  body,
  callToActionLink,
  callToActionText,
  callToActionPlacement,
  defaultTrackingCode,
  icon,
  title,
  trackingCode
}) => {
  const Icon = icons[icon] || null
  const callToActionProps = {
    href: `${callToActionLink}${trackingCode || defaultTrackingCode}`
  }
  const cta = (
    <span className='pr-2'>
      <CallToAction {...callToActionProps}>
        <a>{callToActionText}</a>
      </CallToAction>
    </span>
  )
  return (
    <>
      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}
      <Inner>
        {callToActionPlacement === 'before' && cta}
        {title && <Title>{title}</Title>}
        {body && <Text>{body}</Text>}
        {callToActionPlacement === 'after' && cta}
      </Inner>
    </>
  )
}

Promotion.propTypes = {
  body: PropTypes.string.isRequired,
  callToActionLink: PropTypes.string.isRequired,
  callToActionText: PropTypes.string.isRequired,
  callToActionPlacement: PropTypes.oneOf(['before', 'after']),
  defaultTrackingCode: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  trackingCode: PropTypes.string,
  variant: PropTypes.string.isRequired
}

Promotion.defaultProps = {
  callToActionPlacement: 'after',
  defaultTrackingCode: '',
  icon: null,
  trackingCode: null
}

export const InPagePromotion = ({
  body,
  callToActionLink,
  callToActionText,
  defaultTrackingCode,
  icon,
  title,
  trackingCode,
  variant
}) => {
  const Icon = icons[icon] || null
  const callToActionProps = {
    href: `${callToActionLink}${trackingCode || defaultTrackingCode}`
  }

  return (
    <InPage variant={variant}>
      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}
      <Inner>
        <Title>{title}</Title>
        <Text>{body}</Text>
        <CallToAction {...callToActionProps}>{callToActionText}</CallToAction>
      </Inner>
    </InPage>
  )
}

InPagePromotion.propTypes = {
  body: PropTypes.string.isRequired,
  callToActionLink: PropTypes.string.isRequired,
  callToActionText: PropTypes.string.isRequired,
  defaultTrackingCode: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  trackingCode: PropTypes.string,
  variant: PropTypes.string.isRequired
}

InPagePromotion.defaultProps = {
  defaultTrackingCode: '',
  icon: null,
  trackingCode: null
}
