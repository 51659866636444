import { translate } from '#src/common/components/translation'

const getVoucherErrorReasons = (locale, override = {}) => ({
  eeinvalidvoucher: translate('voucher.errorCode.eeInvalidVoucher', locale)
    ?.value,
  eeexpiredvoucher: translate('voucher.errorCode.eeExpiredVoucherAlt', locale)
    ?.value,
  eeminimumtimenotpassed: translate(
    'voucher.errorCode.eeMinimumTimeNotPassed',
    locale
  )?.value,
  eemaximumlimitreached: translate(
    'voucher.errorCode.eeMaximumLimitReached',
    locale
  )?.value,
  eelockedvoucher: translate('voucher.errorCode.eeLockedVoucher', locale)
    ?.value,
  eeservicedown: translate('voucher.errorCode.eeServiceDown', locale)?.value,
  eevouchercancelled: translate('voucher.errorCode.eeVoucherCancelled', locale)
    ?.value,
  eeerror: translate('voucher.errorCode.eeError', locale)?.value,
  ...override
})

export default getVoucherErrorReasons
