import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Star, Clock, Marker } from '@pretamanger/component-library'

export const OrderSlotBannerWrapper = styled('aside')`
  ${tw`p-2 bg-grey-50`}
`

export const OrderSlotContentContainer = styled('div')`
  ${tw`max-w-screen-md mx-auto flex flex-col md:flex-row items-center justify-center px-1`}
`

export const MobileSummary = styled('div')`
  ${tw`w-full md:hidden`}
`

export const SummaryButton = styled('button')`
  ${tw`w-full py-2 flex justify-between items-center outline-none focus:shadow-outline`}
`

export const OrderSlotContent = styled('div')`
  ${props => (props.orderSlotContentVisibility ? tw`flex` : tw`hidden`)}
  ${tw`w-full md:flex md:w-auto flex-col md:flex-row items-start`}
`

export const OrderType = styled('div')`
  ${tw`hidden md:block mr-3`}
`

export const OrderLocation = styled('div')`
  ${tw`mr-3 md:flex items-center`}
`

export const ShopName = styled('div')`
  ${tw`mt-3 md:mt-0 inline`}
`

export const ShopAddress = styled('div')`
  ${tw`md:hidden text-grey-500 ml-8 text-sm`}
`

export const OrderSlot = styled('div')`
  ${tw`mr-3 mt-3 ml-8 md:mt-0 md:ml-0 text-sm md:text-base`}
`

export const InlineStar = styled(Star)`
  ${tw`hidden md:inline mr-3 align-baseline`}
`

export const InlineMarker = styled(Marker)`
  ${tw`inline md:hidden mr-3 ml-2 align-baseline`}
`

export const InlineClock = styled(Clock)`
  ${tw`inline md:hidden mr-3 align-baseline`}
`
