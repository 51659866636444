import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import cookies from 'js-cookie'
import Script from 'next/script'
import { urlTag, labelCookiesConsent } from './constants'
import { parseJwt } from '#src/common/lib/token'

const scriptId = 'webtrends-script'

export const WebTrends = ({ visible }) => {
  return process.env.NODE_ENV === 'development' || !visible ? (
    <></>
  ) : (
    <WebTrendsScript />
  )
}

const WebTrendsScript = () => {
  const router = useRouter()
  const swbConsent = cookies.get(labelCookiesConsent)
  const [allowed, setAllowed] = useState(false)

  useEffect(() => {
    if (window) {
      if (window?.dataLayer) {
        window.addEventListener('datalayerpush', getAnalyticConsentFromListener)
      } else {
        getAnalyticConsentFromCookie(swbConsent)
      }
    }

    return () =>
      window.removeEventListener(
        'datalayerpush',
        getAnalyticConsentFromListener
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAnalyticConsentFromCookie = consent => {
    const decodedConsentValue = consent ? parseJwt(consent) : {}
    setAllowed(decodedConsentValue?.purposes?.analytics?.allowed === 'true')
  }

  const getAnalyticConsentFromListener = e => {
    if (e?.detail) {
      setAllowed(e?.detail?.analytics)
      const scriptElement = document.getElementById(scriptId)
      const isReload = scriptElement !== null && !e?.detail?.analytics
      if (isReload) {
        router.reload()
      }
    }
  }

  if (!allowed) {
    return <></>
  }

  return (
    <Script
      id={scriptId}
      data-testid={scriptId}
      type='text/javascript'
      src={urlTag}
    />
  )
}
