import { OrderConfirmation } from '#src/coffee-subscription/gifting/types'
import {
  FilteredOrderConfirmation,
  GiftingPurchaseLineItem,
  GiftingPurchaseLineItemTransformed
} from './types'
import {
  DEFAULT_VALUE_TBC,
  GIFTING_PRET_NAME_PREFIX,
  PRODUCT_BRAND,
  PRODUCT_CATEGORY_COFFEE_SUBSCRIPTION,
  PRODUCT_VARIANT_COFFEE_SUBSCRIPTION
} from '../../constants'

export const filterGiftingOrderConfirmation = ({
  orderNumber,
  totalPrice: { centAmount, currencyCode, fractionDigits },
  lineItems,
  orderType: coffeeSubscription,
  sku
}: OrderConfirmation) =>
  ({
    orderNumber,
    price: getPriceInMajorCurrencyUnit(centAmount, fractionDigits),
    currencyCode,
    lineItems: [...lineItems],
    coffeeSubscription,
    sku
  } as FilteredOrderConfirmation)

export const getPriceInMajorCurrencyUnit = (
  centAmount = 0,
  fractionDigits = 0
) => centAmount / Math.pow(10, fractionDigits)

export const transformGiftingPurchaseLineItems = (
  lineItems: GiftingPurchaseLineItem[]
): GiftingPurchaseLineItemTransformed[] =>
  lineItems.map(lineItem => ({
    brand: PRODUCT_BRAND,
    category: PRODUCT_CATEGORY_COFFEE_SUBSCRIPTION,
    coupon: DEFAULT_VALUE_TBC,
    dimension11: 'UK',
    name: `${GIFTING_PRET_NAME_PREFIX}${lineItem.quantity} months`,
    price: getPriceInMajorCurrencyUnit(
      lineItem?.totalPrice?.centAmount,
      lineItem?.totalPrice?.fractionDigits
    ),
    quantity: 1,
    variant: PRODUCT_VARIANT_COFFEE_SUBSCRIPTION
  }))
