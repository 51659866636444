import styled from '@emotion/styled'
import tw from 'twin.macro'

export const StyleChevronLink = styled.a`
  ${tw`border-0 p-0 text-pretRed-700 no-underline hover:underline focus:underline`}
  span {
    ${tw`text-pretRed-700`}
  }
  svg {
    ${tw`inline-block ml-2`}
  }
`
