import {
  ukClickCollectCheckoutRegEx,
  usClickCollectCheckoutRegEx
} from './utils/regex-paths'

export const itemsToAdd = { sectionType: 'Click & Collect' }

export const itemValuesToReplace = {
  checkoutType: {
    from: 'guest',
    to: 'customer'
  }
}

export const itemsToRemove = ['chargebeeSubscriptionId', 'coffeeSubscription']

export const shouldCheckoutContainTransformedPretEcommerce = pathname =>
  ukClickCollectCheckoutRegEx.test(pathname) ||
  usClickCollectCheckoutRegEx.test(pathname)

export const transformPretEcommerce = pretEcommerce =>
  Object.keys(pretEcommerce).reduce(
    (acc, key) => {
      if (!itemsToRemove.includes(key)) {
        acc[key] = pretEcommerce[key]
      }
      if (
        Object.getOwnPropertyDescriptor(acc, key) &&
        Object.getOwnPropertyDescriptor(itemValuesToReplace, key) &&
        acc[key] === itemValuesToReplace[key].from
      ) {
        acc[key] = itemValuesToReplace[key].to
      }

      return acc
    },
    { ...itemsToAdd }
  )
