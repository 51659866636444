import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../state/redux-hooks'
import { bagSelector, giftBagStateSlice } from '#src/state/gift-bag-state-slice'
import { envSelector } from '#src/state/env-state-slice'
import { getGiftBagId } from '#src/common/lib/get-bag-id'
import { getBagById } from '#src/common/lib/api/bag'

const useGiftBag = locale => {
  const dispatch = useAppDispatch()
  const giftBagReduxState = useAppSelector(bagSelector)
  const env = useAppSelector(envSelector)

  useEffect(() => {
    const fetchGiftBag = async () => {
      if (!Object.keys(env).length) return

      const giftBagIdCookieValue = getGiftBagId()

      if (!giftBagReduxState.bagId && giftBagIdCookieValue) {
        const fetchedGiftBag = await getBagById(locale, giftBagIdCookieValue)

        dispatch(giftBagStateSlice.actions.loadBag(fetchedGiftBag))
      }
    }

    fetchGiftBag()
    // dispatch and locale are safe to ignore
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [giftBagReduxState, env])
}
/* eslint-enable react-hooks/exhaustive-deps */

export default useGiftBag
