import React, { useEffect } from 'react'
import { fireCheckoutEvent } from '#src/common/lib/events/publishers/analytics/fire-events'

export interface PageViewCheckoutEventProps {
  step: number
  bag: any
  isLoading?: boolean
  shouldEnforceSingleFire?: boolean
  fireEvent?: typeof fireCheckoutEvent
  isGifting?: boolean
  checkoutSub?: boolean
  state?: string
}

let hasPageViewEventFired = false

export const PageViewCheckoutEvent: React.FC<PageViewCheckoutEventProps> = ({
  step,
  bag,
  isLoading = false,
  shouldEnforceSingleFire = false,
  fireEvent = fireCheckoutEvent,
  isGifting = false,
  checkoutSub = false,
  state
}) => {
  useEffect(() => {
    if (
      !hasPageViewEventFired &&
      !isLoading &&
      bag?.lineItems?.length &&
      typeof step !== 'undefined'
    ) {
      fireEvent({ bag, step, isGifting, checkoutSub, state })
      if (shouldEnforceSingleFire) {
        hasPageViewEventFired = true
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bag, isLoading, step, shouldEnforceSingleFire, isGifting])

  return null
}
