import { v4 as uuidv4 } from 'uuid'
import { setCookie, parseCookies, destroyCookie } from 'nookies'
import { pathOr } from 'ramda'
import { NextApiResponse, NextPageContext } from 'next'

export const PRET_SESSION_ID_KEY = 'pret-session-id'
export const PRET_DELIVERS_LD_USER_ID = 'pret-delivers-ld-user-id'
export const BAG_ID_COOKIE = 'pret-bag-id'
export const ACCESS_TOKEN_COOKIE = 'pret-access-token'
export const BAG_INFO_COOKIE = 'pret-bag-info'
export const getSessionId = (): string => {
  if (typeof window === 'undefined') {
    return
  }

  const sessionId = pathOr<string>(
    uuidv4(),
    [PRET_SESSION_ID_KEY],
    parseCookies()
  )

  return sessionId
}
export const setUserIdCookie = (userId: string) => {
  setCookie(null, PRET_DELIVERS_LD_USER_ID, userId, {
    path: '/'
  })
}

export const getUserIdFromCookie = (ctx?: NextPageContext) => {
  return parseCookies(ctx)[PRET_DELIVERS_LD_USER_ID]
}

export const deleteUserIdFromCookie = () => {
  destroyCookie(null, PRET_DELIVERS_LD_USER_ID, { path: '/' })
}

export const setAccessTokenCookies = (accessToken: string) => {
  setCookie(null, ACCESS_TOKEN_COOKIE, accessToken, {
    path: `/`
  })
}

export const deleteAccessTokenCookies = (res?: NextApiResponse) => {
  destroyCookie(res ? { res } : null, ACCESS_TOKEN_COOKIE, { path: '/' })
}

export const deleteBagCookies = () => {
  destroyCookie(null, BAG_ID_COOKIE, { path: '/' })
  destroyCookie(null, BAG_INFO_COOKIE, { path: '/' })
}

export const clearCookiesOnLogout = () => {
  deleteUserIdFromCookie()
  deleteAccessTokenCookies()
  deleteBagCookies()
}
