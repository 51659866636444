import styled from '@emotion/styled'
import tw from 'twin.macro'
import BagIcon from '#src/common/app/components/bag-cta/bag-icon'

export const BagContainer = styled('button')`
  ${tw`w-10 h-8 mx-auto relative inline-block focus:outline-none focus:shadow-outline px-2 ml-2`}
  ${({ itemCount }) => (itemCount > 0 ? tw`text-pretRed-700` : '')}
`

export const StyledBagIcon = styled(BagIcon)`
  ${tw`align-bottom inline`}
`
