import { getEnvVarsSingleton } from '#src/state/store'
import httpClient from './http-client'
import { removeUndefinedProperties } from '#src/common/lib/object'
import { ResponseError } from './types'

const getHost = () => {
  const host = process?.env?.API_HOST || ''
  return host
}

export const getEnvVars = async (varToGet?: string) => {
  const allVars =
    typeof window === 'undefined'
      ? {
          platformApiHost: process.env.PLATFORM_API_HOST,
          authApiBaseUrl: process.env.AUTH_API_BASE_URL,
          apiHost: process.env.API_HOST,
          checkoutApiHost: process.env.CHECKOUT_API_HOST,
          coffeeSubsManageBaseUrl: process.env.CS_MANAGE_API_BASE_URL,
          auth0Audience: process.env.AUTH0_AUDIENCE,
          auth0ClientId: process.env.AUTH0_CLIENT_ID,
          auth0Domain: process.env.AUTH0_DOMAIN,
          clickAndCollectUrl: process.env.CLICK_AND_COLLECT_URL,
          clickAndCollectToken: process.env.CLICK_AND_COLLECT_TOKEN,
          mentionMeTag: process.env.MENTION_ME_TAG,
          googlePayEnv: process.env.GOOGLE_PAY_ENV,
          appAdsTxtHostUS: process.env.APP_ADS_TXT_HOST_US,
          sessionTimeout: process.env.NEXT_PUBLIC_SESSION_TIMEOUT_IN_MINS,
          promptTimeout: process.env.NEXT_PUBLIC_PROMPT_BEFORE_TIMEOUT_IN_MINS,
          impersonationSessionTimeout:
            process.env.NEXT_PUBLIC_IMPERSONATION_SESSION_TIMEOUT_IN_MINS
        }
      : getEnvVarsSingleton()
  return varToGet ? allVars[varToGet] : removeUndefinedProperties(allVars)
}

export const getEnvVarsTactical = async (varToGet?: string) => {
  const host = getHost()
  const response = await httpClient(`${host}/api/env-vars`)
  if (!response.ok) {
    throw new ResponseError(response)
  }
  const allVars = await response.json()
  return varToGet ? allVars[varToGet] : allVars
}
