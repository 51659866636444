import React from 'react'
import { Toast } from '@pretamanger/component-library'
import { useAppSelector } from '#src/state/redux-hooks'
import { accountSelector } from '#src/state/account-state-slice'

const ToastContainer = () => {
  const { toast } = useAppSelector(accountSelector)
  const { title = '', toastKey = '', variant = '' } = toast
  return title && <Toast title={title} toastKey={toastKey} variant={variant} />
}

export default ToastContainer
