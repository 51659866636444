import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { richText } from '@proptypes'
import Heading from './heading'
import LinkHyperLink from './link-hyperlink'
import LinkEntry from './link-entry'
import LinkAsset from './link-asset'

import Bold from './bold'
import Italic from './italic'
import BlockQuote from './block-quote'
import { Wrapper } from './styles'

const getOptions = (
  locale,
  openEntityLinksInNewWindow,
  openHyperLinksInNewWindow,
  additionalOptions,
  renderText = text => text
) => ({
  renderMark: {
    [MARKS.BOLD]: Bold,
    [MARKS.ITALIC]: Italic
  },
  renderNode: {
    [BLOCKS.HEADING_1]: Heading,
    [BLOCKS.HEADING_2]: Heading,
    [BLOCKS.HEADING_3]: Heading,
    [BLOCKS.HEADING_4]: Heading,
    [BLOCKS.HEADING_5]: Heading,
    [BLOCKS.HEADING_6]: Heading,
    [BLOCKS.QUOTE]: BlockQuote,
    [INLINES.HYPERLINK]: (node, children) =>
      LinkHyperLink(
        node,
        children,
        openHyperLinksInNewWindow,
        additionalOptions
      ),
    [INLINES.ASSET_HYPERLINK]: (node, children) =>
      LinkAsset(node, children, locale),
    [INLINES.ENTRY_HYPERLINK]: (node, children) =>
      LinkEntry(node, children, locale, openEntityLinksInNewWindow)
  },
  renderText
})

const RichText = ({
  text,
  openEntityLinksInNewWindow,
  openHyperLinksInNewWindow,
  withoutWrapper,
  richTextOptions = {},
  isFormatNewLine = false
}) => {
  const { locale } = useRouter()
  const renderText = text => {
    if (!isFormatNewLine) {
      return text
    }
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  }
  const options = getOptions(
    locale,
    openEntityLinksInNewWindow,
    openHyperLinksInNewWindow,
    richTextOptions,
    renderText
  )
  return withoutWrapper ? (
    documentToReactComponents(text, options)
  ) : (
    <Wrapper>{documentToReactComponents(text, options)}</Wrapper>
  )
}

RichText.propTypes = {
  text: richText.isRequired,
  openEntityLinksInNewWindow: PropTypes.bool,
  openHyperLinksInNewWindow: PropTypes.bool,
  withoutWrapper: PropTypes.bool
}

RichText.defaultProps = {
  openEntityLinksInNewWindow: false,
  openHyperLinksInNewWindow: false,
  withoutWrapper: false
}

export default RichText
