import { getLogger } from '#api/logger-with-context'
import { getOutpostById, getOrderById } from '#src/common/lib/api/order'
import { formatShopAddress } from '#lib/format-address'
import platformClient from '#src/common/lib/platform-client'
import {
  authHeader,
  fallbackLocaleCode,
  localeHeader,
  marketIdHeader
} from '#constants'
import { handleBagError } from '#src/common/lib/utils'

export const getOrderConfirmationById = async (
  orderId,
  locale,
  ctRemovalFlag = false
) => {
  const log = getLogger()
  try {
    return await getOrderById(locale, orderId, ctRemovalFlag)
  } catch (error) {
    log.error({ error }, 'Checkout:: error getOrderConfirmationById')
    throw error
  }
}

export const getShopById = async (shopId, locale = fallbackLocaleCode) => {
  const log = getLogger()
  try {
    log.info(
      { shopId, locale },
      'Checkout:: getOrderConfirmationShopById: start channelByWhereCondition query'
    )
    const result = await platformClient(`/shops/${shopId}`, {
      method: 'GET',
      headers: {
        [localeHeader]: locale
      }
    })

    const shop = formatShopAddress(result || {})
    log.info(
      { shopId, locale },
      'Checkout:: getOrderConfirmationShopById: start end query'
    )

    return {
      ...shop,
      timezoneName: result.timezoneName
    }
  } catch (error) {
    log.error({ error }, 'Checkout:: error getOrderConfirmationShopById')
    throw error
  }
}

export const getOutpost = async (outpostId, locale) => {
  const log = getLogger()
  try {
    return await getOutpostById(outpostId, locale)
  } catch (error) {
    log.error({ error }, 'Checkout:: error getOutpostById')
    throw error
  }
}

export const getOrderDetails = async (orderDetails, locale) => {
  const {
    fulfillingShopId,
    orderType,
    pickupTimeStart,
    pickupTimeEnd,
    outpostId
  } = orderDetails
  const [shop, outpost] = await Promise.all([
    getShopById(fulfillingShopId, locale),
    outpostId ? getOutpost(outpostId) : Promise.resolve()
  ])
  const pickupTime = `${pickupTimeStart} - ${pickupTimeEnd}`

  return {
    orderType,
    ...(outpost && { outpost }),
    pickupTime,
    shop,
    pickupEndTime: pickupTimeEnd,
    time: pickupTimeStart
  }
}

export const processTaxOnBag = async (bagId, locale, market, authToken) => {
  const log = getLogger()
  try {
    log.info({ bag: { id: bagId } }, 'Checkout:: processing tax on bag')
    const bag = await platformClient(`/bags/${bagId}?calculate-tax=true`, {
      method: 'PUT',
      version: 'v2',
      headers: {
        ...(authToken && {
          [authHeader]: `Bearer ${authToken}`
        }),
        [localeHeader]: locale,
        [marketIdHeader]: market
      },
      body: JSON.stringify({
        action: { type: 'RECALCULATE_BAG' }
      })
    })
    log.info({ bag: { id: bagId } }, 'Checkout:: processing tax on bag success')
    return bag
  } catch (error) {
    log.info(
      { error, bag: { id: bagId } },
      'Checkout:: processing tax on bag failed'
    )
    handleBagError(error, locale)
  }
}
