import { fireFeatureClickEvent } from '../core'
import { marketDetails } from '#constants'
import { PRET_UK_DOMAIN_WITH_SCHEME } from '../../../../subscribers/analytics/constants'

export const clickAndCollectHomePageEvent = () => {
  fireFeatureClickEvent({
    type: 'option_button',
    name: 'click_and_collect',
    destination: `${PRET_UK_DOMAIN_WITH_SCHEME}/${marketDetails.UK.locale}`
  })
}
