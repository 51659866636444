import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Clock } from '@pretamanger/component-library'

export const ModalContainer = styled.div``

export const TextWrapper = styled.div`
  ${tw`text-center`}
`

export const LargeClock = styled(Clock)`
  ${tw`m-auto mb-4`}
`

export const ButtonWrapper = styled.div`
  ${tw`mt-8 flex flex-col justify-center`}
`
