import { CHECKOUT } from '../../../../topics'

import {
  shouldCheckoutContainTransformedPretEcommerce,
  transformPretEcommerce
} from '../data-transformers/pret-ecommerce'

import {
  shouldCheckoutContainTransformedProductsAndActionFields,
  transformActionField,
  transformProducts
} from '../data-transformers/products'

export const checkoutAdapter = ({
  ecommerce = {
    checkout: {
      actionField: {},
      products: {}
    }
  },
  pretEcommerce = {}
}) => {
  const gtmObj = {
    event: CHECKOUT,
    ecommerce: {
      checkout: {}
    }
  }

  const { pathname } = window.location

  if (shouldCheckoutContainTransformedPretEcommerce(pathname)) {
    gtmObj['pretEcommerce'] = transformPretEcommerce(pretEcommerce)
  }

  if (shouldCheckoutContainTransformedProductsAndActionFields(pathname)) {
    const { actionField = {}, products = [] } = ecommerce?.checkout
    const transformedEcommerce = {
      checkout: {
        actionField: transformActionField(actionField),
        products: transformProducts(products)
      }
    }
    gtmObj.ecommerce = { ...transformedEcommerce }
  } else {
    gtmObj.ecommerce = { ...ecommerce }
  }

  return gtmObj
}
