import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useAppSelector } from '#src/state/redux-hooks'
import { accountSelector } from '#src/state/account-state-slice'
import { envSelector } from '#src/state/env-state-slice'

const checkCondition = (condition, value) => {
  if (condition === undefined) return true
  if (value === undefined) return false
  return condition === value
}

export const isAnnouncementExcluded = (promotion = {}, currentPath) =>
  (promotion.excludeFromPages || []).includes(currentPath)

const getValidPromotion = ({
  promotions,
  hasSubscription,
  isAuthenticated,
  locale
}) =>
  promotions.find(promotion => {
    const hasCoffeeSubscriptionCondition = promotion.hasCoffeeSubscription
    const isLoggedInCondition = promotion.isLoggedIn

    return (
      checkCondition(hasCoffeeSubscriptionCondition, hasSubscription) &&
      checkCondition(isLoggedInCondition, isAuthenticated) &&
      (promotion.locales || []).includes(locale) &&
      promotion.enabled
    )
  })

const usePromotions = ({ promotions = [], isTransactional }) => {
  const { locale, asPath } = useRouter()
  const [path = ''] = asPath.split('?')
  const [currentPromotion, setCurrentPromotion] = useState(null)
  const [showInPagePromo, setShowInPagePromo] = useState()
  const [showAnnouncementPromo, setShowAnnouncementPromo] = useState()
  const [dataForInPagePromo, setDataForInPagePromo] = useState(null)

  const { auth0Audience } = useAppSelector(envSelector)

  const {
    isAuthenticated,
    isLoading: isAuthLoading,
    subsLoading,
    subscriptions,
    subsError
  } = useAppSelector(accountSelector)

  useEffect(() => {
    const hasSubscription =
      isAuthLoading || subsError || subsLoading || !auth0Audience
        ? undefined
        : (subscriptions || []).length > 0
    const promotion = getValidPromotion({
      promotions,
      hasSubscription,
      isAuthenticated,
      locale
    })

    if (promotion?.id !== currentPromotion?.id) {
      setCurrentPromotion(promotion)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions, subsLoading, subsError, isAuthenticated, promotions])

  useEffect(() => {
    if (currentPromotion) {
      const { inPagePromos = {} } = currentPromotion
      const dataForInPage = inPagePromos[path]
      setDataForInPagePromo(dataForInPage)

      const showAnnouncement =
        currentPromotion &&
        !dataForInPage &&
        !isTransactional &&
        !isAnnouncementExcluded(currentPromotion, path)

      setShowInPagePromo(Boolean(dataForInPage))
      setShowAnnouncementPromo(showAnnouncement)
    } else {
      setShowInPagePromo(false)
      setShowAnnouncementPromo(false)
      setDataForInPagePromo(null)
    }
  }, [currentPromotion, path, isTransactional])

  return {
    dataForInPagePromo,
    currentPromotion,
    showInPagePromo,
    showAnnouncementPromo
  }
}

export default usePromotions
