import { client } from '../auth0/client'
import { getEnvVars } from '#src/common/lib/get-env-vars'

export const getEntitlements = async token => {
  const { coffeeSubsManageBaseUrl } = await getEnvVars()

  const entitlements = await client('/v1/manage/entitlements', {
    apiUrl: coffeeSubsManageBaseUrl,
    token
  })

  return entitlements
}
