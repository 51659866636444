import PropTypes from 'prop-types'
import { Checkbox } from '@pretamanger/component-library'
import { bagProps } from '@proptypes'
import Translation from '#src/common/components/translation'
import { CutleryContainer, CutleryReadOnlyContainer } from './styles'

const CutleryItem = ({ bag, updateCutleryOption, readOnly }) => {
  const handleCutleryChange = e => {
    updateCutleryOption(e.target.checked)
  }
  if (readOnly) {
    return (
      <CutleryReadOnlyContainer>
        <span className='text-grey-400'>
          <Translation id='bag.order.utensils' />
        </span>
        <span className='pl-1 pr-2 text-grey-400'>:</span>
        <span>
          {bag.orderDetails.addCutlery ? (
            <Translation id='bag.order.utensil.taken' />
          ) : (
            <Translation id='bag.order.utensil.not-taken' />
          )}
        </span>
      </CutleryReadOnlyContainer>
    )
  }
  return (
    <CutleryContainer>
      <Checkbox
        name='cutlery'
        label={
          <span className='sr-only'>
            <Translation id='bag.order.utensils' />
          </span>
        }
        defaultChecked={!!bag.orderDetails.addCutlery}
        onChange={handleCutleryChange}
        isSelected={!!bag.orderDetails.addCutlery}
        id='cutlery-option'
      />
      <span aria-hidden>
        <Translation id='bag.order.utensils' />
      </span>
    </CutleryContainer>
  )
}

CutleryItem.propTypes = {
  updateCutleryOption: PropTypes.func,
  bag: bagProps.isRequired,
  readOnly: PropTypes.bool
}

CutleryItem.defaultProps = {
  bag: {},
  readOnly: true
}

export default CutleryItem
