import { useCookie as _useCookie } from 'react-use'

const useCookie = name => {
  const [value, set, remove] = _useCookie(name)
  const secure = !process.env.INSECURE_COOKIES
  return [
    value,
    (val, opts) => {
      set(val, { ...opts, secure })
    },
    remove
  ]
}

export default useCookie
