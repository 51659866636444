import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Cross, getScreenSizes } from '@pretamanger/component-library'

export const Inner = styled.div`
  ${tw`flex relative w-full pr-6 md:pr-0`}
`

export const Content = styled.div`
  ${tw`flex md:items-center mx-auto`}
`

export const CrossIcon = styled(Cross)`
  ${tw`inline`}
  width: 0.8em;
  height: 0.8em;
`

export const CloseButton = styled('button')`
  ${tw`
    focus:outline-none focus:shadow-outline p-2
    absolute top-0 right-0
  `}
  line-height: 0;
  @media (min-width: ${getScreenSizes().md}px) {
    top: 50%;
    transform: translateY(-50%);
  }
`
