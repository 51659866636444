import PropTypes from 'prop-types'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { Button } from '@pretamanger/component-library'
import {
  checkIfLocaleFormatIsValid,
  getCountryFromLocale
} from '#src/common/lib/locale'
import Translation from '#src/common/components/translation'
import {
  frScript,
  hkScript,
  ukScript,
  usScript,
  gcmScript,
  gtmScript,
  ketchPermitScript
} from './constants'
import { useEffect } from 'react'
import {
  destroyPretAnonymousId,
  destroyPretSessionId,
  setPretAnonymousId,
  setPretSessionId
} from '../cookie'

export const Ketch = () => {
  return process.env.NODE_ENV === 'development' ? null : <KetchScript />
}

const scriptKetch = {
  GB: ukScript,
  FR: frScript,
  HK: hkScript,
  US: usScript
}

export const KetchScript = () => {
  const { locale } = useRouter()
  const countryCode = checkIfLocaleFormatIsValid(locale)
    ? getCountryFromLocale(locale)
    : 'US'
  const script = scriptKetch[countryCode] || usScript

  return (
    <>
      <Script
        id='ketch-script'
        data-testid='ketch-script'
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: script
        }}
      />
      <Script
        id='google-consent-mode'
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: gcmScript
        }}
      />
      <Script
        id='gtm-host-script'
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: gtmScript
        }}
      />
      <Script
        id='ketch-permit-script'
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: ketchPermitScript
        }}
      />
    </>
  )
}

KetchScript.propTypes = {
  ketchScript: PropTypes.string
}

KetchScript.defaultProps = {
  ketchScript: ''
}

export const CookieSettings = () => {
  useEffect(() => {
    if (window) {
      if (window?.dataLayer) {
        window.addEventListener('datalayerpush', getAnalyticConsentFromListener)
      }
    }

    return () =>
      window.removeEventListener(
        'datalayerpush',
        getAnalyticConsentFromListener
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAnalyticConsentFromListener = e => {
    if (e?.detail) {
      if (e?.detail?.analytics) {
        setPretSessionId()
      }

      if (!e?.detail?.analytics) {
        destroyPretSessionId()
      }

      if (e?.detail?.functionality) {
        setPretAnonymousId()
      }

      if (!e?.detail?.functionality) {
        destroyPretAnonymousId()
      }
    }
  }

  return (
    <div className='relative'>
      <Button
        className='absolute bottom-0 left-0'
        data-testid='cookies-modal'
        styleType='secondary'
        onClick={() => {
          if (window?.semaphore) {
            window?.semaphore?.push(['showPreferences'])
          }
        }}
      >
        <Translation id='cookie.preferences.button' />
      </Button>
    </div>
  )
}
