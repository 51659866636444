import { useEffect } from 'react'

const useResize = (onResize = () => {}) => {
  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useResize
