import { useState } from 'react'
import { validateField } from '../lib/api/form'

const useInput = initialValue => {
  const [fieldState, setFieldState] = useState(initialValue)
  const validate = val => validateField(fieldState.form, fieldState.id, val)

  return {
    fieldState,
    setFieldState,
    bind: {
      fieldState,
      onChange: e =>
        setFieldState({
          ...fieldState,
          ...(fieldState.dirty && { isValid: validate(e.target.value) })
        })
    }
  }
}

export default useInput
