import { GTM_JS } from '../../../../topics'

export const gtmJsAdapter = () => {
  const gtmObj = {
    event: GTM_JS,
    ecommerce: {}
  }
  gtmObj['gtm.start'] = new Date().getTime()
  gtmObj.ecommerce = undefined

  return gtmObj
}
