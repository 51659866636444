import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { getPretStore } from '#src/common/lib/preferences/get-preferences'

export type PretShopsState = {
  shops: Array<any>
  total: number
  statusOk?: boolean
  isLoading?: boolean
  error?: boolean
}

const initialState: PretShopsState = {
  shops: [],
  total: 0
}

export const fetchAllPretShops = createAsyncThunk<
  PretShopsState,
  void,
  { state: RootState }
>('shops/allShops', async () => {
  return await getPretStore()
})

export const pretShopsSlice = createSlice({
  name: 'pretStore',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAllPretShops.pending, (state: PretShopsState) => {
      state.isLoading = true
      state.statusOk = false
      state.error = false
    }),
      builder.addCase(fetchAllPretShops.rejected, (state: PretShopsState) => {
        state.statusOk = false
        state.isLoading = false
        state.error = true
      }),
      builder.addCase(
        fetchAllPretShops.fulfilled,
        (state: PretShopsState, action: PayloadAction<any>) => ({
          ...state,
          shops: action.payload.shops,
          total: action.payload.total,
          ...action.payload,
          isLoading: false,
          error: false,
          statusOk: true
        })
      )
  }
})

export const pretShopsSelector = ({ pretShops }: RootState): any => pretShops
