import PropTypes from 'prop-types'
import { useState } from 'react'
import useIsomorphicLayoutEffect from 'react-use/lib/useIsomorphicLayoutEffect'
import { Announcement } from '@pretamanger/component-library'
import { Content, Inner, CrossIcon, CloseButton } from './styles'
import { userPreferences } from './userPreferences'

const USER_PREFERENCES_ANNOUNCEMENT_KEY = 'pret-user-preferences-announcements'

export const userAnnouncements = userPreferences(
  USER_PREFERENCES_ANNOUNCEMENT_KEY
)

export const Announcements = ({ children, label }) => {
  return (
    <aside aria-live='polite' aria-label={label}>
      {children}
    </aside>
  )
}

Announcements.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired
}

export const Announcer = ({ children, id, variant }) => {
  const [showBanner, setShowBanner] = useState(false)

  useIsomorphicLayoutEffect(() => {
    const userPrefs = userAnnouncements.get()
    const userClosedAnnouncement = userPrefs.includes(id)
    if (!userClosedAnnouncement) {
      setShowBanner(true)
    }
  }, [])

  const handleClose = () => {
    setShowBanner(false)
    userAnnouncements.update(id)
  }

  return showBanner ? (
    <Announcement variant={variant}>
      <Inner
        data-testid={`announcement-${id}`}
        data-marketing-id={`announcement-${id}`}
      >
        <Content>{children}</Content>
        <CloseButton
          type='button'
          aria-label='Close announcement'
          onClick={handleClose}
        >
          <CrossIcon
            aria-hidden
            focusable='false'
            colour={variant === 'hotPink' ? 'white' : 'black'}
          />
        </CloseButton>
      </Inner>
    </Announcement>
  ) : null
}

Announcer.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  variant: PropTypes.string
}
