import { fireFeatureClickEvent } from '../core'
import { marketDetails, orderOnlinePath } from '#constants'
import { PRET_UK_DOMAIN_WITH_SCHEME } from '../../../../subscribers/analytics/constants'

export const uberEatsEvent = () => {
  fireFeatureClickEvent({
    type: 'option_button',
    name: 'uber_eats',
    destination: `${PRET_UK_DOMAIN_WITH_SCHEME}/${marketDetails.UK.locale}/${orderOnlinePath}`
  })
}
