import { removeCurrency } from '../utils/remove-currency'

const createProduct = (
  item,
  unitPrice,
  isCoffeeSubscriptionApplied,
  unitPriceBeforeDiscount
) => ({
  name: item.name,
  id: item.sku,
  price: unitPrice,
  category: 'TBC',
  variant: 'TBC',
  quantity: item.quantity || 1,
  dimension5: isCoffeeSubscriptionApplied,
  metric5: unitPriceBeforeDiscount,
  position: item.recommendationPosition ? item.recommendationPosition : '',
  dimension4: item.recommendationId ? item.recommendationId : ''
})

const transformDiscountedProduct = (
  item,
  paidPrice,
  isCoffeeSubscriptionApplied,
  unitPriceBeforeDiscount
) => {
  if (item.quantity === 1) {
    return createProduct(
      item,
      '0.00',
      isCoffeeSubscriptionApplied,
      unitPriceBeforeDiscount
    )
  }
  const unitPrice = removeCurrency(item.price.localisedPrice)
  return [
    createProduct({ ...item, quantity: 1 }, '0.00', true, unitPrice),
    createProduct(
      { ...item, quantity: item.quantity - 1 },
      unitPrice,
      false,
      unitPrice
    )
  ]
}

export const getIsSubscriptionBeingRedeemed = lineItems =>
  lineItems.some(({ dimension5 }) => dimension5)

export const transformLineItemsForPayload = lineItems =>
  lineItems.flatMap(item => {
    const priceBeforeDiscount = removeCurrency(
      item.originalPrice?.localisedPrice || item.totalPrice.localisedPrice
    )
    const unitPrice = removeCurrency(item.price.localisedPrice)
    const paidPrice = removeCurrency(item.totalPrice.localisedPrice || '')
    const isCoffeeSubscriptionApplied = priceBeforeDiscount !== paidPrice
    if (isCoffeeSubscriptionApplied) {
      return transformDiscountedProduct(
        item,
        paidPrice,
        isCoffeeSubscriptionApplied,
        unitPrice
      )
    }
    return createProduct(
      item,
      unitPrice,
      isCoffeeSubscriptionApplied,
      unitPrice
    )
  })

const transformClickAndCollectFeeForPayload = serviceFee => ({
  name: serviceFee.name,
  id: serviceFee.id,
  price: removeCurrency(serviceFee.totalPrice.localisedPrice),
  quantity: serviceFee.quantity
})

export const transformPurchaseDataForPayload = (
  orderConfirmation,
  shop,
  chargebeeSubscriptionId
) => {
  const { orderNumber, pickupStartTime, lineItems, taxedPrice, serviceFee } =
    orderConfirmation

  const transformedLineItems = transformLineItemsForPayload(lineItems)

  if (serviceFee) {
    transformedLineItems.push(transformClickAndCollectFeeForPayload(serviceFee))
  }

  return {
    lineItems: transformedLineItems,
    orderNumber,
    pickupTime: pickupStartTime,
    price: taxedPrice?.totalGross.localisedPrice,
    currencyCode: taxedPrice?.totalGross.currencyCode,
    storeLocation: shop.name,
    storeCity: shop.city,
    coffeeSubscription: Boolean(chargebeeSubscriptionId),
    chargebeeSubscriptionId: getIsSubscriptionBeingRedeemed(
      transformedLineItems
    )
      ? chargebeeSubscriptionId
      : ''
  }
}
