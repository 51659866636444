import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { market } from '@proptypes'
import { Announcement } from '@pretamanger/component-library'
import Translation, { translate } from '#src/common/components/translation'
import { ie } from '#lib/browser-version'
import { Announcements, Announcer } from '../announcements'
import MarketNotification from '../market-notification'
import usePromotions from '#src/common/hooks/usePromotions'
import { Promotion } from '#src/common/app/components/announcements/promotion'

const AnnouncementsContainer = ({ promotions, markets, isTransactional }) => {
  const { locale } = useRouter()
  const { currentPromotion, showAnnouncementPromo } = usePromotions({
    promotions,
    isTransactional
  })
  const ie11Banner = ie() === 11 && (
    <Announcement variant='red'>
      <Translation id='ie11.announcement' />
    </Announcement>
  )

  return (
    <>
      <Announcements
        label={translate('page.notifications.title', locale)?.value}
      >
        {ie11Banner}
        {showAnnouncementPromo && currentPromotion && (
          <Announcer
            id={currentPromotion.id}
            variant={currentPromotion.variant}
          >
            <Promotion {...currentPromotion} />
          </Announcer>
        )}
      </Announcements>
      <MarketNotification markets={markets} />
    </>
  )
}

AnnouncementsContainer.propTypes = {
  promotions: PropTypes.array,
  markets: PropTypes.arrayOf(market)
}

AnnouncementsContainer.defaultProps = {
  markets: [],
  promotions: []
}

export default AnnouncementsContainer
