import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Translation from '../../../../components/translation'
import PropTypes, { bool, func } from 'prop-types'
import {
  DiscountPriceWrapper,
  RemoveDiscount,
  RemoveDiscountError,
  YourBaristaText
} from './styles'
import { removeDiscount } from '#lib/api/bag'
import { orderAheadBagIdHeader, discountTypes } from '#constants'
import { useCookie } from '#hooks'
import { PaperCup } from '@pretamanger/component-library'
import { LoyaltyLabel } from '#src/common/components/loyalty/label'
import { useAuth0Context } from '#src/common/context/auth0-context'

const RedemptionCodeDiscount = ({
  discountType,
  allowRemoveDiscount,
  refreshBag
}) => {
  const [removeDiscountError, setRemoveDiscountError] = useState(false)
  const [bagId] = useCookie(orderAheadBagIdHeader)
  const { locale } = useRouter()
  const { user } = useAuth0Context()

  const undo = async () => {
    try {
      await removeDiscount(bagId, locale)
    } catch (err) {
      setRemoveDiscountError(true)
      return
    }
    await refreshBag()
  }

  if (discountType === discountTypes.COFFEE_PLAN) {
    return (
      <DiscountPriceWrapper>
        <PaperCup />
        <YourBaristaText>
          <Translation id='bag.yourBarista.title' />
        </YourBaristaText>
        {allowRemoveDiscount && !user && (
          <RemoveDiscount onClick={undo}>
            <Translation id='bag.yourBarista.removeDiscount' />
          </RemoveDiscount>
        )}
        {removeDiscountError && !user && (
          <RemoveDiscountError>
            <Translation id='bag.yourBarista.removeDiscountError' />
          </RemoveDiscountError>
        )}
      </DiscountPriceWrapper>
    )
  }
  if (discountType === discountTypes.PRET_PERKS) {
    return <LoyaltyLabel />
  }

  return null
}

RedemptionCodeDiscount.propTypes = {
  discountType: PropTypes.oneOf(['YourBaristaFree', 'STARS_REWARD']),
  allowRemoveDiscount: bool,
  refreshBag: func
}

RedemptionCodeDiscount.defaultProps = {
  discountType: 'YourBaristaFree',
  allowRemoveDiscount: false,
  refreshBag: () => {}
}

export default RedemptionCodeDiscount
