import fetch from 'isomorphic-unfetch'
import isEmpty from 'ramda/src/isEmpty'
import { v4 as uuidv4 } from 'uuid'
import {
  anonymousIdHeader,
  orderAheadBagIdHeader,
  localeHeader,
  traceId
} from '#constants'
import {
  provideAnonymousId,
  getAnonymousIdFromCtx
} from './provide-anonymous-id'
import getBagId, {
  getBagIdFromCtx,
  getSubscriptionBagIdFromCtx,
  getSubscriptionBagId
} from './get-bag-id'

export const httpClient = (url, config = {}) => {
  const updatedConfig = { ...config }
  if (config && ['POST', 'PUT', 'GET'].includes(config.method)) {
    updatedConfig.headers = {
      ...updatedConfig.headers,
      'Content-Type': 'application/json'
    }
  }

  //ADD traceId
  const traceIdValue = uuidv4().replace(/-/g, '')
  if (!updatedConfig.headers || !updatedConfig.headers[traceId]) {
    updatedConfig.headers = {
      ...updatedConfig.headers,
      [traceId]: traceIdValue
    }
  }

  return fetch(url, updatedConfig).catch(err => {
    err.traceId = traceIdValue
    throw err
  })
}

export const getTransactionalHeaders = (ctx = {}) => {
  const bagId = isEmpty(ctx) ? getBagId() : getBagIdFromCtx(ctx)
  return {
    ...(bagId && { [orderAheadBagIdHeader]: bagId }),
    ...getNonTransactionalHeaders(ctx)
  }
}

export const getSubscriptionHeaders = (ctx = {}) => {
  const bagId = isEmpty(ctx)
    ? getSubscriptionBagId()
    : getSubscriptionBagIdFromCtx(ctx)
  return {
    ...(bagId && { [orderAheadBagIdHeader]: bagId }),
    ...getNonTransactionalHeaders(ctx)
  }
}

export const getNonTransactionalHeaders = (ctx = {}) => {
  const { locale } = ctx
  const id = isEmpty(ctx) ? provideAnonymousId() : getAnonymousIdFromCtx(ctx)

  return {
    ...(locale && { [localeHeader]: locale }),
    ...(id && { [anonymousIdHeader]: id })
  }
}

export default httpClient
