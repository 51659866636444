import styled from '@emotion/styled'
import { css } from '@emotion/react'
import tw from 'twin.macro'

const withLabel = css`
  > li:first-of-type {
    ${tw`w-full mb-4`}
  }
`
export const SocialContainer = styled('ul')`
  ${tw`mt-8 lg:my-0 flex flex-wrap justify-start lg:pb-8`}
  > li {
    ${tw`mr-6`}

    &:last-of-type {
      ${tw`mr-0`}
    }

    svg {
      width: auto;
      height: 24px;
    }
  }
  ${({ hasLabel }) => (hasLabel ? withLabel : '')}
`
export const SocialLink = styled('a')`
  ${tw`inline-block`}
`
