import React from 'react'
import PropTypes from 'prop-types'
import LocaleLink from '#src/common/components/locale-link'
import { Chevron } from '@pretamanger/component-library'
import { StyleChevronLink } from './styles'

const ChevronLink = ({ text, link }) => {
  return (
    <LocaleLink href={link}>
      <StyleChevronLink>
        <span>{text}</span>
        <Chevron type='right' aria-hidden='true' />
      </StyleChevronLink>
    </LocaleLink>
  )
}

ChevronLink.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node
  ]),
  link: PropTypes.string
}

export default ChevronLink
