import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { getProfile } from '#src/common/lib/auth0/get-profile'
import { provideAnonymousId } from '#src/common/lib/provide-anonymous-id'

export interface ProfileState {
  firstName?: string
  lastName?: string
  name?: string
  phoneNumber?: string
  email?: string
  emailVerified?: boolean
  pretId?: string
  locale?: string
  createdAt?: string
  updatedAt?: string
  lastLogin?: string
  loginCount?: number
  error?: boolean
  isLoading: boolean
  // Due to analytics purpose, we need this API state in purpose to be more stateful
  isLoaded: boolean
  userRole?: Array<UserRoles>
  isBusinessPlusAccount?: boolean
}

export interface UserRoles {
  id: string
  name: string
  description: string
}

const initialState: ProfileState = {
  isLoading: false,
  error: false,
  isLoaded: false
}

export const fetchProfile = createAsyncThunk<
  ProfileState,
  { token: string },
  { state: RootState }
>('profile/fetchProfile', async (options, { getState }) => {
  const { env = {} } = getState()
  const anonymousId = provideAnonymousId()
  return await getProfile(anonymousId, env.auth0Audience, options?.token)
})

export const profileStateSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<Partial<ProfileState>>) => ({
      ...state,
      ...action.payload
    })
  },
  extraReducers: builder => {
    builder.addCase(fetchProfile.pending, state => {
      state.isLoading = true
      state.error = false
    })
    builder.addCase(fetchProfile.rejected, state => {
      state.isLoading = false
      state.error = true
    })
    builder.addCase(fetchProfile.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
      error: false,
      isLoaded: true
    }))
  }
})

export const profileSelector = ({ profile }: RootState): ProfileState => profile
