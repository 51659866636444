import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { orderTypeSubscription } from '#src/common/constants'
import { BagState } from './types'

const initialState: BagState = {
  isLoading: true
}

export const checkForSubscriptionProducts = (products = []): boolean =>
  products.some(
    ({ productType }) =>
      productType === orderTypeSubscription ||
      productType?.key === orderTypeSubscription
  )

export const bagStateSlice = createSlice({
  name: 'bag',
  initialState,
  reducers: {
    bagLoading: state => ({
      ...state,
      isLoading: true
    }),
    loadBag: (state, action: PayloadAction<BagState>) => ({
      ...state,
      ...action.payload,
      isSubscription: !!checkForSubscriptionProducts(action.payload.lineItems),
      isLoading: false
    })
  }
})

export const bagSelector = ({ bag }: RootState): BagState => bag
export const bagItemCountSelector = ({ bag }: RootState): number =>
  bag.lineItems?.length || 0
export const bagIsSubscriptionSelector = ({ bag }: RootState): boolean =>
  Boolean(bag.isSubscription)
