import { auth0AuthenticatedCookieName } from '#lib/auth0/get-auth0-authenticated-cookie-name'
import { v4 as uuidv4 } from 'uuid'
import { getAvailablePlans } from '#src/common/lib/coffee-subscription/get-available-plans'
import { Auth0Client, Auth0ClientOptions, User } from '@auth0/auth0-spa-js'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { parseCookies } from 'nookies'
import { loyaltyFlag } from '#src/common/constants'
import { getMarketDetailsFromLocale } from '#src/common/lib/get-market'
import { parseJwtToken } from '#lib/auth0/get-profile'
import { getPaymentSources } from '#lib/coffee-subscription/get-payment-sources'
import { getSubscriptions } from '#lib/coffee-subscription/get-subscriptions'
import { provideAnonymousId } from '#lib/provide-anonymous-id'
import { EnvState } from './env-state-slice'
import { RootState } from './store'
import { tolerantPromiseAll } from '#src/common/lib/tolerantPromiseAll'
import { clientSideGetLaunchDarklyFlag } from '#src/common/lib/get-launch-darkly-flag'
import { getEntitlements } from '#lib/coffee-subscription/get-entitlements'
import { pathOr } from 'ramda'
import { PaymentSource, Subscription, SubscriptionPlan } from '#src/state/types'
import { NoticeProps } from '@pretamanger/component-library/dist/components/notice'

let auth0Client: Auth0Client = null

export const getAuth0Client = (): Auth0Client | null => auth0Client

export interface Claims {
  'http://pret.com/ctoolsId': string
  'http://pret.com/cbeeId': string
  'http://pret.com/pretId': string
  'http://pret.com/eeyeWalletId': string
  'http://pret.com/adyenId': string
  'http://pret.com/pretPublicId': string
  iss: string
  sub: string
  aud?: string[] | null
  iat: number
  exp: number
  azp: string
  scope: string
}

interface Entitlement {
  planId: string
}

interface Card {
  billingAddressCity: string
  billingAddressLine1: string
  billingAddressLine2: string
  billingAddressPostalCode: string
  brand: string
  expiryMonth: number
  expiryYear: number
  firstName: string
  fundingType: string
  last4: string
  lastName: string
}

type Toast = {
  title: string
  variant: string
  description?: string
  toastKey?: string
}

export type FeatureFlags = Record<string, boolean>

export interface AccountState {
  /**
   * @deprecated use `isLoading` instead
   */
  loaded?: boolean
  claims?: Claims
  isLoading?: boolean
  user?: User | null
  subscriptions?: Subscription[]
  subsLoading?: boolean
  subsError?: boolean
  paymentSources?: PaymentSource[]
  paymentSourcesLoading?: boolean
  availablePlans?: SubscriptionPlan[]
  accessToken?: string
  isAuthenticated?: boolean
  hasPreviousSession?: boolean
  loyaltyStateFlag?: boolean
  featureFlags?: FeatureFlags
  entitlements?: Entitlement[]
  entitlementsLoading?: boolean
  entitlementsError?: boolean
  audience?: any
  notice?: NoticeProps
  toast?: Toast
  isImpersonatedUser?: boolean
}

const initialState = {
  accessToken: undefined,
  availablePlans: [],
  claims: undefined,
  hasPreviousSession: false,
  isAuthenticated: false,
  isLoading: true,
  loaded: false,
  paymentSources: [],
  paymentSourcesLoading: false,
  subscriptions: [],
  subsLoading: true,
  subsError: false,
  user: undefined,
  loyaltyStateFlag: false,
  featureFlags: {},
  entitlements: [],
  entitlementsLoading: false,
  entitlementsError: false,
  audience: undefined,
  notice: undefined,
  toast: {},
  isImpersonatedUser: false
} as AccountState

export const fetchEntitlements = createAsyncThunk<Entitlement[]>(
  'account/fetchEntitlements',
  async () => {
    const token = await getAuth0Client().getTokenSilently()
    return (await getEntitlements(token)) as Promise<any>
  }
)

export const paymentSourcesRefetch = createAsyncThunk<Partial<AccountState>>(
  'account/paymentSourcesRefetch',
  async () => {
    const anonymousId = provideAnonymousId()
    return getPaymentSources(anonymousId)
  }
)

export const subsRefetch = createAsyncThunk<Partial<AccountState>>(
  'account/subsRefetch',
  async () => {
    const anonymousId = provideAnonymousId()
    return getSubscriptions(anonymousId)
  }
)

export const availablePlansRefetch = createAsyncThunk<
  Partial<AccountState>,
  {
    planId: string
    currencyCode: string
  }
>('account/availablePlansRefetch', async ({ planId, currencyCode }) => {
  return getAvailablePlans({
    planId,
    currencyCode
  })
})

export const accountStateSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    loadAccount: (state, action: PayloadAction<Partial<AccountState>>) => ({
      ...state,
      ...action.payload,
      isImpersonatedUser: !!action.payload.user?.authenticatedBy,
      isLoading: false,
      loaded: true
    }),
    updateAccount: (state, action: PayloadAction<Partial<AccountState>>) => ({
      ...state,
      ...action.payload
    }),
    setToast: (
      state,
      action: PayloadAction<{
        title: string
        variant?: string
        allowSameAsPrevious?: boolean
      }>
    ) => ({
      ...state,
      toast: {
        title: action.payload.title,
        variant: action.payload.variant || 'success',
        toastKey: action.payload.allowSameAsPrevious
          ? state.toast?.toastKey
          : uuidv4()
      }
    })
  },
  extraReducers: builder => {
    builder.addCase(fetchEntitlements.pending, state => {
      state.entitlementsLoading = true
    })
    builder.addCase(fetchEntitlements.fulfilled, (state, action) => {
      state.entitlementsLoading = false
      state.entitlements = action.payload
    })
    builder.addCase(fetchEntitlements.rejected, state => {
      state.entitlementsLoading = false
      state.entitlementsError = true
    })
    builder.addCase(paymentSourcesRefetch.pending, (state, action) => {
      state.paymentSourcesLoading = true
    })
    builder.addCase(paymentSourcesRefetch.fulfilled, (state, action) => {
      state.paymentSourcesLoading = false
      state.paymentSources = action.payload.paymentSources
      state.paymentSourcesLoading = false
    })
    builder.addCase(paymentSourcesRefetch.rejected, state => {
      state.paymentSourcesLoading = false
      state.paymentSources = null
      state.paymentSourcesLoading = false
    })
    builder.addCase(subsRefetch.pending, state => {
      state.subsLoading = true
    })
    builder.addCase(subsRefetch.rejected, state => {
      state.subsLoading = false
      state.subsError = true
    })
    builder.addCase(subsRefetch.fulfilled, (state, action) => {
      state.subsLoading = false
      state.subscriptions = pathOr([], ['subscriptions'], action.payload)
    })
    builder.addCase(availablePlansRefetch.fulfilled, (state, action) => {
      state.availablePlans = action.payload as SubscriptionPlan[]
    })
  }
})

export const getAccount = async (
  {
    auth0Audience: audience,
    auth0ClientId: clientId,
    auth0Domain: domain
  }: EnvState,
  featureFlags?: FeatureFlags
): Promise<AccountState> => {
  const options: Auth0ClientOptions = {
    domain,
    clientId,
    authorizationParams: { audience },
    useRefreshTokens: true,
    cacheLocation: 'localstorage'
  }

  auth0Client = new Auth0Client(options)
  const isAuthenticated = await auth0Client.isAuthenticated()

  if (!isAuthenticated) {
    return {
      loaded: true,
      subsLoading: false,
      paymentSourcesLoading: false,
      isAuthenticated
    }
  }
  try {
    const idTokenClaims = await auth0Client.getIdTokenClaims()
    const getTokenSilently = () =>
      auth0Client.getTokenSilently({
        cacheMode: 'off',
        authorizationParams: {
          userId: idTokenClaims?.impersonatedUserPretId
        }
      })
    const accessToken = await getTokenSilently()
    const claims = parseJwtToken(accessToken)
    const user = await auth0Client.getUser()
    const anonymousId = provideAnonymousId()
    const market = getMarketDetailsFromLocale(claims.locale)
    const loyaltyFeature = await clientSideGetLaunchDarklyFlag(
      loyaltyFlag,
      anonymousId,
      market
    )

    const hasPreviousSession =
      parseCookies()[auth0AuthenticatedCookieName] === 'true'

    let promiseAPICollection: Promise<any>[] = [
      getSubscriptions(anonymousId),
      getPaymentSources(anonymousId)
    ]

    /**
     * @deprecated This is an anti pattern and we should not add any more API calls here
     * Instead create a new state slice and use thunks
     */
    const [subscriptionsResponse, paymentSourcesResponse] =
      await tolerantPromiseAll(promiseAPICollection)
    /** */

    const subscriptions = pathOr([], ['subscriptions'], subscriptionsResponse)
    const paymentSources = pathOr(
      [],
      ['paymentSources'],
      paymentSourcesResponse
    )

    const availablePlans = subscriptions.length
      ? await getAvailablePlans({
          planId: subscriptions[0]?.planId,
          currencyCode: subscriptions[0]?.currencyCode
        })
      : []

    return {
      subsLoading: false,
      hasPreviousSession,
      loaded: true,
      isAuthenticated,
      claims,
      user,
      subscriptions,
      paymentSources,
      paymentSourcesLoading: false,
      availablePlans,
      loyaltyStateFlag: loyaltyFeature,
      featureFlags,
      audience,
      accessToken
    }
  } catch (e) {
    return {
      loaded: true,
      subsLoading: false,
      paymentSourcesLoading: false,
      isAuthenticated: false
    }
  }
}

export const accountSelector = ({ account }: RootState): AccountState => account

export const setSubscriptions = (subscriptions: Subscription[]) => {
  return accountStateSlice.actions.updateAccount({ subscriptions })
}
