import PropTypes from 'prop-types'
import {
  BannerText,
  IconContainer,
  NotificationBannerContainer,
  InnerWrapper
} from './styles'
import { InformationIcon } from '#src/common/components/NotificationBanner/InformationIcon'

const NotificationBanner = ({
  children,
  variant,
  colorVariant,
  icon,
  iconVariant,
  layout
}) => (
  <NotificationBannerContainer
    variant={variant}
    aria-live='polite'
    role='alert'
    className='flex'
  >
    <InnerWrapper>
      <IconContainer iconVariant={iconVariant}>{icon}</IconContainer>
      <div className={`flex items-${layout}`}>
        <BannerText
          colorVariant={colorVariant}
          className='text-base font-medium'
        >
          {children}
        </BannerText>
      </div>
    </InnerWrapper>
  </NotificationBannerContainer>
)

NotificationBanner.propTypes = {
  variant: PropTypes.oneOf(['grey', 'red', 'yellow', 'lightYellow', 'gold']),
  colorVariant: PropTypes.oneOf(['red', 'grey', 'white', 'yellow']),
  iconVariant: PropTypes.oneOf(['informationIcon', 'loyaltyIcon']),
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  layout: PropTypes.oneOf(['start', 'center'])
}

NotificationBanner.defaultProps = {
  variant: 'yellow',
  colorVariant: 'grey',
  iconVariant: 'informationIcon',
  icon: <InformationIcon />,
  layout: 'start'
}

export default NotificationBanner
