import { useRouter } from 'next/router'
import { SmallStyledDropdown } from './styles'
import { translate } from '../../../components/translation'
import { mapLocale } from '../../../lib/locale'
import { getMarketDetailsFromLocale } from '../../../lib/get-market'

const LanguageSwitch = () => {
  const router = useRouter()
  const mappedLocale = mapLocale(router.locale, true)
  const currentMarket = getMarketDetailsFromLocale(router.locale)

  const options = currentMarket.locales
    .map(m => ({
      id: m.code,
      value: m.code,
      label: translate(`language.${m.code}`, mappedLocale)?.value
    }))
    .filter(o => o.label)

  const onSelect = locale => {
    router.push(`/${locale}`, `/${locale}`, { locale })
  }

  return (
    options.length > 1 && (
      <SmallStyledDropdown
        id='languages'
        name='languages'
        current={router.locale}
        label={translate('header.language.label', mappedLocale)?.value}
        hideLabel
        onSelect={onSelect}
        options={options}
      />
    )
  )
}

export default LanguageSwitch
