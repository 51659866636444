import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import {
  RewardCategory,
  CategoryProduct
} from '#src/auth/my-account-page/types'
import { getRewardCategories } from '../common/lib/loyalty/get-reward-categories'
import { getCategoryProducts } from '../common/lib/loyalty/get-category-products'

export type RewardCategoriesState = {
  products?: CategoryProduct[]
  categoryRewards?: RewardCategory[]
  statusOk?: boolean
  isLoading?: boolean
  error?: boolean
}

const initialState: RewardCategoriesState = {
  products: [],
  categoryRewards: [],
  statusOk: true,
  isLoading: false
}

export const fetchRewardCategories = createAsyncThunk<
  RewardCategoriesState,
  { locale: string },
  { state: RootState }
>(
  'rewardCategories/fetchRewardCategories',
  async ({ locale }, { getState }) => {
    const { env = {} } = getState()
    return await getRewardCategories(env.platformApiHost, locale)
  }
)

export const fetchCategoryProducts = createAsyncThunk<
  RewardCategoriesState,
  { locale: string; campaignId: number },
  { state: RootState }
>(
  'rewardCategories/fetchCategoryProducts',
  async ({ locale, campaignId }, { getState }) => {
    const { env = {} } = getState()
    return await getCategoryProducts(env.platformApiHost, locale, campaignId)
  }
)

export const rewardCategoriesStateSlice = createSlice({
  name: 'rewardCategories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      fetchRewardCategories.pending,
      (state: RewardCategoriesState) => {
        state.isLoading = true
        state.statusOk = false
      }
    ),
      builder.addCase(
        fetchRewardCategories.rejected,
        (state: RewardCategoriesState) => {
          state.categoryRewards = []
          state.statusOk = false
          state.isLoading = false
        }
      ),
      builder.addCase(
        fetchRewardCategories.fulfilled,
        (state: RewardCategoriesState, action: PayloadAction<any>) => ({
          ...state,
          ...action.payload,
          isLoading: false
        })
      ),
      builder.addCase(
        fetchCategoryProducts.pending,
        (state: RewardCategoriesState, action: PayloadAction<any>) => {
          state.isLoading = true
          state.statusOk = false
        }
      ),
      builder.addCase(
        fetchCategoryProducts.rejected,
        (state: RewardCategoriesState) => {
          state.products = []
          state.statusOk = false
          state.isLoading = false
        }
      ),
      builder.addCase(
        fetchCategoryProducts.fulfilled,
        (state: RewardCategoriesState, action: PayloadAction<any>) => ({
          ...state,
          ...action.payload,
          isLoading: false
        })
      )
  }
})

export const rewardCategoriesSelector = ({
  rewardCategories
}: RootState): RewardCategoriesState => rewardCategories
