import PropTypes from 'prop-types'
import BagItem from './bag-item'
import { BagProductsList } from './styles'
import { lineItemProps } from '@proptypes'
import { FeeItem } from '../../fee-item'
import { BagItemContainer } from './bag-item/styles'

const BagItems = ({
  lineItems = [],
  onQuantityChange,
  refreshBag,
  serviceFee
}) => {
  return (
    <>
      <BagProductsList>
        {lineItems.map(item => (
          <BagItem
            key={item.id}
            item={item}
            onQuantityChange={onQuantityChange}
            refreshBag={refreshBag}
          />
        ))}
        {serviceFee && (
          <BagItemContainer>
            <FeeItem
              name={serviceFee.name}
              localisedPrice={serviceFee.totalPrice.localisedPrice}
            />
          </BagItemContainer>
        )}
      </BagProductsList>
    </>
  )
}

BagItems.propTypes = {
  lineItems: PropTypes.arrayOf(lineItemProps).isRequired,
  onQuantityChange: PropTypes.func,
  refreshBag: PropTypes.func.isRequired
}

BagItems.defaultProps = {
  onQuantityChange: () => {}
}

export default BagItems
