import { PRODUCT_LIST_IMPRESSION } from '../../../../topics'

export const productListImpressionAdapter = (props: any) => {
  const gtmObj = {
    event: PRODUCT_LIST_IMPRESSION,
    ecommerce: props.ecommerce
  }

  return gtmObj
}
