import { useState } from 'react'

const useChecked = (initial = false) => {
  const [checked, setChecked] = useState(initial)
  return [
    checked,
    e => {
      setChecked(e.target.checked)
    }
  ]
}

export default useChecked
