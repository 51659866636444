import { getLogger } from '#src/api/logger-with-context'
import { localeHeader } from '#src/common/constants'
import { getTokenSilently } from '#src/common/lib/auth0/auth0-functions'
import httpClient from '#src/common/lib/http-client'

const logReference = 'Get Company info::'

export const getCompanyInfoAPI = async (locale: string) => {
  const log = getLogger()

  try {
    log.info(`Getting company info`)
    const accessToken = await getTokenSilently()
    const response = await httpClient(
      `/pret-delivers-api/v1/accounts/company`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          [localeHeader]: locale,
          'x-forwarded-authorization': `Bearer ${accessToken}`
        }
      }
    )

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return await response.json()
  } catch (error) {
    log.error({ error }, `${logReference} error`)
    return null
  }
}
