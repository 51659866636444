import PropTypes from 'prop-types'

const commonCallToActionProps = {
  buttonLabel: PropTypes.string,
  buttonAriaLabel: PropTypes.string,
  id: PropTypes.string.isRequired,
  buttonIcon: PropTypes.oneOf([
    'email',
    'phone',
    'facebook',
    'instagram',
    'twitter',
    'chevron',
    'appstore',
    'googleplay'
  ]),
  buttonIconColour: PropTypes.oneOf(['red', 'green']),
  marketingId: PropTypes.string,
  styleType: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary'])
    .isRequired
}

export const orderType = PropTypes.oneOf(['outpost', 'collection', 'delivery'])

export const callToActionProps = {
  ...commonCallToActionProps,
  target: PropTypes.string.isRequired,
  href: PropTypes.string,
  slug: PropTypes.string,
  refType: PropTypes.string,
  eventOnClick: PropTypes.string,
  gaClass: PropTypes.string,
  gaDesc: PropTypes.string
}

export const alternateCallToActionProps = [
  PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        identifier: PropTypes.string,
        buttonAriaLabel: PropTypes.string,
        buttonIcon: PropTypes.oneOf([
          'email',
          'phone',
          'facebook',
          'instagram',
          'twitter',
          'chevron',
          'appstore',
          'googleplay'
        ]),
        buttonType: PropTypes.string,
        externalUrl: PropTypes.string,
        openInANewWindow: PropTypes.bool,
        marketingId: PropTypes.string,
        eventOnClick: PropTypes.string
      })
    ).isRequired
  })
]

export const callToAction = PropTypes.shape(callToActionProps)

export const dynamicCallToActionProps = {
  ...commonCallToActionProps,
  action: PropTypes.string.isRequired,
  gaClass: PropTypes.string,
  gaDesc: PropTypes.string
}

export const dynamicCallToAction = PropTypes.shape(dynamicCallToActionProps)

export const richText = PropTypes.shape({
  nodeType: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      nodeType: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          marks: PropTypes.array,
          nodeType: PropTypes.string.isRequired,
          value: PropTypes.string
        })
      ).isRequired
    })
  ).isRequired
})

export const image = PropTypes.shape({
  alt: PropTypes.string,
  height: PropTypes.number,
  src: PropTypes.string,
  width: PropTypes.number
})

export const navigationItemShape = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.shape({
    active: PropTypes.bool,
    openInANewWindow: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string.isRequired
  })
}

export const navigationItem = PropTypes.shape({
  ...navigationItemShape,
  subMenu: PropTypes.arrayOf(PropTypes.shape(navigationItemShape))
})

export const navigation = PropTypes.shape({
  header: PropTypes.arrayOf(navigationItem).isRequired,
  footer: PropTypes.arrayOf(navigationItem).isRequired
})

export const page = PropTypes.shape({
  title: PropTypes.string,
  slots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      contentType: PropTypes.string.isRequired
    })
  ).isRequired,
  body: richText,
  markdownBody: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  canonicalLink: PropTypes.string
})

export const socialNetworks = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  })
)

const nutrientShape = PropTypes.shape({
  per100g: PropTypes.string,
  perServing: PropTypes.string
})

export const nutritionalDataShape = PropTypes.arrayOf(
  PropTypes.shape({
    Carbohydrates: nutrientShape,
    'Dietary Fibre': nutrientShape,
    'Energy (KCal)': nutrientShape,
    'Energy (KJ)': nutrientShape,
    Fat: nutrientShape,
    Protein: nutrientShape,
    Salt: nutrientShape,
    Saturates: nutrientShape,
    Sodium: nutrientShape,
    Sugars: nutrientShape
  })
)

export const baristaBeverageDetailsShape = PropTypes.shape({
  cupSizes: PropTypes.object
})

export const settings = PropTypes.shape({
  logo: image.isRequired,
  metaTitleSuffix: PropTypes.string.isRequired,
  placeholderImage: image.isRequired,
  fourOhFourImage: image.isRequired,
  oopsImage: image,
  footerLogo: image,
  socialNetworks,
  announcement: PropTypes.object,
  announcementVariant: PropTypes.string
})

export const productShape = {
  allergens: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string
    })
  ),
  canHaveVariants: PropTypes.bool,
  categories: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  description: PropTypes.string,
  id: PropTypes.string,
  image,
  ingredients: PropTypes.string,
  isOnStock: PropTypes.bool,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    as: PropTypes.string.isRequired
  }),
  name: PropTypes.string,
  newUntil: PropTypes.string,
  sku: PropTypes.string,
  unavailableForOrderAhead: PropTypes.bool,
  nutritionals: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string
      })
    )
  ),
  nutritionalData: nutritionalDataShape,
  productType: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string
    }),
    PropTypes.string
  ]),
  suitableForVegans: PropTypes.bool,
  suitableForVegetarians: PropTypes.bool,
  averageWeight: PropTypes.number
}

const productExtras = PropTypes.arrayOf(PropTypes.shape(productShape))

const customisationOptions = PropTypes.shape({
  ...productShape,
  isSelected: PropTypes.bool,
  isDefault: PropTypes.bool,
  label: PropTypes.string
})

export const customisationGroup = PropTypes.shape({
  type: PropTypes.string.isRequired,
  localisedLabel: PropTypes.string,
  multiSelectable: PropTypes.bool,
  subHeading: PropTypes.string,
  options: PropTypes.arrayOf(customisationOptions).isRequired
})

export const product = PropTypes.shape({
  ...productShape,
  customisationOptions: PropTypes.arrayOf(customisationGroup),
  extras: productExtras
})

export const category = PropTypes.shape({
  availableForOrderAhead: PropTypes.bool,
  id: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    as: PropTypes.string.isRequired
  }).isRequired,
  name: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(product),
  slug: PropTypes.string.isRequired
})

export const filterItem = PropTypes.shape({
  id: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    as: PropTypes.string.isRequired
  }).isRequired,
  name: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(product),
  slug: PropTypes.string.isRequired
})

const locale = PropTypes.shape({
  code: PropTypes.string.isRequired
})

export const market = PropTypes.shape({
  id: PropTypes.string.isRequired,
  locales: PropTypes.arrayOf(locale).isRequired,
  link: PropTypes.string.isRequired,
  oneTrustScript: PropTypes.string
})

export const totalPriceProps = PropTypes.shape({
  value: PropTypes.shape({
    centAmount: PropTypes.number.isRequired,
    currencyCode: PropTypes.string.isRequired,
    fractionDigits: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    localisedPrice: PropTypes.string.isRequired
  })
})

export const lineItemProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: totalPriceProps,
  localisedPrice: PropTypes.string,
  productId: PropTypes.string,
  quantity: PropTypes.number.isRequired
})

export const orderDetailsProps = PropTypes.shape({
  orderType: PropTypes.string,
  shop: PropTypes.shape({
    address: PropTypes.string,
    name: PropTypes.string
  }),
  pickupTime: PropTypes.string,
  time: PropTypes.string,
  addCutlery: PropTypes.bool
})

export const bagProps = PropTypes.shape({
  lineItems: PropTypes.arrayOf(lineItemProps),
  totalPrice: totalPriceProps,
  canAddCutlery: PropTypes.bool,
  orderDetails: orderDetailsProps
})

const customerProps = PropTypes.shape({
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired
})

export const orderConfirmationProps = PropTypes.shape({
  orderNumber: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      localisedPrice: PropTypes.string
    })
  ).isRequired,
  customer: customerProps.isRequired,
  fulfillingShopId: PropTypes.string.isRequired,
  pickupTime: PropTypes.string.isRequired,
  totalPrice: totalPriceProps,
  orderDetails: orderDetailsProps,
  csRemoveSignUp: PropTypes.bool
})

export const shopProps = PropTypes.shape({
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired
})

export const orderConfirmationSlotProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  pageLocale: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  images: PropTypes.array,
  displayOrder: PropTypes.number.isRequired
})

export const addressProps = PropTypes.shape({
  streetName: PropTypes.string,
  streetNumber: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string
})

export const storeProps = PropTypes.shape({
  address: addressProps,
  distance: PropTypes.number,
  name: PropTypes.string,
  id: PropTypes.string
})

export const paymentSettings = PropTypes.shape({
  script: PropTypes.shape({
    src: PropTypes.string.isRequired,
    crossorigin: PropTypes.string.isRequired,
    async: PropTypes.bool.isRequired
  }),
  stylesheet: PropTypes.shape({
    url: PropTypes.string.isRequired,
    crossorigin: PropTypes.string.isRequired
  }),
  removedPaymentMethods: PropTypes.arrayOf(PropTypes.string),
  paymentMethodsConfiguration: PropTypes.shape({
    card: PropTypes.shape({
      hasHolderName: PropTypes.bool.isRequired,
      holderNameRequired: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      enableStoreDetails: PropTypes.bool.isRequired,
      hideCVC: PropTypes.bool.isRequired,
      brands: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  }),
  response: PropTypes.shape({
    resultCode: PropTypes.shape({
      authorised: PropTypes.string.isRequired,
      received: PropTypes.string.isRequired,
      refused: PropTypes.string.isRequired,
      error: PropTypes.string.isRequired
    })
  })
})

export const checkoutProps = PropTypes.shape({
  paymentSettings
})

export const commonPageProps = {
  navigation: navigation.isRequired,
  settings: settings.isRequired
}

export const pageProps = PropTypes.shape({
  ...commonPageProps,
  bagInitialValue: bagProps,
  currentFilter: category,
  customPage: PropTypes.bool,
  displayBag: PropTypes.bool,
  displayPickupTime: PropTypes.bool,
  displayUserAccountLink: PropTypes.bool,
  gtmHost: PropTypes.string,
  gtmId: PropTypes.string,
  isYourPret: PropTypes.bool,
  isTransactional: PropTypes.bool,
  locale: PropTypes.string,
  orderDetails: orderDetailsProps,
  page,
  product,
  showOrderSlotBanner: PropTypes.bool,
  showLoyaltyBanner: PropTypes.bool
})

export const timeslotProps = PropTypes.shape({
  slot: PropTypes.shape({
    name: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired
  }).isRequired,
  time: PropTypes.string.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  displayWarning: PropTypes.bool.isRequired
})

export const carouselSlideProps = PropTypes.shape({
  title: PropTypes.string.isRequired,
  slideImage: image.isRequired,
  slideAction: PropTypes.shape(callToActionProps)
})

export const location = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired
})

export const coffeeWizardProps = PropTypes.shape({
  enabled: PropTypes.bool,
  load: PropTypes.func
})

export const coffeeWizardDefaults = {
  enabled: false,
  load: () => {}
}

export const outpostProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  associatedShopId: PropTypes.string.isRequired,
  features: PropTypes.shape({
    breakfastTimeslotsStartTime: PropTypes.string,
    breakfastTimeslotsEndTime: PropTypes.string,
    breakfastCutoffInMinutes: PropTypes.number,
    lunchTimeslotsStartTime: PropTypes.string.isRequired,
    lunchTimeslotsEndTime: PropTypes.string.isRequired,
    lunchCutoffInMinutes: PropTypes.number.isRequired,
    dinnerTimeslotsStartTime: PropTypes.string,
    dinnerTimeslotsEndTime: PropTypes.string,
    dinnerCutoffInMinutes: PropTypes.number
  })
})

export const outpostsProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  channelId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location,
  countryCode: PropTypes.string.isRequired,
  address: addressProps,
  outposts: PropTypes.arrayOf(outpostProps)
})

export const formValueProps = PropTypes.shape({
  id: PropTypes.string,
  dirty: PropTypes.bool,
  isValid: PropTypes.bool,
  value: PropTypes.string
})

export const savedCardProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  lastFour: PropTypes.string.isRequired,
  expiryMonth: PropTypes.string.isRequired,
  expiryYear: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired
})

export const shopFinderRegion = PropTypes.shape({
  title: PropTypes.string.isRequired,
  location: location.isRequired
})

export const paymentBrandsProps = PropTypes.oneOf([
  'visa',
  'mastercard',
  'americanExpress',
  'discover',
  'jcb',
  'dinersClub',
  'other'
])

export const referFriendProps = PropTypes.shape({
  enabled: PropTypes.bool,
  host: PropTypes.string
})

export const featuredProductBanner = PropTypes.shape({
  title: PropTypes.string,
  subTitle: PropTypes.string,
  action: PropTypes.shape({
    identifier: PropTypes.string,
    slug: PropTypes.string,
    buttonLabel: PropTypes.string
  }),
  campaignImageSm: PropTypes.shape({
    src: PropTypes.string
  }),
  campaignImageMd: PropTypes.shape({
    src: PropTypes.string
  }),
  campaignImageLg: PropTypes.shape({
    src: PropTypes.string
  })
})

export const cancelSubscriptionsPageHeadingsProps = PropTypes.shape({
  currentTermEnd: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  subscriptionStatus: PropTypes.string.isRequired,
  isTotalPriceZero: PropTypes.bool,
  clubPret: PropTypes.bool
})
