import {
  fallbackLocaleCode,
  routeDefinitionsCommon,
  routeDefinitionsCollection
} from '#constants'
import { getCollectionPath } from '#src/common/lib/get-collection-path'

const getPath = (slug, locale = fallbackLocaleCode) => {
  let path

  const collectionPath = getCollectionPath(locale)
  const routesCommon = routeDefinitionsCommon
  const routesCollection = routeDefinitionsCollection.map(curr => ({
    prefix: `/${collectionPath}${curr.prefix}`,
    path: `/${collectionPath}${curr.path}`
  }))
  const routes = [...routesCommon, ...routesCollection]

  for (let i = 0; i < routes.length; i++) {
    if (slug === routes[i].prefix) {
      path = routes[i].path
      break
    }
    if (slug.startsWith(routes[i].prefix)) {
      path = routes[i].path
    }
  }

  return path
}

export default getPath
