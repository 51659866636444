import React from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line @next/next/no-img-element
const Image = ({ title, ...props }) => <img alt={title} {...props} />

Image.propTypes = {
  title: PropTypes.string
}

Image.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string
}

export default Image
