// TODO move to Component-Library https://pretamanger.atlassian.net/browse/CS-689

import PropTypes from 'prop-types'
import { getColours } from '@pretamanger/component-library'
const colours = getColours()

const PaperCup = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 253.27 366.29'
      {...props}
    >
      <title>Cup</title>
      <polygon
        fill={props.colour}
        points='104.48 212.28 105 210.72 112.42 187.67 93.62 174.65 92.07 173.58 93.96 173.58 117.48 173.5 126.06 148.43 126.61 146.82 127.17 148.42 135.91 173.44 159.33 173.37 161.2 173.36 159.67 174.44 141.04 187.62 148.46 210.51 148.97 212.07 147.57 211.18 126.71 197.89 105.88 211.37 104.48 212.28'
      />

      <path
        d='M311.22,49c0-6.92-4.16-16.1-4.16-16.1l-10.21-2.53L282.66,2.33,89.43,4.46,75.84,30,61.3,38.29s-5.39,13.16-.66,23.88H61c21.07,2.74,83.08.29,129.21.14,82.68-.26,117.32.18,117.32.18,6.87,1.24,3.7-6.58,3.68-13.47'
        transform='translate(-58.7 -2.33)'
        fill={props.colour}
      />
      <path
        d='M227.55,362H143.11c-15.6,0-31.92-12-33.62-27.93L80.88,64.51C78.9,46,93.07,29.76,111.29,29.76H259.37c18.22,0,32.39,16.2,30.41,34.75L261.17,334.05C259.47,349.94,243.15,362,227.55,362Z'
        transform='translate(-58.7 -2.33)'
        fill='none'
        stroke={props.colour}
        strokeWidth={20}
      />
    </svg>
  )
}

PaperCup.propTypes = {
  colour: PropTypes.string
}

PaperCup.defaultProps = {
  colour: colours['pretRed-700']
}

export default PaperCup
