import { httpClient as fetch } from '#lib/http-client'
import { localeHeader } from '#src/common/constants'
import { RewardCategoryResponse } from '#src/auth/my-account-page/types'
import { RequestExtend } from './types'

export const getRewardCategories = async (
  baseUrl: string,
  locale: string
): Promise<RewardCategoryResponse> => {
  let params: RequestExtend = {
    method: 'GET',
    json: true,
    headers: {
      [localeHeader]: locale
    }
  }

  const response = await fetch(
    `${baseUrl}/v2/categories/reward-categories`,
    params
  )

  let categoryRewards
  let statusOk

  if (response.status === 200) {
    categoryRewards = await response.json()
    statusOk = true
  } else {
    categoryRewards = []
    statusOk = false
  }

  return { categoryRewards, statusOk }
}
