import { httpClient as fetch } from '#lib/http-client'
import { localeHeader } from '#src/common/constants'
import { RequestExtend } from './types'

export const getCategoryProducts = async (
  baseUrl: string,
  locale: string,
  campaignId: number
): Promise<any> => {
  let params: RequestExtend = {
    method: 'GET',
    json: true,
    headers: {
      [localeHeader]: locale
    }
  }

  const response = await fetch(
    `${baseUrl}/v2/products/by-external-category/${campaignId}`,
    params
  )

  let products
  let statusOk

  if (response.status === 200) {
    products = await response.json()
    statusOk = true
  } else {
    products = []
    statusOk = false
  }

  return { products, statusOk }
}
