import { Grid, GridArea, Heading, Image } from '@pretamanger/component-library'
import Translation from '#src/common/components/translation'
import { areas } from './styles'

type FiveHundredPageProps = {
  oopsImage: {
    src: string
    alt: string
  }
}

const FiveHundredPage = ({ oopsImage }: FiveHundredPageProps) => {
  const oopsImageBaseUrl = oopsImage.src
  const oopsImageUrl = `${oopsImageBaseUrl}?fm=jpg&fl=progressive`
  const oopsImageSources = {
    xs: `${oopsImageUrl}&w=328`,
    sm: `${oopsImageUrl}&w=394`,
    md: `${oopsImageUrl}&w=480`,
    lg: `${oopsImageUrl}&w=394`,
    xl: `${oopsImageUrl}&w=499`,
    '2xl': `${oopsImageUrl}&w=499`
  }
  const oopsImageFallback = `${oopsImageUrl}?w=499`

  return (
    <Grid className='my-16' areas={areas}>
      <GridArea name='copy'>
        <Heading className='mb-4' level='h1' styleOverride='headingSm'>
          <Translation id='errors.500.title' />
        </Heading>
        <div className='mb-6'>
          <Translation id='errors.500.copy' />
        </div>
      </GridArea>
      <GridArea name='image' className='xs:my-12'>
        <Image
          alt={oopsImage.alt || '500'}
          sources={oopsImageSources}
          img={oopsImageFallback}
        />
      </GridArea>
    </Grid>
  )
}

export default FiveHundredPage
