import { analyticsEventAdapters } from './event-adapters'
export { removeCurrency } from './utils/remove-currency'

const gtmAdapter = (event, data = {}) => {
  let adapter = null

  if (analyticsEventAdapters[event] !== undefined) {
    adapter = analyticsEventAdapters[event]
  }

  return adapter ? adapter(data) : {}
}

export default gtmAdapter
