import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import difference from 'ramda/src/difference'
import { Button, Heading, AriaLive } from '@pretamanger/component-library'
import { useAsync } from 'react-use'
import { bagProps } from '@proptypes'
import Translation, { translate } from '#src/common/components/translation'
import {
  baristaProductType,
  basketAdjudicationFlag,
  coffeeSubscriptionPlanIds
} from '#constants'
import { BodyLock } from '#src/common/components/body-lock'
import BagItems from './bag-items'
import { loginUserAccountVerifySubscription } from '#lib/api/subscription'
import { getTotalNumberOfItems } from './bag-utils'
import getVoucherErrorReasons from '#src/checkout/get-voucher-error-reasons'
import CutleryItem from '#src/common/components/bag/cutlery'

import {
  BagOverlay,
  BagWrapper,
  BagHeading,
  StyledButton,
  BagCross,
  LoadingText,
  BagFooter,
  CheckoutButton,
  BagPanel,
  BagContent,
  BagPriceWrapper,
  BagTotalPrice,
  EmptyBagText,
  SignInButton,
  CutleryContainerWrapper
} from './styles'
import { useFocusTrap } from '#hooks'
import RedemptionError from './redemption-code/error'
import { getUserError } from '#lib/bag-user-error'
import { fireCheckoutEvent } from '#src/common/lib/events/publishers/analytics/fire-events/core'
import { useAppSelector } from '#src/state/redux-hooks'
import { accountSelector } from '#src/state/account-state-slice'
import { getCollectionPath } from '#src/common/lib/get-collection-path'
import { navigateToSignInWithRedirectUrl } from '#src/common/lib/auth0/auth0-functions'
import { clientSideGetLaunchDarklyFlag } from '#src/common/lib/get-launch-darkly-flag'
import { getMarketDetailsFromLocale } from '#src/common/lib/get-market'
import { provideAnonymousId } from '#src/common/lib/provide-anonymous-id'
import { pathOr } from 'ramda'

const Bag = ({
  bag,
  isLoading,
  isOpen,
  onCloseBag,
  onQuantityChange,
  refreshBag,
  removeItems,
  updateCutleryOption
}) => {
  const router = useRouter()
  const { events: routerEvents, push: routerPush, locale } = router
  const collectionPath = getCollectionPath(locale)
  const { user, isAuthenticated, subscriptions } =
    useAppSelector(accountSelector)

  const subscription = pathOr({}, [0], subscriptions)

  const isUSClassic =
    subscription?.planId === coffeeSubscriptionPlanIds.US_CLASSIC_COFFEE_PLAN

  const anonymousId = provideAnonymousId()

  const [bagLoadEventTracked, setBagLoadEventTracked] = useState(false)

  const [isCheckingOut, setIsCheckingOut] = useState(false)
  const bagRef = useFocusTrap()
  const isBagEmpty = !bag.lineItems?.length
  const isOutpostOrder = bag.orderDetails?.orderType === 'outpost'

  const hasUnavailableItems = bag => bag.lineItemsUnavailable?.length > 0
  const hasBaristaProduct = bag =>
    bag?.lineItems?.some?.(item => item.productType.key === baristaProductType)

  useEffect(() => {
    if (isOpen && !isLoading && !bagLoadEventTracked && !isBagEmpty) {
      fireCheckoutEvent({ bag, subscriptions, isAuthenticated, step: 1 })
      setBagLoadEventTracked(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isLoading, isBagEmpty])

  const market = useMemo(() => {
    return getMarketDetailsFromLocale(locale)
  }, [locale])

  const voucherErrorReasons = useMemo(
    () =>
      getVoucherErrorReasons(locale, {
        eeexpiredvoucher: translate(
          'voucher.errorCode.eeExpiredVoucher',
          locale
        )?.value
      }),
    [locale]
  )

  const handleCheckout = async () => {
    setIsCheckingOut(true)
    const showLoading = false
    const refreshedBag = await refreshBag({ showLoading })

    if (hasUnavailableItems(refreshedBag)) {
      setIsCheckingOut(false)
      return
    }

    if (isOutpostOrder) {
      await routerPush(
        `/${collectionPath}/checkout/signin`,
        `/${locale}/${collectionPath}/checkout/signin`
      )
    } else {
      await routerPush(
        `/${collectionPath}/checkout/recommendations`,
        `/${locale}/${collectionPath}/checkout/recommendations`
      )
    }
    setIsCheckingOut(false)
  }

  const isUserLoggedInAndEESubscriptionError = () => {
    const isEESubscriptionError =
      user &&
      bag?.collectionDetails?.subscriptionCode &&
      !bag?.collectionDetails?.eagleEyeTransactionId &&
      !bag?.collectionDetails?.voucherCodeApplied

    return isEESubscriptionError
  }

  const basketAdjudicationFeature = useAsync(() =>
    clientSideGetLaunchDarklyFlag(basketAdjudicationFlag, anonymousId, market)
  )

  useAsync(async () => {
    if (
      isUserLoggedInAndEESubscriptionError() &&
      basketAdjudicationFeature.value === false
    ) {
      await loginUserAccountVerifySubscription(locale, bag?.bagId)
    }
  }, [bag.lineItems, basketAdjudicationFeature])

  useEffect(() => {
    if (isOpen) {
      bagRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    routerEvents.on('routeChangeComplete', () => {
      onCloseBag()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bagRef])

  const bagHasUnavailableItems = hasUnavailableItems(bag)
  // TODO: update bag response model https://pretamanger.atlassian.net/browse/BPS-595
  const lineItems = difference(
    bag.lineItems || [],
    bag.lineItemsUnavailable || []
  )

  const totalNumberOfItems = getTotalNumberOfItems(bag)

  const getUserErrorResult = getUserError(user, bag)
  const hasBaristaProductResult = user && bag && hasBaristaProduct(bag)

  const voucherErrorReasonOnBag =
    user &&
    bag &&
    voucherErrorReasons[
      bag?.collectionDetails?.subscriptionCode?.toLowerCase?.()
    ]

  const determinedVoucherErrorReason =
    voucherErrorReasonOnBag ||
    (isUSClassic ? undefined : voucherErrorReasons.eeerror)

  const userError =
    getUserErrorResult &&
    hasBaristaProductResult &&
    determinedVoucherErrorReason

  return (
    <>
      {isOpen && (
        <BodyLock>
          <BagOverlay data-testid='bag-overlay' onClick={onCloseBag} />
        </BodyLock>
      )}
      <BagWrapper
        data-testid='bag-wrapper'
        tabIndex={-1}
        ref={bagRef}
        isOpened={isOpen}
        aria-expanded={isOpen}
      >
        <BagPanel>
          {isLoading ? (
            <LoadingText>
              <Translation id='bag.loading' />
            </LoadingText>
          ) : (
            <>
              <BagHeading>
                <StyledButton
                  title={translate('bag.close.button', locale)?.value}
                  onClick={onCloseBag}
                >
                  <BagCross colour='grey-700' />
                </StyledButton>
                <span>
                  <Translation id='bag.heading.title' />
                </span>
              </BagHeading>
              <BagContent>
                {bag.canAddCutlery && (
                  <CutleryContainerWrapper>
                    <CutleryItem
                      bag={bag}
                      updateCutleryOption={updateCutleryOption}
                      readOnly={false}
                    />
                  </CutleryContainerWrapper>
                )}
                {bagHasUnavailableItems && (
                  <>
                    <div className='flex items-baseline'>
                      <Heading level='h5'>
                        <Translation
                          id='bag.unavailable.heading'
                          tokens={{
                            unavailable: bag.lineItemsUnavailable?.length
                          }}
                        />
                      </Heading>

                      <Button
                        styleType='tertiary'
                        icon={null}
                        onClick={() => removeItems(bag.lineItemsUnavailable)}
                      >
                        <Translation id='bag.unavailable.heading-remove' />
                      </Button>
                    </div>
                    <BagItems
                      lineItems={bag.lineItemsUnavailable}
                      refreshBag={refreshBag}
                    />
                  </>
                )}
                <Heading
                  data-testid='available-to-order-items'
                  level='h5'
                  styleOverride='heading2XsLight'
                >
                  <Translation
                    id='bag.available.heading'
                    tokens={{ available: totalNumberOfItems }}
                  />
                </Heading>
                {!isBagEmpty ? (
                  <BagItems
                    lineItems={lineItems}
                    onQuantityChange={onQuantityChange}
                    refreshBag={refreshBag}
                    serviceFee={bag.serviceFee}
                  />
                ) : (
                  <EmptyBagText>
                    <Translation id='bag.order.empty' />
                  </EmptyBagText>
                )}
              </BagContent>
              <BagFooter>
                {userError && <RedemptionError errorMessage={userError} />}
                <BagPriceWrapper>
                  {!isBagEmpty && bag.totalPrice?.localisedPrice && (
                    <BagTotalPrice>
                      <div>
                        <span data-testid='total-price-heading'>
                          {
                            translate('bag.order.totalCost', locale, {
                              count: totalNumberOfItems
                            })?.value
                          }
                        </span>
                        <span>
                          {bag.totalPriceOfAvailableItems.localisedPrice}
                        </span>
                      </div>
                    </BagTotalPrice>
                  )}
                </BagPriceWrapper>
                <AriaLive role='alert'>
                  <CheckoutButton
                    activity={isCheckingOut}
                    styleType='primary'
                    data-testid='checkout-button'
                    disabled={isLoading || isBagEmpty || bagHasUnavailableItems}
                    onClick={handleCheckout}
                  >
                    {!isCheckingOut ? (
                      <Translation id='bag.checkout.button' />
                    ) : (
                      <span className='sr-only'>
                        <Translation id='bag.checkout.loading' />
                      </span>
                    )}
                  </CheckoutButton>
                </AriaLive>
                {!isAuthenticated && !isOutpostOrder && (
                  <>
                    <Translation id='bag.checkout.have-coffee-subscription.title' />
                    <SignInButton
                      data-testid='checkout-signin-link'
                      onClick={() => {
                        navigateToSignInWithRedirectUrl({}, router)
                      }}
                    >
                      <Translation id='bag.checkout.have-coffee-subscription.signin' />
                    </SignInButton>
                  </>
                )}
              </BagFooter>
            </>
          )}
        </BagPanel>
      </BagWrapper>
    </>
  )
}

Bag.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCloseBag: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  refreshBag: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired,
  bag: bagProps
}

Bag.defaultProps = {
  bag: {}
}

export default Bag
