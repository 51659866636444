import { httpClient } from '#lib/http-client'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import { anonymousIdHeader } from '#constants'
import { getTokenSilently } from './auth0-functions'
import { jwtDecode } from 'jwt-decode'

export const parseJwtToken = (jwt): any => {
  try {
    return jwtDecode(jwt)
  } catch {
    return null
  }
}

const pickPretId = prop('http://pret.com/pretId')
const getPretIdFromToken = pipe(parseJwtToken, pickPretId)

export const getProfile = async (
  anonymousId,
  authApiBaseUrl,
  defaultToken?: string
) => {
  const accessToken = defaultToken || (await getTokenSilently())
  const response = await httpClient(`${authApiBaseUrl}/v1/auth/profile`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      [anonymousIdHeader]: anonymousId
    }
  })
  const result = await response.json()
  const profile = {
    ...result,
    pretId: getPretIdFromToken(accessToken)
  }
  return profile
}
