import styled from '@emotion/styled'
import { css } from '@emotion/react'
import tw from 'twin.macro'

export const BagItemContainer = styled('li')`
  ${tw`flex flex-col py-5 w-full`}
`

export const BagItemContainerRow = styled('div')`
  ${tw`flex flex-row items-start`}
`

export const UnavailableBagItem = styled(BagItemContainerRow)`
  ${tw`flex-row-reverse mr-4`}
`

export const BagItemTitleQuantityContainer = styled('div')`
  ${props => (props.readOnly ? tw`flex-row` : tw`flex-col`)}
  ${tw`pr-4 flex-grow flex`}
  > * {
    ${tw`float-left`}
  }
`

export const BagItemTitleContainer = styled('div')`
  ${tw`flex flex-col flex-grow`}
`

export const BagItemQuantityContainer = styled.span`
  ${tw`mr-1 sm:mr-2 mt-1`}
`

export const RedemptionCodeDiscountContainer = styled('div')`
  ${tw`mt-1`}
`

export const BagItemTitle = styled('span')`
  ${tw`pl-2`}
`
export const BagItemReadOnlyQuantity = styled('span')`
  ${tw`font-medium text-grey-700`}
`

export const BagItemSubTitle = styled('span')`
  ${tw`text-sm text-grey-500 clear-left pl-2`}
`

export const OriginalBagItemPrice = styled('span')`
  ${tw`line-through text-grey-400`}
`

export const TotalBagItemPrice = styled('span')`
  ${props =>
    css`
      ${props.discount && tw`pl-3 text-grey-700`}
    `}
  ${tw`pl-5`}
`
