export const getBrowserEnvironment = () => {
  if (typeof window === 'undefined') {
    return
  }

  const { host } = window.location
  if (host.includes('localhost')) {
    return 'development'
  }
  if (host.includes('lab')) {
    return 'lab'
  }
  return 'production'
}
