import {
  frCoffeeSubCheckoutRegEx,
  ukClickCollectCheckoutRegEx,
  ukCoffeeSubCheckoutRegEx,
  ukGiftingCheckoutRegEx,
  usClickCollectCheckoutRegEx,
  usCoffeeSubLandingPageRegEx,
  usCoffeeSubCheckoutRegEx,
  usCoffeeSubSignupRegEx
} from './utils/regex-paths'
import { coffeeSubscriptionPlanIds } from '#src/common/constants'
import {
  DEFAULT_VALUE_TBC,
  PRODUCT_BRAND,
  PRODUCT_CATEGORY_COFFEE_SUBSCRIPTION,
  PRODUCT_INAPPROPRIATE_ITEMS,
  PRODUCT_SUBSCRIPTION_NAME_US,
  PRODUCT_VARIANT_COFFEE_SUBSCRIPTION
} from '../../constants'

export const appendDimension11 = (id, transformedProduct = {}) => {
  const dimension11 = generateDimension11(id)

  return dimension11
    ? { ...transformedProduct, dimension11 }
    : transformedProduct
}

export const generateDimension11 = skuOrId => {
  if (skuOrId === coffeeSubscriptionPlanIds.US_CLASSIC_COFFEE_PLAN) {
    return 'Classic'
  }
  if (skuOrId === coffeeSubscriptionPlanIds.US_PREMIUM_COFFEE_PLAN) {
    return 'Premium'
  }
}

export const removeInappropriatePropductItems = (products = []) =>
  products.reduce((acc, product) => {
    const appropriateProduct = { ...product }
    PRODUCT_INAPPROPRIATE_ITEMS.forEach(item => {
      if (typeof appropriateProduct[item] !== 'undefined') {
        delete appropriateProduct[item]
      }
    })
    acc.push(appropriateProduct)

    return acc
  }, [])

export const shouldCheckoutContainTransformedProductsAndActionFields =
  pathname =>
    frCoffeeSubCheckoutRegEx.test(pathname) ||
    ukClickCollectCheckoutRegEx.test(pathname) ||
    ukCoffeeSubCheckoutRegEx.test(pathname) ||
    ukGiftingCheckoutRegEx.test(pathname) ||
    usClickCollectCheckoutRegEx.test(pathname) ||
    usCoffeeSubLandingPageRegEx.test(pathname) ||
    usCoffeeSubCheckoutRegEx.test(pathname) ||
    usCoffeeSubSignupRegEx.test(pathname)

export const transformActionField = ({ ...actionField }) => {
  if (typeof actionField.option !== 'undefined') {
    delete actionField.option
  }

  return actionField
}

export const transformProductBrand = (brand = '') =>
  brand === '' ? PRODUCT_BRAND : brand

export const transformProductCategory = (category = '') =>
  category === '' || category === DEFAULT_VALUE_TBC
    ? PRODUCT_CATEGORY_COFFEE_SUBSCRIPTION
    : category

export const transformProductPurchaseName = (productPurchaseName, skuOrId) => {
  if (
    skuOrId === coffeeSubscriptionPlanIds.US_CLASSIC_COFFEE_PLAN ||
    skuOrId === coffeeSubscriptionPlanIds.US_PREMIUM_COFFEE_PLAN
  ) {
    return PRODUCT_SUBSCRIPTION_NAME_US
  }

  return productPurchaseName
}

const transformProductsReducer = (acc = [], product) => {
  const { brand, category, id, name, variant } = product
  const transformedProduct = {
    ...product,
    brand: transformProductBrand(brand),
    category: transformProductCategory(category),
    name: transformProductPurchaseName(name, id),
    variant: transformProductVariant(variant)
  }

  return [...acc, appendDimension11(id, transformedProduct)]
}

export const transformProducts = products => {
  const transformedProducts = products.reduce(transformProductsReducer, [])

  return removeInappropriatePropductItems(transformedProducts)
}

export const transformProductVariant = (variant = '') =>
  variant === '' || variant === DEFAULT_VALUE_TBC
    ? PRODUCT_VARIANT_COFFEE_SUBSCRIPTION
    : variant
