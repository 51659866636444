import { useTheme } from '@pretamanger/component-library'
import { navigationItemShape } from '@proptypes'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  ExpandableItem,
  ExpandableItemTitle,
  SecondLevelGroup,
  ToggleIcon
} from './styles'

const Submenu = ({ id, items, title, showEmployeePrivacyPolicy }) => {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setOpen(false)
  }, [])

  const toggle = e => {
    e && e.preventDefault()
    setOpen(!open)
  }

  const onClick = e => {
    e.stopPropagation()
    toggle()
  }

  const { theme } = useTheme()

  return (
    <ExpandableItem
      onClick={toggle}
      aria-expanded={open}
      aria-controls={`${id}-submenu`}
      id={`${id}-link`}
    >
      <ExpandableItemTitle theme={theme}>
        {title}
        <ToggleIcon type={open ? 'up' : 'down'} />
      </ExpandableItemTitle>
      <SecondLevelGroup
        items={items}
        open={open}
        id={`${id}-submenu`}
        aria-labelledby={`${id}-link`}
        onClick={onClick}
        showEmployeePrivacyPolicy={showEmployeePrivacyPolicy}
      />
    </ExpandableItem>
  )
}

Submenu.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(navigationItemShape)).isRequired
}

export default Submenu
