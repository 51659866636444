import { FEATURE_CLICK } from '../../../../topics'

export const featureClickAdapter = ({ elements }) => {
  const gtmObj = {
    ecommerce: {},
    elements,
    event: FEATURE_CLICK
  }

  return gtmObj
}
