import { mapLocale } from '#lib/locale'
import settings from '#src/../settings'
import { getLogger } from '#api/logger-with-context'

export const getMappedSettings = locale => {
  const log = getLogger()
  const contentfulLocale = mapLocale(locale, true)
  const settingsForLocale = settings[contentfulLocale]
  if (!settingsForLocale) {
    log.error(
      { contentfulLocale },
      'Get locale settings:: error retrieving locale settings'
    )
  }
  const socialNetworks = [
    settingsForLocale.facebookLink && {
      name: 'facebook',
      link: settingsForLocale.facebookLink
    },
    settingsForLocale.twitterLink && {
      name: 'twitter',
      link: settingsForLocale.twitterLink
    },
    settingsForLocale.instagramLink && {
      name: 'instagram',
      link: settingsForLocale.instagramLink
    }
  ].filter(x => x)

  return {
    ...settingsForLocale,
    logo: settingsForLocale.headerLogo,
    placeholderImage: settingsForLocale.globalPlaceholderImage,
    socialNetworks
  }
}
