import PropTypes from 'prop-types'
import { BlockQuote as Bq, QuoteIcon } from './styles'

const BlockQuote = ({ children }) => (
  <Bq>
    <QuoteIcon /> {children}
  </Bq>
)

BlockQuote.propTypes = {
  children: PropTypes.node.isRequired
}

export default BlockQuote
