import { TRANSACTIONAL_PRODUCT_DETAILS } from '../../../../topics'
import { removeCurrency } from '../utils/remove-currency'

export const transactionalProductDetailsAdapter = ({
  categories,
  product: { name, sku, price, categories: productSubCategories = [] }
}) => {
  const gtmObj = {
    event: TRANSACTIONAL_PRODUCT_DETAILS,
    ecommerce: {}
  }
  const category =
    categories.find(({ subcategories }) =>
      subcategories.some(({ id }) => id === productSubCategories[0]?.id)
    ) || {}
  const productSubCategoryName = productSubCategories[0]?.name
  gtmObj.ecommerce = {
    detail: {
      actionField: { list: productSubCategoryName },
      products: [
        {
          name,
          id: sku,
          price: removeCurrency(price.localisedPrice),
          category: category.name,
          variant: productSubCategoryName,
          brand: 'Pret'
        }
      ]
    }
  }

  return gtmObj
}
