import {
  collectionPaths,
  enGBCollectionURLPrefix,
  enUSCollectionURLPrefix,
  marketDetails,
  signInSubscriptionRedirectPath,
  subscriptionCheckoutPath,
  usCoffeeSubsLandingPagePath,
  orderConfirmationPath,
  frCoffeeSubsLandingPagePath,
  subscriptionGiftCheckoutPath
} from '#src/common/constants'

const frLocale = marketDetails.FR.locale
const ukLocale = marketDetails.UK.locale
const usLocale = marketDetails.US.locale

export const frCoffeeSubCheckoutRegEx = new RegExp(
  `^/${frLocale}/${subscriptionCheckoutPath}$`
)
export const frCoffeeSubLandingPageRegEx = new RegExp(
  `^/${frLocale}/${frCoffeeSubsLandingPagePath}$`
)
export const frCoffeeSubSignupRegEx = new RegExp(
  `^/${frLocale}/${signInSubscriptionRedirectPath}$`
)
// The suffix below, [a-z0-9]+, is a simplified template for a purchase UUID.
export const frCoffeeSubThankYouRegEx = new RegExp(
  `^/${frLocale}/${orderConfirmationPath}/[a-z0-9]+`
)
export const ukClickCollectCheckoutRegEx = new RegExp(
  `^/${ukLocale}/${enGBCollectionURLPrefix}/${collectionPaths.checkout}$`
)
export const ukCoffeeSubCheckoutRegEx = new RegExp(
  `^/${ukLocale}/${subscriptionCheckoutPath}$`
)
export const ukGiftingCheckoutRegEx = new RegExp(
  `^/${ukLocale}/${subscriptionGiftCheckoutPath}$`
)
export const usClickCollectCheckoutRegEx = new RegExp(
  `^/${usLocale}/${enUSCollectionURLPrefix}/${collectionPaths.checkout}$`
)
export const usCoffeeSubCheckoutRegEx = new RegExp(
  `^/${usLocale}/${subscriptionCheckoutPath}$`
)
export const usCoffeeSubLandingPageRegEx = new RegExp(
  `^/${usLocale}/${usCoffeeSubsLandingPagePath}$`
)
export const usCoffeeSubSignupRegEx = new RegExp(
  `^/${usLocale}/${signInSubscriptionRedirectPath}$`
)
// The suffix below, [a-z0-9]+, is a simplified template for a purchase UUID.
export const usCoffeeSubThankYouRegEx = new RegExp(
  `^/${usLocale}/${orderConfirmationPath}/[a-z0-9]+`
)
