import { localeHeader } from '#constants'
import platformClient from '#src/common/lib/platform-client'
import { getLogger } from '#api/logger-with-context'

const logRef = 'Order API - Platform-API::'

export const getOrderById = async (locale, orderId, ctRemovalFlag) => {
  const log = getLogger()
  log.info({ orderId }, `${logRef} start getOrderById`)
  const order = await platformClient(`/order/${orderId}`, {
    version: ctRemovalFlag ? 'v3' : 'v1',
    method: 'GET',
    headers: {
      [localeHeader]: locale
    }
  })
  log.info({ orderId }, `${logRef} end getOrderById`)
  return order
}

export const getOutpostById = async (outpostId, locale) => {
  const log = getLogger()
  log.info({ outpostId }, `${logRef} start getOutpostById`)
  const outpost = await platformClient(`/outposts/${outpostId}`, {
    method: 'GET',
    headers: {
      [localeHeader]: locale
    }
  })
  log.info({ outpostId }, `${logRef} end getOutpostById`)
  return outpost
}
