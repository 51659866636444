import range from 'ramda/src/range'
import Translation, { translate } from '#src/common/components/translation'
import { totalPriceProps } from '@proptypes'
import { getAttributeTranslationId } from '#src/coffee-wizard/constants'

export const getAdditionalAttributes = (item, locale) => {
  const lineItemAttributes = []
  item.attributes?.decaf &&
    lineItemAttributes.push(
      translate(getAttributeTranslationId(item.attributes?.decaf), locale)
        ?.value
    )
  item.attributes?.milkType &&
    lineItemAttributes.push(
      translate(getAttributeTranslationId(item.attributes?.milkType), locale)
        ?.value
    )
  item.attributes?.cupSize &&
    lineItemAttributes.push(
      translate(getAttributeTranslationId(item.attributes?.cupSize), locale)
        ?.value
    )
  const mapSubLineItemLabels = subLineItem => {
    const subLineItemName = subLineItem.label
      ? translate(subLineItem.label, locale)?.value
      : subLineItem.name
    return subLineItem.quantity > 1
      ? translate('bag.subline.item.quantity', locale, {
          quantity: subLineItem.quantity,
          subLineItem: subLineItemName
        }).value
      : subLineItemName
  }
  const subLineItemsAttributes = item.subLineItems?.length
    ? item.subLineItems.map(mapSubLineItemLabels)
    : []
  return [...lineItemAttributes, ...subLineItemsAttributes]
}

export const renderAdditionalAttributesString = additionalAttributes => {
  return additionalAttributes.reduce(
    (additionalAttributesStr, attribute) =>
      additionalAttributesStr.length
        ? `${additionalAttributesStr}, ${attribute}`
        : attribute,
    ''
  )
}

export const isItemAvailable = ({ availability }) =>
  !availability || availability?.isOnStock

export const getTotalNumberOfItems = bag =>
  bag.lineItems?.reduce(
    (a, c) => (isItemAvailable(c) ? a + c.quantity : a),
    0
  ) || 0

export const renderTotalPriceLabel = (price, locale) =>
  price?.centAmount === 0
    ? translate('bag.item.free', locale)?.value
    : price.localisedPrice

export const RenderTotalPriceLabel = ({ price }) => {
  return price?.centAmount === 0 ? (
    <Translation id='bag.item.free' />
  ) : (
    price?.localisedPrice || ''
  )
}

RenderTotalPriceLabel.propTypes = {
  price: totalPriceProps.isRequired
}

export const quantityOptions = (id, locale, quantity) => {
  const MAX = Math.max(quantity, 10)
  return range(0, MAX + 1).map(i => ({
    id: `${id}-qty-option-${i}`,
    label: i ? String(i) : translate('bag.item.remove', locale)?.value,
    value: String(i)
  }))
}
