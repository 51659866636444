import { fireFeatureClickEvent } from '../core'
import { marketDetails, risingStarsPath } from '#constants'
import { PRET_UK_DOMAIN_WITH_SCHEME } from '../../../../subscribers/analytics/constants'

export const risingStarsDonateEvent = () => {
  fireFeatureClickEvent({
    type: 'option_button',
    name: 'donate_to_pret_foundation',
    destination: `${PRET_UK_DOMAIN_WITH_SCHEME}/${marketDetails.UK.locale}/${risingStarsPath}`
  })
}
