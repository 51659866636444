/* eslint-disable camelcase */
import {
  coffeeSubscriptionPlanIds,
  localeToCurrencyMap
} from '#src/common/constants'
import { ADD_TO_CART } from '#src/common/lib/events/topics'
import { capitalize } from '#src/common/lib/string'

export const getCoffeeSubAddToCartPayload = (
  currencyCode = localeToCurrencyMap['en-GB'],
  sku = coffeeSubscriptionPlanIds.UK_COFFEE_PLAN,
  price = 12.5
) => {
  const plan = capitalize(sku?.split('-')[1] || '')
  return {
    event: ADD_TO_CART,
    ecommerce: {
      currency: currencyCode,
      value: price,
      items: [
        {
          item_id: sku,
          item_name: 'Coffee Subscription',
          affiliation: 'Online Store',
          item_brand: 'Pret',
          item_category: 'Subscriptions',
          item_variant: sku.includes('us') ? plan : 'Classic',
          price,
          quantity: 1
        }
      ]
    }
  }
}

export const getCoffeeSubUSClassicAddToCartPayload = () =>
  getCoffeeSubAddToCartPayload(
    'USD',
    coffeeSubscriptionPlanIds.US_CLASSIC_COFFEE_PLAN,
    24.99
  )

export const getCoffeeSubUSPremiumAddToCartPayload = () =>
  getCoffeeSubAddToCartPayload(
    'USD',
    coffeeSubscriptionPlanIds.US_PREMIUM_COFFEE_PLAN,
    34.99
  )
