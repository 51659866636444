import styled from '@emotion/styled'
import tw, { css } from 'twin.macro'
import LocaleLink from '#src/common/components/locale-link'

const variants = {
  teal: css`
    ${tw`bg-teal`}
    * {
      ${tw`text-white`}
    }
    a {
      ${tw`border-white`}
    }
  `,
  eggBlue: css`
    ${tw`bg-eggBlue`}
    * {
      ${tw`text-black`}
    }
    a {
      ${tw`border-black`}
    }
  `,
  grey: css`
    ${tw`bg-grey-700`}
    * {
      ${tw`text-white`}
    }
    a {
      ${tw`border-white`}
    }
  `,
  red: css`
    ${tw`bg-pretRed-700`}
    * {
      ${tw`text-white`}
    }
    a {
      ${tw`border-white`}
    }
  `,
  yellowGreen: css`
    ${tw`bg-yellowGreen`}
    * {
      ${tw`text-black`}
    }
    a {
      ${tw`border-black`}
    }
  `
}

export const Inner = styled.p``

export const Text = styled.span`
  ${tw`inline-block mr-3`}
`

export const Title = styled.em`
  ${tw`font-semibold leading-tight sm:leading-normal not-italic block sm:inline mr-3`}
`

export const IconContainer = styled.span`
  &:not(:empty) {
    ${tw`mt-1 md:mt-0 mr-2`}
  }
`

export const CallToAction = styled(LocaleLink)`
  ${tw`relative whitespace-nowrap`}
`

export const InPage = styled.div`
  ${tw`flex mb-5 p-4 md:px-8 space-x-4 md:space-x-5 lg:space-x-6`}
  * {
    ${tw`text-white`}
  }
  ${props => variants[props.variant] || variants['grey']}
  > span {
    &:not(:empty) {
      ${tw`m-0 md:mt-2`}
    }
  }
  > p {
    ${tw`flex flex-col`}
  }
  em,
  svg {
    ${tw`text-2xl sm:text-3xl`}
  }
  em {
    ${tw`leading-tight font-medium`}
  }
  a {
    --shadowcolor: 160, 160, 160;
    ${tw`
      m-0 mt-3 px-4 py-1 self-start
      border rounded
      no-underline text-sm
    `}
    &::after {
      display: none;
    }
    &:hover,
    &:active {
      box-shadow: inset 0 0 4rem rgba(var(--shadowcolor), 0.5) !important;
    }
    &:active {
      --shadowcolor: 120, 120, 120;
    }
  }
`
