import { mapLocale, normaliseLocale } from '../lib/locale'
import locales from '#src/../locales'
import { fallbackLocaleCode } from '../constants'

const getCurrentLocale = url => {
  const segments = url.split('/')
  const currentLocale = segments[1]
  const mappedCurrentLocale = mapLocale(currentLocale, true)
  return mappedCurrentLocale
}

export const getLocaleFromUrl = url => {
  if (!url) {
    return fallbackLocaleCode
  }

  const normalisedUrl = url.replace(/http:\/\/|https:\/\/|\/\//gi, '')
  const locale = normalisedUrl.split('/')[1]
  const normalisedLocale = normaliseLocale(locale)
  const hasValidLocale = locales.some(
    locale => mapLocale(locale.code) === normalisedLocale
  )

  return hasValidLocale ? normalisedLocale : fallbackLocaleCode
}

export default getCurrentLocale
