import invertObj from 'ramda/src/invertObj'

export const milkOptions = Object.freeze({
  SEMI_SKIMMED: 'SEMI_SKIMMED',
  OAT: 'OAT',
  SKIMMED: 'SKIMMED',
  RICE_COCONUT: 'RICE_COCONUT',
  NO_MILK: 'NO_MILK',
  SOYA: 'SOYA',
  ALMOND: 'ALMOND',
  HALF_AND_HALF: 'HALF_AND_HALF',
  WHOLE_MILK: 'WHOLE_MILK'
})

export const mapCanMilkOptions = Object.freeze({
  milkCanBeSemiSkimmed: milkOptions.SEMI_SKIMMED,
  milkCanBeOat: milkOptions.OAT,
  milkCanBeRiceCoconut: milkOptions.RICE_COCONUT,
  milkCanBeSkimmed: milkOptions.SKIMMED,
  canBeBlack: milkOptions.NO_MILK,
  milkCanBeSoya: milkOptions.SOYA,
  milkCanBeAlmond: milkOptions.ALMOND,
  milkCanBeWhole: milkOptions.WHOLE_MILK,
  milkCanBeHalfAndHalf: milkOptions.HALF_AND_HALF
})

export const mapIsMilkOptions = Object.freeze({
  milkIsSemiSkimmed: milkOptions.SEMI_SKIMMED,
  milkIsOat: milkOptions.OAT,
  milkIsRiceCoconut: milkOptions.RICE_COCONUT,
  milkIsSkimmed: milkOptions.SKIMMED,
  isBlack: milkOptions.NO_MILK,
  milkIsSoya: milkOptions.SOYA,
  milkIsHalfAndHalf: milkOptions.HALF_AND_HALF,
  milkIsWhole: milkOptions.WHOLE_MILK,
  milkIsAlmond: milkOptions.ALMOND
})

export const caffeineOptions = Object.freeze({
  DECAF: 'DECAF',
  DECAF_POD: 'DECAF_POD',
  CAFFEINATED: 'CAFFEINATED',
  HALF_CAF: 'HALF_CAF'
})

export const mapIsCaffeineOptions = Object.freeze({
  isDecaf: caffeineOptions.DECAF,
  isDecafPod: caffeineOptions.DECAF_POD,
  isHalfCaf: caffeineOptions.HALF_CAF,
  caffeine: caffeineOptions.CAFFEINATED
})

export const mapCanCaffeineOptions = Object.freeze({
  canBeDecaf: caffeineOptions.DECAF,
  canBeDecafPod: caffeineOptions.DECAF_POD,
  canBeCaf: caffeineOptions.CAFFEINATED,
  canBeHalfCaf: caffeineOptions.HALF_CAF
})

export const cupSizeOptions = Object.freeze({
  '2OZ': '2OZ',
  '4OZ': '4OZ',
  '8OZ': '8OZ',
  '12OZ': '12OZ',
  '16OZ': '16OZ',
  '20OZ': '20OZ',
  '24OZ': '24OZ',
  NONE: 'NONE'
})

export const mapIsCupSizeOptions = Object.freeze({
  is2OZ: cupSizeOptions['2OZ'],
  is4OZ: cupSizeOptions['4OZ'],
  is8OZ: cupSizeOptions['8OZ'],
  is12OZ: cupSizeOptions['12OZ'],
  is16OZ: cupSizeOptions['16OZ'],
  is20OZ: cupSizeOptions['20OZ'],
  is24OZ: cupSizeOptions['24OZ']
})

export const mapCanCupSizeOptions = Object.freeze({
  canHave2OZ: cupSizeOptions['2OZ'],
  canHave4OZ: cupSizeOptions['4OZ'],
  canHave8OZ: cupSizeOptions['8OZ'],
  canHave12OZ: cupSizeOptions['12OZ'],
  canHave16OZ: cupSizeOptions['16OZ'],
  canHave20OZ: cupSizeOptions['20OZ'],
  canHave24OZ: cupSizeOptions['24OZ'],
  NONE: cupSizeOptions.NONE
})

export const getAttributeTranslationId = key => {
  const canAttributes = {
    ...mapCanCaffeineOptions,
    ...mapCanMilkOptions,
    ...mapCanCupSizeOptions
  }
  return mapAttributesToTranslationId[invertObj(canAttributes)[key]]
}

export const mapAttributesToTranslationId = Object.freeze({
  canBeDecaf: 'coffee.wizard.caffeine.decaf',
  canBeDecafPod: 'coffee.wizard.caffeine.decaf',
  canBeCaf: 'coffee.wizard.caffeine.caf',
  canBeHalfCaf: 'coffee.wizard.caffeine.canBeHalfCaf',
  milkCanBeSemiSkimmed: 'coffee.wizard.milkOptions.semiskimmed',
  milkCanBeOat: 'coffee.wizard.milkOptions.oat',
  milkCanBeSkimmed: 'coffee.wizard.milkOptions.skimmed',
  milkCanBeHalfAndHalf: 'coffee.wizard.milkOptions.milkCanBeHalfAndHalf',
  milkCanBeWhole: 'coffee.wizard.milkOptions.milkCanBeWhole',
  milkCanBeAlmond: 'coffee.wizard.milkOptions.milkCanBeAlmond',
  milkCanBeRiceCoconut: 'coffee.wizard.milkOptions.ricecoconut',
  canBeBlack: 'coffee.wizard.milkOptions.black',
  milkCanBeSoya: 'coffee.wizard.milkOptions.soya',
  canAddExtraShot: 'coffee.wizard.extras.makemestrong',
  canAddWhippedCream: 'coffee.wizard.extras.whippedcream',
  canAddLemonWedge: 'coffee.wizard.extras.lemonwedge',
  canHave2OZ: 'coffee.wizard.cupSizes.canHave2OZ',
  canHave4OZ: 'coffee.wizard.cupSizes.canHave4OZ',
  canHave8OZ: 'coffee.wizard.cupSizes.canHave8OZ',
  canHave12OZ: 'coffee.wizard.cupSizes.canHave12OZ',
  canHave16OZ: 'coffee.wizard.cupSizes.canHave16OZ',
  canHave20OZ: 'coffee.wizard.cupSizes.canHave20OZ',
  canHave24OZ: 'coffee.wizard.cupSizes.canHave24OZ'
})

export const caffeineDisplayOrder = {
  'en-GB': ['canBeCaf', 'canBeDecaf', 'canBeHalfCaf'],
  default: ['canBeCaf', 'canBeHalfCaf', 'canBeDecaf']
}

export const milkOptionsDisplayOrder = Object.freeze({
  'en-GB': [
    'milkCanBeSemiSkimmed',
    'milkCanBeSkimmed',
    'milkCanBeOat',
    'milkCanBeSoya',
    'milkCanBeRiceCoconut',
    'canBeBlack',
    'milkCanBeWhole',
    'milkCanBeHalfAndHalf',
    'milkCanBeAlmond'
  ],
  'en-US': [
    'milkCanBeSkimmed',
    'milkCanBeWhole',
    'milkCanBeHalfAndHalf',
    'milkCanBeAlmond',
    'milkCanBeOat',
    'canBeBlack',
    'milkCanBeSemiSkimmed',
    'milkCanBeSoya',
    'milkCanBeRiceCoconut'
  ],
  default: [
    'milkCanBeSemiSkimmed',
    'milkCanBeSkimmed',
    'milkCanBeWhole',
    'milkCanBeHalfAndHalf',
    'milkCanBeOat',
    'milkCanBeSoya',
    'milkCanBeAlmond',
    'milkCanBeRiceCoconut',
    'canBeBlack'
  ]
})

export const groupTypes = Object.freeze({
  CUP_SIZES: 'cup-sizes',
  CAFFEINE: 'caffeine-options',
  MILK_OPTIONS: 'milk-options',
  QUANTITY: 'quantity',
  EXTRA_OPTIONS: 'extras-options',
  FLAVOUR_OPTIONS: 'flavour-options',
  TOPPINGS: 'toppings'
})
