import { fireEvent } from '#src/common/lib/events'
import {
  FEATURE_CLICK,
  CHECKOUT,
  BEGIN_CHECKOUT
} from '#src/common/lib/events/topics'
import { getIsSubscriptionBeingRedeemed } from '../../../subscribers/analytics/gtm-adapter/data-transformers/core'
import { getFormattedOrderDetails } from '#src/common/app/components/order-slot-banner'
import { capitalize } from '#src/common/lib/string'
import pathOr from 'ramda/src/pathOr'
import { getLineItemsTransformer } from './utils/get-line-items-transformer'
import { CheckoutEvent, FeatureClick } from './types'
import clickEventsModule from './click-events'
import { mapSubscriptionSignupEvent } from '../../../subscribers/analytics/gtm-adapter/utils/map-subscription-signup'

export const fireFeatureClickEvent = ({
  type = 'button',
  name,
  destination,
  position = 'top'
}: FeatureClick) => {
  fireEvent(FEATURE_CLICK, {
    elements: {
      type,
      name,
      destination,
      position
    }
  })
}

export const fireCheckoutEvent = ({
  bag,
  subscriptions,
  isAuthenticated,
  step,
  actionFieldOption,
  isGifting,
  checkoutSub,
  state
}: CheckoutEvent) => {
  const { time, shop } = getFormattedOrderDetails(bag?.orderDetails || {}, true)
  const transformedLineItems = getLineItemsTransformer(isGifting)(
    bag?.lineItems
  )
  const isSubscriptionBeingRedeemed =
    getIsSubscriptionBeingRedeemed(transformedLineItems)

  const subscription = pathOr({ id: '' }, [0], subscriptions)
  const cbeeId = subscription.id
  const chargebeeSubscriptionId = isSubscriptionBeingRedeemed ? cbeeId : ''

  if (checkoutSub && step === 3) {
    fireEvent(
      BEGIN_CHECKOUT,
      mapSubscriptionSignupEvent(BEGIN_CHECKOUT, bag, state)
    )
  } else {
    fireEvent(CHECKOUT, {
      pretEcommerce: {
        currencyCode: bag.totalPrice.currencyCode,
        storeLocation: shop.name,
        storeCity: shop.city,
        coffeeSubscription: Boolean(bag.collectionDetails?.subscriptionCode),
        checkoutType: isAuthenticated ? 'customer' : 'guest',
        pickupTime: time,
        chargebeeSubscriptionId
      },
      ecommerce: {
        checkout: {
          actionField: {
            step,
            ...(actionFieldOption && { option: actionFieldOption })
          },
          products: transformedLineItems.map(p => ({
            ...p,
            brand: capitalize(bag?.orderDetails?.fulfillingShopType || '')
          }))
        }
      }
    })
  }
}

export const clickEvents = clickEventsModule
