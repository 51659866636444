import PropTypes from 'prop-types'
import { Button, Heading, Modal } from '@pretamanger/component-library'
import Translation, { translate } from '#src/common/components/translation'
import {
  ButtonWrapper,
  LargeClock,
  ModalContainer,
  TextWrapper
} from '../cutoff-notification-modal/styles'
import { useRouter } from 'next/router'

const CutoffExpiryModal = ({ slotTime, handleSubmit }) => {
  const { locale } = useRouter()
  const translatedText = translate('outpost.expiry.modal.content', locale, {
    slotTime: slotTime
  }).value

  return (
    <ModalContainer data-testid='outpost-expiry-modal'>
      <Modal open showCloseButton={false} closeOnEscape={false} size='narrow'>
        <LargeClock colour='pretRed-700' size='large' />
        <TextWrapper>
          <Heading level='h2' styleOverride='headingSm' className='mb-5'>
            <Translation id='outpost.expiry.modal.heading' />
          </Heading>
          {translatedText}
        </TextWrapper>
        <ButtonWrapper>
          <Button styleType='primary' onClick={handleSubmit}>
            <Translation id='outpost.expiry.modal.button' />
          </Button>
        </ButtonWrapper>
      </Modal>
    </ModalContainer>
  )
}

CutoffExpiryModal.propTypes = {
  slotTime: PropTypes.string
}

export default CutoffExpiryModal
