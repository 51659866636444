import { Heading as H } from './styles'

const levels = {
  'heading-1': 'h1',
  'heading-2': 'h2',
  'heading-3': 'h3',
  'heading-4': 'h4',
  'heading-5': 'h5',
  'heading-6': 'h6'
}

const Heading = (node, children) => {
  const level = levels[node.nodeType]
  if (!level) {
    return null
  }
  return <H level={level}>{children}</H>
}

export default Heading
