import styled from '@emotion/styled'
import tw from 'twin.macro'

export const DiscountPriceWrapper = styled('div')`
  ${tw`rounded inline-block py-1 px-1 md:px-2 text-left bg-lightTeal`}
  svg {
    ${tw`inline mr-2 text-teal`}
    path {
      ${tw`text-teal`}
    }
  }
`

export const YourBaristaText = styled('span')`
  ${tw`text-teal text-sm`}
`

export const RemoveDiscount = styled('span')`
  ${tw`ml-3 text-sm underline cursor-pointer`}
`

export const RemoveDiscountError = styled('div')`
  ${tw`text-sm text-pretRed-700 mt-3`}
`
