import PropTypes from 'prop-types'
import Script from 'next/script'
import { gnattaScript } from './constants'

export const GnattaChatBotComponent = () => {
  return process.env.NODE_ENV === 'development' ? null : <GnattaChatBot />
}

export const GnattaChatBot = () => {
  return (
    <>
      <Script
        id='gnatta-script'
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: gnattaScript
        }}
      />
    </>
  )
}

GnattaChatBot.propTypes = {
  GnattaChatBot: PropTypes.string
}

GnattaChatBot.defaultProps = {
  GnattaChatBot: ''
}
