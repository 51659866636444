import PropTypes from 'prop-types'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { Button } from '@pretamanger/component-library'
import { getMarketDetailsFromLocale } from '#src/common/lib/get-market'
import { getLocaleToCookieLanguage } from '#src/common/lib/locale'
import Translation from '#src/common/components/translation'

export const OneTrust = () => {
  return process.env.NODE_ENV === 'development' ? null : <OneTrustScript />
}

export const OneTrustScript = () => {
  const { locale } = useRouter()
  const currentMarket = getMarketDetailsFromLocale(locale)

  return (
    <>
      <Script
        src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
        data-document-language='true'
        data-language={getLocaleToCookieLanguage(locale)}
        data-domain-script={currentMarket?.oneTrustScript}
        defer
      />
      <Script
        id='optanon-script'
        type='text/javascript'
        dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
      />
    </>
  )
}

OneTrustScript.propTypes = {
  oneTrustScript: PropTypes.string
}

OneTrustScript.defaultProps = {
  oneTrustScript: ''
}

export const CookieSettings = () => (
  <div className='relative'>
    <Button
      className='ot-sdk-show-settings absolute bottom-0 left-0'
      data-testid='cookies-modal'
      styleType='secondary'
    >
      <Translation id='cookie.preferences.button' />
    </Button>
  </div>
)
