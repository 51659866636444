import { useEffect, useState } from 'react'
import { timeFormatWithSuffix } from '#constants'
import {
  formatDate,
  getDate,
  addDuration,
  isAfter
} from '#src/common/lib/date-utils'
import Router from 'next/router'

const GRACE_PERIOD_MINUTES: number =
  Number(process.env.OUTPOST_GRACE_PERIOD_MINUTES) || 5

export const useOutpostExpiry = (
  outpostFlag: boolean,
  locale: string,
  pickupTimeEnd: string,
  orderType: string,
  timezone: string
) => {
  const [isOutpostExpired, setIsOutpostExpired] = useState(false)
  const [outpostTimeslot, setOutpostTimeslot] = useState()

  const handleExpiryRedirect = async () => {
    await Router.push(`/${locale}/orderonline`)
  }

  useEffect(() => {
    const isExpired = (timeslotEndTime: string, gracePeriodMinutes: number) => {
      const timeslotWithGracePeriod = addDuration(timeslotEndTime, {
        minutes: gracePeriodMinutes
      })
      return (
        timeslotWithGracePeriod &&
        isAfter(getDate().toISO(), timeslotWithGracePeriod.toISO(), timezone)
      )
    }

    if (outpostFlag && pickupTimeEnd && orderType === 'outpost') {
      setOutpostTimeslot(
        formatDate(timeFormatWithSuffix, pickupTimeEnd, timezone)
      )
      setIsOutpostExpired(isExpired(pickupTimeEnd, GRACE_PERIOD_MINUTES))
    }
  }, [outpostFlag, locale, pickupTimeEnd, orderType, timezone])

  return {
    isOutpostExpired,
    handleExpiryRedirect,
    outpostTimeslot
  }
}
