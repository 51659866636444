import { LINK_CLICK } from '../../../../topics'

export const linkClickAdapter = ({ ...elements }) => {
  const gtmObj = {
    ecommerce: {},
    element: { ...elements },
    event: LINK_CLICK
  }

  return gtmObj
}
