import { coffeeSubscriptionPlanIds } from '#src/common/constants'
import Type from '#src/pages/api/payment/handle-shopper-redirect/[locale]/[redirectId]/[type]'
import { Subscription } from '#src/state/types'
import { Rewards, RewardSummary } from '#src/typings/rewards'
import { User } from '@auth0/auth0-spa-js'
import { PageConfig } from '../../../../../page-config/page-config'
import { fireEvent } from '../../../index'
import { PAGE_VIEW } from '../../../topics'

type PageViewEventProps = {
  gtmPageProps: PageConfig
  claims: object
  isLoading: boolean
  locale?: string
  createdAt?: string
  subscriptions?: Subscription[]
  rewardsSummary?: RewardSummary
  rewards?: Rewards
  user?: User
}

export const firePageViewEvent = ({
  gtmPageProps,
  claims,
  isLoading,
  locale,
  createdAt,
  subscriptions,
  rewardsSummary,
  rewards,
  user
}: PageViewEventProps) => {
  if (isLoading) {
    return
  }
  const {
    screenName = document.title,
    screenType = '',
    sectionType = ''
  } = gtmPageProps || {}

  const subscription =
    subscriptions &&
    ((subscriptions.length > 0 && subscriptions[0]) || ({} as Subscription))

  fireEvent(PAGE_VIEW, {
    screenName,
    screenType,
    sectionType,
    claims,
    locale,
    createdAt,
    subscription,
    rewardsSummary,
    rewards,
    user
  })
}
