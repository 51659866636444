import PropTypes from 'prop-types'
import {
  MyAccountLabel,
  MyAccountButton,
  SignInLabel,
  SignInButton
} from '#src/common/app/components/auth-cta/styles'
import Translation, { translate } from '#src/common/components/translation'
import {
  myAccountPath,
  myAccountTransactionalQueryParam,
  subscriptionURLPrefix
} from '#constants'
import { useRouter } from 'next/router'
import { getCollectionPath } from '#src/common/lib/get-collection-path'
import { accountSelector } from '#src/state/account-state-slice'
import { useAppSelector } from '#src/state/redux-hooks'
import { navigateToSignInWithRedirectUrl } from '#src/common/lib/auth0/auth0-functions'

const isOrderAheadFlow = (pathname, collectionPath) => {
  return !!pathname && pathname.split('/').includes(collectionPath)
}

const SignInCTA = ({ onClick, login }) => {
  const router = useRouter()
  const collectionPath = getCollectionPath(router.locale)
  const isPartOfOrderAheadFlow = isOrderAheadFlow(
    router.pathname,
    collectionPath
  )
  const shouldSkipVerification = isPartOfOrderAheadFlow
  const returnPath =
    !isPartOfOrderAheadFlow &&
    !router.asPath.includes(`/${subscriptionURLPrefix}`)
      ? `/${myAccountPath}`
      : undefined
  const handleClick = () => {
    login({ shouldSkipVerification, returnPath })
    onClick()
  }

  return (
    <SignInButton
      aria-label={`${translate('header.auth.signin.screenreader.label').value}`}
      data-testid='sign-in'
      onClick={handleClick}
    >
      <SignInLabel aria-hidden>
        <Translation id='header.auth.signin.link.label' />
      </SignInLabel>
      <span className='sr-only'>
        <Translation id='header.auth.signin.screenreader.label' />
      </span>
    </SignInButton>
  )
}

const MyAccountCTA = ({ onClick }) => {
  const router = useRouter()
  const collectionPath = getCollectionPath(router.locale)
  const shouldPassTxnQueryParam = isOrderAheadFlow(
    router.pathname,
    collectionPath
  )
  const myAccountUrl = shouldPassTxnQueryParam
    ? `/${myAccountPath}?${myAccountTransactionalQueryParam}=true`
    : `/${myAccountPath}`

  const handleClick = async () => {
    await router.push(myAccountUrl)
    onClick()
  }

  return (
    <MyAccountButton data-testid='my-account' onClick={handleClick}>
      <MyAccountLabel aria-hidden>
        <Translation id='header.auth.myaccount.link.label' />
      </MyAccountLabel>
      <span className='sr-only'>
        <Translation id='header.auth.myaccount.screenreader.label' />
      </span>
    </MyAccountButton>
  )
}

const AuthCTA = ({ onClick }) => {
  const router = useRouter()
  const { isAuthenticated, isLoading } = useAppSelector(accountSelector)
  return isLoading ? null : isAuthenticated ? (
    <MyAccountCTA onClick={onClick} />
  ) : (
    <SignInCTA
      onClick={onClick}
      login={options => navigateToSignInWithRedirectUrl(options, router)}
    />
  )
}

MyAccountCTA.propTypes = {
  onClick: PropTypes.func
}

SignInCTA.propTypes = {
  onClick: PropTypes.func,
  login: PropTypes.func
}

AuthCTA.propTypes = {
  onClick: PropTypes.func
}

AuthCTA.defaultProps = {
  onClick: () => {}
}

export default AuthCTA
