import {
  ADD_TO_BAG,
  CHECKOUT,
  COFFEE_SUBSCRIPTION_PURCHASE,
  CUSTOM,
  FEATURE_CLICK,
  GTM_JS,
  LINK_CLICK,
  PAGE_VIEW,
  PRODUCT_CLICK,
  PRODUCT_LIST_IMPRESSION,
  PURCHASE,
  RECOMMENDATIONS_LOAD,
  REMOVE_FROM_BAG,
  SELECT_OPTION,
  TRANSACTIONAL_PRODUCT_DETAILS,
  LOGOUT_LINK_CLICK,
  SUBSCRIPTION_PAUSED,
  SUBSCRIPTION_PAUSE_REMOVED,
  SUBSCRIPTION_CANCELLED,
  SUBSCRIPTION_CANCELLATION_REMOVED,
  SUBSCRIPTION_CHANGED,
  SUBSCRIPTION_REACTIVATED,
  SELECT_ITEM,
  VIEW_ITEM,
  BEGIN_CHECKOUT,
  ADD_PAYMENT_INFO,
  PAYMENT_FAILURE,
  SUBSCRIPTION_ACTIVATED,
  ADD_TO_CART
} from '../../../../topics'

import { addToBagAdapter } from './add-to-bag'
import { checkoutAdapter } from './checkout'
import { coffeeSubscriptionPurchaseAdapter } from './coffee-subscription-purchase'
import { customAdapter } from './custom'
import { featureClickAdapter } from './feature-click'
import { gtmJsAdapter } from './gtm-js'
import { linkClickAdapter } from './link-click'
import { logoutLinkClickAdapter } from './logout-link-click'
import { pageViewAdapter } from './page-view'
import { productClickAdapter } from './product-click'
import { productListImpressionAdapter } from './product-list-impression'
import { purchaseAdapter } from './purchase'
import { recommednationsLoadAdapter } from './recommendations-load'
import { removeFromBagAdapter } from './remove-from-bag'
import { selectOptionAdapter } from './select-option'
import { transactionalProductDetailsAdapter } from './transactional-product-details'
import { subscriptionAdapter } from './subscription-adapter'
import { selectItemAdapter } from './select-item'
import { viewItemAdapter } from './view-item'

export const analyticsEventAdapters = {}
analyticsEventAdapters[ADD_TO_BAG] = addToBagAdapter
analyticsEventAdapters[CHECKOUT] = checkoutAdapter
analyticsEventAdapters[CUSTOM] = customAdapter
analyticsEventAdapters[FEATURE_CLICK] = featureClickAdapter
analyticsEventAdapters[GTM_JS] = gtmJsAdapter
analyticsEventAdapters[LINK_CLICK] = linkClickAdapter
analyticsEventAdapters[PAGE_VIEW] = pageViewAdapter
analyticsEventAdapters[PRODUCT_CLICK] = productClickAdapter
analyticsEventAdapters[PRODUCT_LIST_IMPRESSION] = productListImpressionAdapter
analyticsEventAdapters[PURCHASE] = purchaseAdapter
analyticsEventAdapters[RECOMMENDATIONS_LOAD] = recommednationsLoadAdapter
analyticsEventAdapters[REMOVE_FROM_BAG] = removeFromBagAdapter
analyticsEventAdapters[SELECT_OPTION] = selectOptionAdapter
analyticsEventAdapters[LOGOUT_LINK_CLICK] = logoutLinkClickAdapter
analyticsEventAdapters[SUBSCRIPTION_PAUSED] = subscriptionAdapter
analyticsEventAdapters[SUBSCRIPTION_PAUSE_REMOVED] = subscriptionAdapter
analyticsEventAdapters[SUBSCRIPTION_CANCELLED] = subscriptionAdapter
analyticsEventAdapters[SUBSCRIPTION_CANCELLATION_REMOVED] = subscriptionAdapter
analyticsEventAdapters[SUBSCRIPTION_CHANGED] = subscriptionAdapter
analyticsEventAdapters[SUBSCRIPTION_REACTIVATED] = subscriptionAdapter
analyticsEventAdapters[SELECT_ITEM] = selectItemAdapter
analyticsEventAdapters[VIEW_ITEM] = viewItemAdapter
analyticsEventAdapters[BEGIN_CHECKOUT] = subscriptionAdapter
analyticsEventAdapters[ADD_PAYMENT_INFO] = subscriptionAdapter
analyticsEventAdapters[PAYMENT_FAILURE] = subscriptionAdapter
analyticsEventAdapters[SUBSCRIPTION_ACTIVATED] = subscriptionAdapter
analyticsEventAdapters[ADD_TO_CART] = subscriptionAdapter

analyticsEventAdapters[COFFEE_SUBSCRIPTION_PURCHASE] =
  coffeeSubscriptionPurchaseAdapter

analyticsEventAdapters[TRANSACTIONAL_PRODUCT_DETAILS] =
  transactionalProductDetailsAdapter
