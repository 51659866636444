import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { GridArea } from '@pretamanger/component-library'
import { image, navigationItem } from '@proptypes'
import LocaleLink from '../../../components/locale-link'
import Navigation from '../navigation'
import {
  CustomButton,
  HeaderGrid,
  HeaderLogo,
  HeaderNavigationContainer,
  LanguageSwitchContainer,
  LogoLink
} from './styles'
import LanguageSwitch from './language-switch'
import BagButton from '../bag-cta'
import { useResize } from '@hooks'
import { OrderAheadBagContext } from '#src/common/context/order-ahead-bag'
import Translation, { translate } from '../../../components/translation'
import { getTotalNumberOfItems } from '../../../components/bag/bag-utils'
import AuthCTA from '#src/common/app/components/auth-cta'
import { HeaderActions } from '#src/common/app/components/layout/styles'
import { PRET_DELIVERS_SHOP_ASSIGNMENT } from '#src/common/constants'

const Header = ({
  logo,
  navigation,
  displayBag,
  onToggleBag,
  displayUserAccountLink
}) => {
  const { isLoading: bagLoading, bag } = useContext(OrderAheadBagContext)
  const { locale, push } = useRouter()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const menuLabelId = isMenuOpen
    ? 'header.main-navigation.close'
    : 'header.main-navigation.open'
  const hasNavigationItems = navigation.length > 0
  const isUKPage = locale === 'en-GB'

  const areas = useMemo(() => {
    const xs = hasNavigationItems
      ? [
          [
            { name: 'logo', start: 2, span: 2 },
            { name: 'navigation', start: 1, span: 1 },
            { name: 'bag', start: 7, span: 1 }
          ]
        ]
      : [
          [
            { name: 'logo', start: 1, span: 2 },
            { name: 'bag', start: 6, span: 2 }
          ]
        ]

    const sm = hasNavigationItems
      ? [
          [
            { name: 'logo', start: 2, span: 2 },
            { name: 'navigation', start: 1, span: 1 },
            { name: 'bag', start: 7, span: 2 }
          ]
        ]
      : [
          [
            { name: 'logo', start: 1, span: 2 },
            { name: 'bag', start: 7, span: 2 }
          ]
        ]

    const lg = [
      [
        { name: 'logo', start: 1, span: 1 },
        { name: 'navigation', start: 2, span: 9 },
        { name: 'language', start: 10, span: 1 },
        { name: 'bag', start: 11, span: 2 }
      ]
    ]

    const xl = [
      [
        { name: 'logo', start: 1, span: 1 },
        { name: 'navigation', start: 2, span: 9 },
        { name: 'language', start: 10, span: 1 },
        { name: 'bag', start: 11, span: 2 }
      ]
    ]

    return {
      xs,
      sm,
      md: sm,
      lg,
      xl,
      '2xl': xl
    }
  }, [hasNavigationItems])

  const closeMenu = useCallback(() => {
    if (isMenuOpen) {
      toggleMenu()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isMenuOpen])

  const toggleMenu = useCallback(() => {
    setMenuOpen(!isMenuOpen)
  }, [setMenuOpen, isMenuOpen])

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('nav-open')
    } else {
      document.body.classList.remove('nav-open')
    }
  }, [isMenuOpen])

  useResize(() => setMenuOpen(false))

  return (
    <HeaderGrid areas={areas}>
      <GridArea name='logo'>
        <LocaleLink href='/'>
          <LogoLink
            aria-label={translate('common.pret.homepage', locale)?.value}
          >
            <span className='sr-only'>
              <Translation id='common.pret.homepage' />
            </span>
            <HeaderLogo src={logo.src} alt={logo.alt} aria-hidden='true' />
          </LogoLink>
        </LocaleLink>
      </GridArea>
      <GridArea data-testid='navigation-hamburger' name='navigation'>
        {navigation.length > 0 && (
          <HeaderNavigationContainer>
            <input
              type='checkbox'
              id='hamburger-toggle'
              onChange={toggleMenu}
              checked={isMenuOpen}
            />
            <label htmlFor='hamburger-toggle'>
              <Translation id={menuLabelId} />
              <span />
              <span />
              <span />
            </label>
            <Navigation
              ariaLabel={translate('landmark.navigation.main', locale)?.value}
              displayLanguageSwitcher
              displayUserAccountLink={displayUserAccountLink}
              isPrimary
              items={navigation}
              onNavigate={closeMenu}
            />
          </HeaderNavigationContainer>
        )}
      </GridArea>
      <GridArea name='language'>
        <LanguageSwitchContainer>
          <LanguageSwitch />
        </LanguageSwitchContainer>
      </GridArea>
      <GridArea name='bag' className='text-right'>
        <HeaderActions data-testid='header-actions'>
          {displayUserAccountLink && <AuthCTA />}
          {isUKPage && (
            <CustomButton
              className='!px-[20px]'
              onClick={() => push(PRET_DELIVERS_SHOP_ASSIGNMENT)}
            >
              <Translation id='header.order-now.button' />
            </CustomButton>
          )}
          {displayBag && (
            <BagButton
              onClick={onToggleBag}
              itemCount={bagLoading ? null : getTotalNumberOfItems(bag)}
            />
          )}
        </HeaderActions>
      </GridArea>
    </HeaderGrid>
  )
}

Header.propTypes = {
  logo: image.isRequired,
  onToggleBag: PropTypes.func.isRequired,
  displayBag: PropTypes.bool.isRequired,
  displayUserAccountLink: PropTypes.bool,
  navigation: PropTypes.arrayOf(navigationItem)
}

Header.defaultProps = {
  buttonLabel: undefined,
  navigation: [],
  displayUserAccountLink: false
}

export default Header
