import { getEnvVarsTactical } from '#src/common/lib/get-env-vars'
import { getBrowserEnvironment } from '#src/common/lib/getBrowserEnv'
import { configureStore } from '@reduxjs/toolkit'
import {
  AccountState,
  accountStateSlice,
  getAccount,
  FeatureFlags
} from './account-state-slice'
import { bagStateSlice } from './bag-state-slice'
import { giftBagStateSlice } from './gift-bag-state-slice'
import { BagState } from './types'
import { EnvState, envStateSlice } from './env-state-slice'
import {
  RewardCategoriesState,
  rewardCategoriesStateSlice
} from './reward-categories-state-slice'
import {
  OrderDetailsState,
  orderDetailsStateSlice
} from './order-details-state-slice'
import { RewardsState, rewardsStateSlice } from './rewards-state-slice'
import {
  fetchProfile,
  ProfileState,
  profileStateSlice
} from './profile-state-slice'
import {
  subscriptionBagStateSlice,
  SubscriptionBagState
} from '#src/state/subscription-bag-state-slice'
import { preferencesSlice, PreferencesState } from './preferences-state-slice'
import { PretShopsState, pretShopsSlice } from './shops-state-slice'
import {
  preferencesCenterSlice,
  PreferencesCenterState
} from './preferences-center-state-slice'
import { CompanyDetails, companySlice } from './company-state-slice'

let envVars = null
export const getEnvVarsSingleton = () => envVars

const initialiseState = async (store: any, featureFlags?: FeatureFlags) => {
  if (typeof window === 'undefined') {
    return
  }

  envVars = await getEnvVarsTactical()
  store.dispatch(envStateSlice.actions.loadEnv(envVars))
  const account = await getAccount(envVars, featureFlags)
  store.dispatch(accountStateSlice.actions.loadAccount(account))
  if (account.isAuthenticated) {
    store.dispatch(fetchProfile({ token: '' }))
  }
}

let store = null

export const getStore = (featureFlags?: FeatureFlags) => {
  if (!store) {
    store = configureStore({
      devTools: getBrowserEnvironment() !== 'production',
      reducer: {
        env: envStateSlice.reducer,
        account: accountStateSlice.reducer,
        profile: profileStateSlice.reducer,
        bag: bagStateSlice.reducer,
        giftBag: giftBagStateSlice.reducer,
        orderDetails: orderDetailsStateSlice.reducer,
        rewards: rewardsStateSlice.reducer,
        rewardCategories: rewardCategoriesStateSlice.reducer,
        subscriptionBag: subscriptionBagStateSlice.reducer,
        preferences: preferencesSlice.reducer,
        pretShops: pretShopsSlice.reducer,
        preferencesCenter: preferencesCenterSlice.reducer,
        company: companySlice.reducer
      }
    })
    initialiseState(store, featureFlags)
  } else if (featureFlags) {
    store.dispatch(accountStateSlice.actions.updateAccount({ featureFlags }))
  }

  return store
}

export type AppDispatch = typeof store.dispatch

export type RootState = {
  env: EnvState
  account: AccountState
  profile: ProfileState
  bag: BagState
  giftBag: BagState
  orderDetails: OrderDetailsState
  rewards: RewardsState
  rewardCategories: RewardCategoriesState
  subscriptionBag: SubscriptionBagState
  preferences: PreferencesState
  pretShops: PretShopsState
  preferencesCenter: PreferencesCenterState
  company: CompanyDetails
}
