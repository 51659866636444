import { httpClient } from './http-client'
import { getEnvVars } from './get-env-vars'
import { getLogger } from '#api/logger-with-context'

const logRef = 'Platform Client::'

export const targetServiceEnum = Object.freeze({
  PLATFORM_API_SERVICE: 'PLATFORM_API_SERVICE',
  CHECKOUT_API_SERVICE: 'CHECKOUT_API_SERVICE'
})

const platformClient = async (endpoint, config = {}) => {
  const log = getLogger()
  const {
    version = 'v1',
    targetService = targetServiceEnum.PLATFORM_API_SERVICE,
    ...remainingConfig
  } = config
  const { platformApiHost = '', checkoutApiHost = '' } = await getEnvVars()

  const apiHost =
    targetService === targetServiceEnum.CHECKOUT_API_SERVICE
      ? checkoutApiHost
      : platformApiHost

  log.info({ endpoint }, `${logRef} api call start`)
  const response = await httpClient(
    `${apiHost}/${version}${endpoint}`,
    remainingConfig
  )
  log.info(`${logRef} api call end`)

  if (!response.ok) {
    const error = new Error(response.statusText)
    error.response = response
    try {
      const responseJson = await response.json()
      error.responseJson = responseJson
      // eslint-disable-next-line no-empty
    } catch {}
    log.error({ error }, `${logRef} api call error`)
    throw error
  }
  return response.json()
}

export default platformClient
