export type PageConfig = {
  screenName?: string
  screenType?: string
  sectionType?: string
}
type PageConfigMap = {
  [name: string]: PageConfig
}

export const HOME_PAGE = 'home-page'
export const MY_ACCOUNT_PAGE = 'my-account-page'
export const MANAGE_SUBSCRIPTION_PAGE = 'manage-subscription-page'
export const PAUSE_SUBSCRIPTION_PAGE = 'pause-subscription-page'
export const CANCEL_SUBSCRIPTION_PAGE = 'cancel-subscription-page'
export const CHECKOUT_SIGNIN_PAGE = 'checkout-signin-page'
export const LOYALTY_SIGNIN_PAGE = 'loyalty-signin-page'
export const COFFEE_SUBS_SIGNIN_PAGE = 'coffee-subs-signin-page'
export const COFFEE_SUBSCRIPTION_CONFIRMATION =
  'coffee-subscription-confirmation'
export const BILLING_HISTORY_PAGE = 'billing-history-page'
export const CHANGE_PLAN_PAGE = 'change-plan-page'
export const MANAGE_CARD = 'manage-card'
export const REACTIVATE_CARD = 'reactivate-card'
export const RESUME_SUBSCRIPITON = 'resume-subscription'

export const STORE_FINDER_PAGE = 'store-finder-page'
export const COLLECTION_TIMESLOTS = 'collection-timeslots'
export const MENU_LANDING_PAGE = 'menu-landing-page'
export const PRODUCT_LISTING_PAGE = 'product-listing-page'
export const PRODUCT_CATEGORY_PAGE = 'product-category-page'
export const RECOMMENDATIONS_PAGE = 'recommendations-page'
export const ORDER_DETAILS_PAGE = 'order-details-page'
export const ORDER_CONFIRMATION_PAGE = 'order-confirmation-page'

const pageConfigMap: PageConfigMap = {
  [STORE_FINDER_PAGE]: {
    screenName: 'Pret A Manger',
    screenType: 'selection screen',
    sectionType: 'Click & Collect'
  },
  [MANAGE_SUBSCRIPTION_PAGE]: {
    screenType: 'myaccount',
    sectionType: 'manageSubscription'
  },
  [CANCEL_SUBSCRIPTION_PAGE]: {
    screenType: 'myaccount',
    sectionType: 'cancel subscription'
  },
  [PAUSE_SUBSCRIPTION_PAGE]: {
    screenType: 'myaccount',
    sectionType: 'pause subscription'
  },
  [BILLING_HISTORY_PAGE]: {
    screenType: 'myaccount',
    sectionType: 'billing history'
  },
  [CHANGE_PLAN_PAGE]: {
    screenType: 'myaccount',
    sectionType: 'change plan'
  },
  [MANAGE_CARD]: {
    screenType: 'myaccount',
    sectionType: 'managePayment'
  },
  [REACTIVATE_CARD]: {
    screenType: 'myaccount',
    sectionType: 'managePayment'
  },
  [RESUME_SUBSCRIPITON]: {
    screenType: 'myaccount',
    sectionType: 'managePayment'
  },
  [RECOMMENDATIONS_PAGE]: {
    screenType: 'bag',
    screenName: 'Cross-sell',
    sectionType: 'Click & Collect'
  },
  [HOME_PAGE]: {
    screenName:
      'Freshly Prepared Sandwiches, Soups, Organic Coffee | Pret A Manger',
    screenType: 'home page'
  },
  [CHECKOUT_SIGNIN_PAGE]: {
    screenType: 'form',
    sectionType: 'Click & Collect'
  },
  [LOYALTY_SIGNIN_PAGE]: {
    screenType: '',
    sectionType: ''
  },
  [COFFEE_SUBS_SIGNIN_PAGE]: {
    screenType: 'subscription setup screen',
    sectionType: 'coffeeSubscription'
  },
  [MY_ACCOUNT_PAGE]: {
    screenType: 'myaccount',
    screenName: 'myaccount',
    sectionType: 'My Account'
  },
  [ORDER_CONFIRMATION_PAGE]: {
    screenType: 'thankyou',
    screenName: 'thankyou',
    sectionType: 'Click & Collect'
  },
  [COFFEE_SUBSCRIPTION_CONFIRMATION]: {
    screenType: 'thankyou',
    screenName: 'thankyou',
    sectionType: 'coffeeSubscription'
  },
  [COLLECTION_TIMESLOTS]: {
    screenType: 'selection screen',
    screenName: 'Pret A Manger',
    sectionType: 'Click & Collect'
  },
  [MENU_LANDING_PAGE]: {
    screenType: 'menu',
    screenName: 'Pret A Manger',
    sectionType: 'Click & Collect'
  },
  [PRODUCT_LISTING_PAGE]: {
    screenType: 'selection',
    screenName: 'Pret A Manger',
    sectionType: 'Click & Collect'
  },
  [ORDER_DETAILS_PAGE]: {
    screenType: 'form',
    screenName: 'Pret A Manger',
    sectionType: 'Click & Collect'
  },
  [PRODUCT_CATEGORY_PAGE]: {
    screenType: ''
  }
}

export const pageCategory = {
  subscription: 'subscription',
  auth: 'auth',
  home: 'home',
  shop: 'shop',
  menu: 'menu',
  product: 'product',
  account: 'account',
  information: 'information'
}

export const getPageConfig = (pageName: string): PageConfig => {
  return pageConfigMap[pageName] || {}
}

const informationSlugs = [
  'allerg',
  'terms',
  'legal',
  'privacy',
  'confidentialite',
  'faqs',
  'talk-to',
  'contacter'
]

const isInformationPage = (path?: string) =>
  !!path && informationSlugs.some(slug => !!path && path.includes(slug))

const pageCategoryMap = (
  firstPath: string,
  secondPath: string,
  thirdPath: string
) =>
  ({
    products:
      {
        categories: pageCategory.menu
      }[thirdPath] || pageCategory.product,
    'my-account':
      {
        subscription: pageCategory.subscription
      }[thirdPath] || pageCategory.account,
    'shop-finder': pageCategory.shop,
    orderonline: pageCategory.home,
    'our-menu': pageCategory.menu
  }[secondPath] || (firstPath ? pageCategory.home : 'other'))

export const getPageCategory = () => {
  if (typeof window === 'undefined') {
    return ''
  }
  const { pathname } = window.location
  const [_, firstPath, secondPath, thirdPath] = pathname.split('/')

  return isInformationPage(secondPath)
    ? pageCategory.information
    : pageCategoryMap(firstPath, secondPath, thirdPath)
}
