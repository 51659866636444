import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { getPreferences } from '#src/common/lib/preferences/get-preferences'

export type PreferencesState = {
  sms: boolean
  email: boolean
  statusOk?: boolean
  isLoading?: boolean
  error?: boolean
}

const initialState: PreferencesState = {
  sms: false,
  email: false
}

export const fetchPreferences = createAsyncThunk<
  any,
  null,
  { state: RootState }
>('preferences/fetchPreferences', async () => {
  return await getPreferences()
})

export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPreferences.pending, (state: PreferencesState) => {
      state.isLoading = true
      state.statusOk = false
      state.error = false
    }),
      builder.addCase(fetchPreferences.rejected, (state: PreferencesState) => {
        state.statusOk = false
        state.isLoading = false
        state.error = true
      }),
      builder.addCase(
        fetchPreferences.fulfilled,
        (state: PreferencesState, action: PayloadAction<any>) => ({
          ...state,
          sms: action.payload.hasSMSOptin,
          email: action.payload.hasEmailOptin,
          ...action.payload,
          isLoading: false,
          error: false
        })
      )
  }
})

export const preferencesSelector = ({
  preferences
}: RootState): PreferencesState => preferences
