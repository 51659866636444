export const ADD_TO_BAG = 'addToCart'
export const CHECKOUT = 'checkout'
export const FEATURE_CLICK = 'featureClick'
export const PRODUCT_LIST_IMPRESSION = 'view_item_list'
export const RECOMMENDATIONS_LOAD = 'recommendationsLoad'
export const PURCHASE = 'purchase'
export const REMOVE_FROM_BAG = 'removeFromCart'
export const LINK_CLICK = 'linkClick'
export const GTM_JS = 'gtm.js'
export const PAGE_VIEW = 'page_view'
export const COFFEE_SUBSCRIPTION_PURCHASE = 'coffee-subscription-purchase'
export const CUSTOM = 'custom'
export const TRANSACTIONAL_PRODUCT_DETAILS = 'detail'
export const PRODUCT_CLICK = 'productClick'
export const SELECT_OPTION = 'selectOption'
export const LOGOUT_LINK_CLICK = 'logout'
export const SUBSCRIPTION_PAUSED = 'subscription_paused'
export const SUBSCRIPTION_PAUSE_REMOVED = 'subscription_pause_removed'
export const SUBSCRIPTION_CANCELLED = 'subscription_cancelled'
export const SUBSCRIPTION_CANCELLATION_REMOVED =
  'subscription_cancellation_removed'
export const SUBSCRIPTION_CHANGED = 'subscription_changed'
export const SUBSCRIPTION_REACTIVATED = 'subscription_reactivated'
export const SELECT_ITEM = 'select_item'
export const VIEW_ITEM = 'view_item'
export const BEGIN_CHECKOUT = 'begin_checkout'
export const ADD_PAYMENT_INFO = 'add_payment_info'
export const PAYMENT_FAILURE = 'payment_failure'
export const SUBSCRIPTION_ACTIVATED = 'subscription_activated'
export const ADD_TO_CART = 'add_to_cart'

export const actionField = {
  crossSell: 'Cross-sell',
  gifting: 'Gifting',
  orderAgain: 'Order Again'
}
