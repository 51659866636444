import { useState } from 'react'

const useSwitch = (initial = false) => {
  const [status, setStatus] = useState(initial)
  return [
    status,
    () => {
      setStatus(true)
    },
    () => {
      setStatus(false)
    }
  ]
}

export default useSwitch
