import {
  PURCHASE,
  BEGIN_CHECKOUT,
  ADD_PAYMENT_INFO
} from '#src/common/lib/events/topics'
import { capitalize } from '#src/common/lib/string'
import { mapPaymentMethod } from './map-payment-method'
import { getAmount } from './map-amount'
import {
  PRODUCT_SUBSCRIPTION_NAME_US,
  PRODUCT_BRAND,
  PRODUCT_CATEGORY_COFFEE_SUBSCRIPTION
} from '../../constants'

export const mapSubscriptionSignupEvent = (
  event: string,
  bagData: any,
  state?: string,
  paymentType?: string
) => {
  const obj = {
    event,
    ecommerce: {
      items: []
    }
  }

  const item = {}
  const lineItem = bagData?.lineItems?.[0]
  const sku = lineItem?.sku
  const plan = capitalize(sku?.split('-')[1] || '')

  item['item_id'] = sku ?? ''
  item['item_name'] = PRODUCT_SUBSCRIPTION_NAME_US
  item['affiliation'] = 'Online Store'
  item['item_brand'] = PRODUCT_BRAND
  item['item_category'] = PRODUCT_CATEGORY_COFFEE_SUBSCRIPTION
  if (state) {
    item['item_category2'] = state
  }
  obj.ecommerce['currency'] = bagData?.totalPrice?.currencyCode ?? ''
  obj.ecommerce['value'] = getAmount(
    bagData?.totalPrice?.centAmount,
    bagData?.totalPrice?.fractionDigits
  )

  const getDetailItem = () => {
    item['item_variant'] = sku?.includes('us') ? plan : 'Classic'
    item['price'] = getAmount(
      lineItem?.price?.centAmount,
      lineItem?.price?.fractionDigits
    )
    item['quantity'] = lineItem?.quantity ?? 0
  }

  if (event === BEGIN_CHECKOUT) {
    getDetailItem()
  }

  if (event === PURCHASE) {
    getDetailItem()
    obj.ecommerce['transaction_id'] = bagData?.orderId || bagData?.bagId || ''
    obj.ecommerce['affiliation'] = 'Online Store'
    obj.ecommerce['coupon'] = lineItem?.discount?.discountCode ?? ''
    obj.ecommerce['tax'] = getAmount(
      bagData?.taxedPrice?.totalTax?.centAmount,
      bagData?.taxedPrice?.totalTax?.fractionDigits
    )
  }

  if (event === ADD_PAYMENT_INFO) {
    getDetailItem()
    obj.ecommerce['payment_type'] = mapPaymentMethod(paymentType)
  }

  obj.ecommerce.items.push(item)

  return obj
}
