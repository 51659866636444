import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Footer from './footer'
import Header from './header'
import { market, navigation, settings } from '@proptypes'
import AnnouncementsContainer from './announcements-container'
import Translation from '#src/common/components/translation'
import { useOrderAheadBagContext } from '#src/common/context/order-ahead-bag'
import Bag from '#src/common/components/bag'
import {
  SkipToContent,
  HeaderContainer,
  StickyContainer,
  LayoutContainer
} from './styles'
import OrderSlotBanner from '../order-slot-banner'
import { useAsync, useMeasure } from 'react-use'
import { CookieSettings } from '#src/common/components/ketch'
import { CookieSettings as CookieSettingsOneTrust } from '#src/common/components/one-trust'
import httpClient, { getNonTransactionalHeaders } from '#lib/http-client'
import { auth0Flag } from '#constants'
import { setCssCustomProperty } from '#src/common/lib/css-custom-property'
import { fireFeatureClickEvent } from '#src/common/lib/events/publishers/analytics/fire-events/core'
import { useRouter } from 'next/router'
import {
  ImpersonationFrame,
  Notice,
  Star
} from '@pretamanger/component-library'
import NotificationBanner from '#src/common/components/NotificationBanner'
import { accountSelector } from '#src/state/account-state-slice'
import { rewardsSelector } from '#src/state/rewards-state-slice'
import { useAppSelector } from '#src/state/redux-hooks'
import { ErrorBoundary } from 'react-error-boundary'
import FiveHundredPage from '#src/error/FiveHundredPage'
import { useOutpostExpiry } from '#hooks/useOutpostExpiry'
import CutoffExpiryModal from '#src/outposts/components/cutoff-expiry-modal'
import { clientSideGetLaunchDarklyFlag } from '#src/common/lib/get-launch-darkly-flag'
import { ketchFlag } from '#constants'
import { provideAnonymousId } from '#src/common/lib/provide-anonymous-id'
import { getMarketDetailsFromLocale } from '#src/common/lib/get-market'
import { fireEvent } from '#src/common/lib/events'
import { LOGOUT_LINK_CLICK } from '#src/common/lib/events/topics'
import { auth0Logout } from '#src/common/lib/auth0/auth0-functions'
import { NoticeWrapper } from '#src/auth/my-account-page/styles'
import { companyInformationSelector } from '#src/state/company-state-slice'
import { profileSelector } from '#src/state/profile-state-slice'
import { businessPlus, businessPlusIBL } from '#src/common/constants/userRoles'
import { isRoleHasAccess } from '#src/loyalty/util'

const Layout = ({
  children,
  navigation,
  marketDetails,
  isTransactional,
  displayBag,
  isYourPret,
  showOrderSlotBanner,
  showLoyaltyBanner,
  displayPickupTime,
  displayUserAccountLink,
  cutoffTimeEnabledFlag,
  isMyaccount,
  settings: { footerLogo, socialNetworks, logo, oopsImage, markets, promotions }
}) => {
  const displayBagInTransactionalFlow = isTransactional && displayBag
  const [isUserAccountsEnabled, setUserAccounts] = useState(false)
  const {
    isLoading: bagLoading = true,
    bag,
    updateItemQuantity,
    refreshBag,
    removeItems,
    updateCutleryOption
  } = useOrderAheadBagContext()
  const [isBagOpen, setBagOpen] = useState(false)
  const {
    loyaltyStateFlag,
    isAuthenticated,
    user,
    isImpersonatedUser,
    accessToken
  } = useAppSelector(accountSelector)
  const { rewardsSummary = {} } = useAppSelector(rewardsSelector)
  const { locale, pathname } = useRouter()
  const anonymousId = provideAnonymousId()
  const market = useMemo(() => {
    return getMarketDetailsFromLocale(locale)
  }, [locale])
  const [currentScript, setCurrentScript] = useState(null)
  const { userRole } = useAppSelector(profileSelector)
  const companyInfo = useAppSelector(companyInformationSelector)
  const isBusinessPlus = isRoleHasAccess(userRole, businessPlus)
  const isIBLAccount = isRoleHasAccess(userRole, businessPlusIBL)
  const isBusinessPlusOrIBL = isBusinessPlus || isIBLAccount
  const toggleBag = () => {
    if (isBagOpen === false) {
      fireFeatureClickEvent({
        type: 'icon',
        name: 'Bag',
        destination: `${window.location.origin}/${locale}/order-ahead/bag`
      })
      refreshBag()
    }
    setBagOpen(!isBagOpen)
  }
  useAsync(async () => {
    const { userAccountsStatus, id: marketId } = marketDetails
    if (userAccountsStatus === 'live') {
      setUserAccounts(true)
    }
    if (userAccountsStatus === 'feature-flagged') {
      const headers = getNonTransactionalHeaders()
      const result = await httpClient('/api/flags', {
        method: 'POST',
        body: JSON.stringify({ name: auth0Flag, country: marketId }),
        headers
      })
      const isFlagEnabled = await result.json()
      setUserAccounts(isFlagEnabled)
    }
  }, [displayUserAccountLink])

  useAsync(async () => {
    const value = await clientSideGetLaunchDarklyFlag(
      ketchFlag,
      anonymousId,
      market?.id
    )

    if (value) {
      setCurrentScript('ketch')
    } else {
      setCurrentScript('oneTrust')
    }
  })

  const [announcementWrapperRef, { height: announcementHeight }] = useMeasure()
  const [headerWrapperRef, { bottom: headerBottom, top: headerTop }] =
    useMeasure()
  const [stickyHeaderRef, { height: stickyHeaderHeight }] = useMeasure()

  useEffect(() => {
    setCssCustomProperty.set(
      '--bag-offset-top',
      `${announcementHeight + headerBottom + headerTop}px`
    )
  }, [announcementHeight, headerBottom, headerTop])

  useEffect(() => {
    setCssCustomProperty.set(
      '--sticky-header-height',
      `${stickyHeaderHeight}px`
    )
  }, [stickyHeaderHeight])

  const { isOutpostExpired, handleExpiryRedirect, outpostTimeslot } =
    useOutpostExpiry(
      cutoffTimeEnabledFlag,
      bag.locale,
      bag?.orderDetails?.pickupTimeEnd,
      bag?.orderDetails?.orderType,
      bag?.orderDetails?.fulfillingShopTimezone
    )

  function renderLoyaltyBanner() {
    if (loyaltyStateFlag) {
      if (rewardsSummary.noOfRewards) {
        return (
          <NotificationBanner
            variant='gold'
            colorVariant='white'
            iconVariant='loyaltyIcon'
            icon={<Star colour='white' />}
            layout='center'
          >
            <Translation id='menu.loyalty.notification.banner' />
          </NotificationBanner>
        )
      }
    }
    return null
  }

  return (
    <LayoutContainer isImpersonation={isImpersonatedUser}>
      <SkipToContent href='#main-content'>
        <Translation id='page.accessibility.skipToContent' />
      </SkipToContent>
      <div ref={announcementWrapperRef}>
        <AnnouncementsContainer
          promotions={promotions}
          markets={markets}
          isTransactional={isTransactional}
        />
      </div>
      <StickyContainer
        ref={stickyHeaderRef}
        isSticky={displayBagInTransactionalFlow}
      >
        <HeaderContainer
          ref={headerWrapperRef}
          role='banner'
          aria-label='Header'
        >
          <div className='container mx-auto'>
            <Header
              logo={logo}
              navigation={
                isTransactional || isYourPret ? [] : navigation.header
              }
              displayUserAccountLink={isUserAccountsEnabled}
              displayBag={displayBagInTransactionalFlow}
              onToggleBag={toggleBag}
            />
          </div>
        </HeaderContainer>
        {showLoyaltyBanner && renderLoyaltyBanner()}
        {showOrderSlotBanner && (
          <OrderSlotBanner displayPickupTime={displayPickupTime} />
        )}
      </StickyContainer>
      <ImpersonationFrame
        isImpersonation={isImpersonatedUser}
        customerName={user?.name}
        exitButtonLabel={<Translation id='impersonated-user.button.exit' />}
        onExit={() => {
          fireEvent(LOGOUT_LINK_CLICK, { url: window.location.href })
          auth0Logout({
            returnTo: window.location.origin
          })
        }}
      />
      {isTransactional && isOutpostExpired && cutoffTimeEnabledFlag && (
        <CutoffExpiryModal
          handleSubmit={handleExpiryRedirect}
          slotTime={outpostTimeslot}
        />
      )}
      <main id='main-content' tabIndex={-1} className='relative outline-none'>
        {isMyaccount && isBusinessPlusOrIBL && companyInfo.isAccountSuspended && (
          <NoticeWrapper variant='center'>
            <Notice
              variant='critical'
              title={
                <Translation
                  id={
                    isBusinessPlus
                      ? 'my.account.pret.delivers.suspended.notification'
                      : 'my.account.pret.delivers.suspended-ibl.notification'
                  }
                />
              }
            />
          </NoticeWrapper>
        )}
        <div className='container mx-auto'>
          {isTransactional && (
            <Bag
              bag={bag}
              isOpen={isBagOpen}
              isLoading={bagLoading}
              onCloseBag={() => setBagOpen(false)}
              onQuantityChange={updateItemQuantity}
              refreshBag={refreshBag}
              removeItems={removeItems}
              updateCutleryOption={updateCutleryOption}
            />
          )}
          <ErrorBoundary
            fallbackRender={() => <FiveHundredPage oopsImage={oopsImage} />}
            resetKeys={[pathname]}
          >
            {children}
          </ErrorBoundary>
        </div>
      </main>
      <footer role='contentinfo'>
        <div className='mt-8 py-8 border-t border-grey-100'>
          <div className='container mx-auto'>
            <Footer
              logo={footerLogo}
              navigation={navigation.footer}
              socialNetworks={socialNetworks}
              markets={markets}
              isAuthenticated={isAuthenticated}
            />
          </div>
        </div>
        {currentScript === 'ketch' && <CookieSettings />}
        {currentScript === 'oneTrust' && <CookieSettingsOneTrust />}
      </footer>
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  marketDetails: market.isRequired,
  displayAnnouncement: PropTypes.bool,
  displayBag: PropTypes.bool,
  displayPickupTime: PropTypes.bool,
  displayUserAccountLink: PropTypes.bool,
  isTransactional: PropTypes.bool,
  isYourPret: PropTypes.bool,
  navigation,
  showOrderSlotBanner: PropTypes.bool,
  showLoyaltyBanner: PropTypes.bool,
  cutoffTimeEnabledFlag: PropTypes.bool,
  settings,
  isAuthenticated: PropTypes.bool
}

Layout.defaultProps = {
  isTransactional: false,
  isYourPret: false,
  showOrderSlotBanner: false,
  showLoyaltyBanner: false,
  displayAnnouncement: true,
  displayBag: true,
  displayPickupTime: true,
  displayUserAccountLink: true,
  cutoffTimeEnabledFlag: false,
  isAuthenticated: false
}

export default Layout
