import { httpClient as fetch } from '#lib/http-client'
import { getTokenSilently } from '../auth0/auth0-functions'
import { RequestExtend } from './types'

export const getRewards = async (baseUrl: string): Promise<any> => {
  const accessToken = await getTokenSilently()
  const params: RequestExtend = {
    method: 'GET',
    json: true,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  const response = await fetch(
    `${baseUrl}/v1/wallet/reward?active=true`,
    params
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return await response.json()
}
