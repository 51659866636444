import { accountSelector, getAuth0Client } from '#src/state/account-state-slice'
import { envSelector } from '#src/state/env-state-slice'
import { useAppSelector } from '#src/state/redux-hooks'
import { RedirectLoginOptions, User } from '@auth0/auth0-spa-js'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { createContext, useContext } from 'react'
import {
  navigateToSignInWithRedirectUrl,
  auth0Logout,
  navigateToSignUpWithRedirectUrl,
  getTokenSilently
} from '../lib/auth0/auth0-functions'

export type Auth0ContextType = {
  login?: (options?: any) => void
  getTokenSilently?: (options?: any) => Promise<any>
  isLoading?: boolean
  user?: User
  isAuthenticated?: boolean
  isAuth0Error?: boolean
}

/**
 * @deprecated: for state requirements use redux state selectors.
 */
export const Auth0Context = createContext<Auth0ContextType>({})

/**
 * @deprecated: for state requirements use redux state selectors.
 * For `getTokenSilently` use the singleton client via `getAuth0Client`
 * For all other functions use the `auth0-functions`
 */
export const useAuth0Context = () => {
  const utils = useContext(Auth0Context)
  if (!utils) {
    throw new Error(
      'Auth0 compound components cannot be rendered outside the Auth0Provider component'
    )
  }
  return utils
}

/**
 * @deprecated: for state requirements use redux state selectors.
 * For `getTokenSilently` use the singleton client via `getAuth0Client`.
 * For Auth0 redirection use `useAuth0Redirects` hook.
 */
const Auth0Provider = ({ children }) => {
  const { auth0Audience } = useAppSelector(envSelector)
  const { user, claims, isLoading, isAuthenticated } =
    useAppSelector(accountSelector)

  const client = getAuth0Client()
  const router = useRouter()

  /**
   * @deprecated use `auth0-functions` and redux state selectors
   */
  const configObject = {
    isLoading,
    audience: auth0Audience,
    isAuthenticated,
    user,
    claims,
    /**
     * @deprecated the user is either logged in or out, failed login attempts are redirected back to auth0 login
     */
    isAuth0Error: false,
    _loginWithRedirect: (options?: RedirectLoginOptions) =>
      client.loginWithRedirect(options),
    login: options => navigateToSignInWithRedirectUrl(options, router),
    signup: options => navigateToSignUpWithRedirectUrl(options, router),
    getTokenSilently,
    getIdTokenClaims: () => client.getIdTokenClaims(),
    logout: auth0Logout
  }

  return (
    <Auth0Context.Provider value={configObject}>
      {children}
    </Auth0Context.Provider>
  )
}

Auth0Provider.propTypes = {
  children: PropTypes.node.isRequired
}

export default Auth0Provider
