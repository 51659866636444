import { LOGOUT_LINK_CLICK } from '../../../../topics'
import { getLocaleFromUrl } from '#src/common/init/get-current-locale'

export const logoutLinkClickAdapter = ({ url }) => {
  const gtmObj = {
    event: LOGOUT_LINK_CLICK,
    /* eslint-disable-next-line camelcase */
    referring_locale: getLocaleFromUrl(url)
  }
  return gtmObj
}
