import styled from '@emotion/styled'
import tw from 'twin.macro'

export const CutleryContainer = styled('div')`
  ${tw`w-full flex items-center p-2 bg-grey-100`}
  label {
    ${tw`bg-white`}
  }
`

export const CutleryReadOnlyContainer = styled('div')`
  ${tw`w-full flex items-center text-base`}
`
