export const employee = 'EMPLOYEE'
export const personal = 'PERSONAL'
export const business = 'BUSINESS'
export const businessPlus = 'BUSINESS_PLUS'
export const businessPlusIBL = 'BUSINESS_PLUS_IBL'
export const pdRoles = [personal, business, businessPlus, businessPlusIBL]
export const textUserRoles = {
  PERSONAL: 'Personal',
  EMPLOYEE: 'Personal(Employee)',
  BUSINESS: 'Business',
  BUSINESS_PLUS: 'Business Plus',
  BUSINESS_PLUS_IBL: 'Business Plus IBL'
}

export const userRolesOrder = {
  PERSONAL: 0,
  EMPLOYEE: 1,
  BUSINESS: 2,
  BUSINESS_PLUS: 3,
  BUSINESS_PLUS_IBL: 4
}

export const orderUserRoles = {
  0: 'PERSONAL',
  1: 'EMPLOYEE',
  2: 'BUSINESS',
  3: 'BUSINESS_PLUS',
  4: 'BUSINESS_PLUS_IBL'
}

export const USERS_WHO_NOT_ALLOWED_EDIT_EMAIL = [
  textUserRoles.EMPLOYEE,
  textUserRoles.BUSINESS_PLUS,
  textUserRoles.BUSINESS_PLUS_IBL
]

export const USERS_WHO_NOT_ALLOWED_EDIT_NAME = [textUserRoles.EMPLOYEE]
