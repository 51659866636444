import { SessionTimeOutModal } from '@pretamanger/component-library'
import Translation from '../translation'
import {
  auth0Logout,
  getTokenSilently
} from '#src/common/lib/auth0/auth0-functions'
import { accountSelector } from '#src/state/account-state-slice'
import { useAppSelector } from '#src/state/redux-hooks'
import { convertMinstoMilliSecs } from '#src/common/lib/utils'
import { envSelector } from '#src/state/env-state-slice'

const SessionTimeoutComponent = () => {
  const { isAuthenticated, isImpersonatedUser } =
    useAppSelector(accountSelector)
  const {
    sessionTimeout = '',
    promptTimeout = '',
    impersonationSessionTimeout = ''
  } = useAppSelector(envSelector)

  const inactivityTimeout = convertMinstoMilliSecs(
    Number(isImpersonatedUser ? impersonationSessionTimeout : sessionTimeout)
  )
  const promptBeforeIdleTimeout = convertMinstoMilliSecs(Number(promptTimeout))

  const handleSessionLogout = () => {
    auth0Logout({ returnTo: window.location.origin })
  }

  const refreshSession = async () => {
    await getTokenSilently()
  }

  if (isAuthenticated) {
    return (
      <SessionTimeOutModal
        title={<Translation id='session.modal.title' />}
        staySignLabel={<Translation id='login.session.stay.signin' />}
        signOutLabel={<Translation id='login.session.signout' />}
        handleStaySign={refreshSession}
        handleSignOut={handleSessionLogout}
        sessionTimeout={inactivityTimeout}
        promptBeforeIdle={promptBeforeIdleTimeout}
      />
    )
  }
  return <></>
}

export default SessionTimeoutComponent
