import getNextPath from '../../../lib/get-path'
import LocaleLink from '../../locale-link'

const LinkEntry = (node, children, locale, openEntityLinksInNewWindow) => {
  if (openEntityLinksInNewWindow) {
    const localizedSlug = node.data.target.fields.slug[locale]
    let href
    if (localizedSlug) {
      href = `/${locale}/${localizedSlug}`
    } else {
      href = `/${node.data.target.fields.slug}`
    }
    return (
      <a href={href} rel='noopener noreferrer' target='_blank'>
        {children}
      </a>
    )
  }

  const path =
    (node.data.target.fields.slug && node.data.target.fields.slug[locale]) ||
    node.data.target.fields.slug

  const slug = `/${path}`
  return (
    <LocaleLink href={getNextPath(slug, locale)} as={slug}>
      <a>{children}</a>
    </LocaleLink>
  )
}

export default LinkEntry
