import {
  DateObject,
  DateObjectUnits,
  DateTime,
  DurationObjectUnits,
  Settings
} from 'luxon'
import curry from 'ramda/src/curry'
import {
  time24HourFormat,
  timeFormatWithSuffix,
  timezone,
  timeDayOfMonthYearFormat,
  timeDayOfMonthYearFormatForUS,
  timeMonthDayFormat,
  timeDayMonthFormat
} from '#constants'
import {
  AvailableLocales,
  AvailableFormats,
  LocalesMap
} from '#src/typings/locales'

Settings.defaultZoneName = timezone

const localesUsing24HourTime = ['fr-FR', 'zh-HK', 'en-HK']
const timePeriodToLowercase = (dateString: string) =>
  dateString.replace('AM', 'am').replace('PM', 'pm')

export const getDate = () => DateTime.local()
export const getDateFromObject = (obj: DateObject) =>
  DateTime.fromObject({ ...obj, zone: 'utc' })
export const getTimeFromString = (dateString: string) =>
  DateTime.fromString(dateString, timeFormatWithSuffix)
export const getDateFromISO = (date: string, timezoneName?: string) =>
  DateTime.fromISO(date, { zone: timezoneName })
export const getDateFromTimestamp = (timestamp: number) =>
  DateTime.fromMillis(Number(timestamp))
export const toISODate = (date: DateTime) => date.toISODate()
export const toISOString = (date: string) =>
  getDateFromISO(date).toJSDate().toISOString()
export const toFormattedISOString = (date: string, timezoneName?: string) =>
  getDateFromISO(date, timezoneName).toFormat('yyyy-MM-dd')
export const formatTime = (timeIn24Hr: string, format: string) =>
  timePeriodToLowercase(
    DateTime.fromFormat(timeIn24Hr, time24HourFormat).toFormat(
      format || timeFormatWithSuffix
    )
  )
export const formatTimeToLocaleValue = (timeIn24Hr: string, locale: string) => {
  const format = localesUsing24HourTime.includes(locale)
    ? time24HourFormat
    : timeFormatWithSuffix
  return timePeriodToLowercase(formatTime(timeIn24Hr, format))
}
export const getDateTimeFromFormat = curry((format: string, date: string) =>
  DateTime.fromFormat(date, format)
)
export const getDiffFromNowInMinutes = (date: any) =>
  Math.floor(getDateFromISO(date).diffNow().as('minutes'))
export const getDiffFromNowInDays = (date: any) =>
  Math.floor(getDateFromISO(date).diffNow().as('days'))
export const getEndOfMonth = (month, year) =>
  getDate().set({ year, month }).endOf('month')

export const isBefore = (
  date: string,
  dateToCompare: string,
  timezoneName?: string
) =>
  getDateFromISO(date, timezoneName) <
  getDateFromISO(dateToCompare, timezoneName)

export const isDateObjectBeforeToday = ({ year, day, month }) => {
  const date = getDateFromObject({
    year,
    month,
    day
  })
  const today = getDate()
  return date.isValid && date < today.startOf('day')
}

export const isAfter = (
  date: string,
  dateToCompare: string,
  timezoneName?: string
) =>
  getDateFromISO(date, timezoneName) >
  getDateFromISO(dateToCompare, timezoneName)

export const getDay = (date: string, timezoneName?: string) =>
  DateTime.fromISO(date, { zone: timezoneName }).weekday % 7

export const formatDate = curry(
  (format, date, timezoneName = 'Europe/London') =>
    timePeriodToLowercase(getDateFromISO(date, timezoneName).toFormat(format))
)

export const getAmendedDate = (
  date: string,
  amendments: DateObjectUnits,
  timezoneName?: string
) => getDateFromISO(date, timezoneName).set(amendments)

export const hasExpired = (
  date: string,
  timeToExpire: number,
  units: keyof DurationObjectUnits = 'hours'
) => Math.abs(getDateFromISO(date).diffNow().as(units)) >= timeToExpire

export const addDuration = (date, duration): DateTime =>
  getDateFromISO(date).plus(duration)
export const subtractDuration = (date, duration) =>
  getDateFromISO(date).minus(duration)

const isDateTimeToday = (comparisonDate: DateTime) => {
  const today = getDate()
  return (
    comparisonDate.isValid &&
    today.year === comparisonDate.year &&
    today.day === comparisonDate.day &&
    today.month === comparisonDate.month
  )
}

export const isDateToday = (date: string) => {
  const comparisonDate = getDateFromISO(date)
  return isDateTimeToday(comparisonDate)
}

export const isDateObjectToday = ({ year, day, month }) => {
  const date = getDateFromObject({
    year,
    month,
    day
  })
  return isDateTimeToday(date)
}

const defaultLocalesMap: LocalesMap = {
  'en-GB': {
    DAY_MONTH_YEAR: timeDayOfMonthYearFormat,
    MONTH_DAY: timeMonthDayFormat,
    DAY_MONTH: timeDayMonthFormat
  },
  'en-US': {
    DAY_MONTH_YEAR: timeDayOfMonthYearFormatForUS,
    MONTH_DAY: timeMonthDayFormat,
    DAY_MONTH: timeDayMonthFormat
  },
  'fr-FR': {
    DAY_MONTH_YEAR: timeDayOfMonthYearFormat,
    MONTH_DAY: timeMonthDayFormat,
    DAY_MONTH: timeDayMonthFormat
  }
}

export type FormatDateByLocaleProps = {
  locale?: AvailableLocales
  localesMap?: LocalesMap
  format?: AvailableFormats
}

export const formatDateByLocale = (
  timestamp: string | number | DateTime,
  {
    locale = 'en-GB',
    localesMap = defaultLocalesMap,
    format = 'DAY_MONTH_YEAR'
  }: FormatDateByLocaleProps = {}
): string => {
  const selectedLocale = localesMap[locale]

  if (selectedLocale) {
    return getDateFromTimestamp(Number(timestamp))
      .setLocale(locale)
      .toFormat(selectedLocale[format] ?? timeDayOfMonthYearFormat)
  } else {
    throw new Error(`locale ${locale} is not supported!`)
  }
}

export const getIsDateWithinDuration = ({
  year,
  day,
  month,
  duration,
  durationType = 'months'
}) => {
  const date = getDateFromObject({
    year,
    month,
    day
  })
  const today = getDate()
  const maxDate = addDuration(today, {
    [durationType]: duration
  })

  return (
    date.isValid &&
    date.startOf('day') < maxDate.startOf('day') &&
    date.startOf('day') >= today
  )
}

export const isDateObjectValid = ({ year, day, month }) => {
  if (year !== '' && month !== '' && day !== '') {
    const date = getDateFromObject({
      year,
      month,
      day
    })
    return date.isValid
  }

  return false
}

//params should be EPOCH timestamps
export const isDateWithinPeriod = (
  periodStart: number,
  periodEnd: number,
  dateToCheck: number
) => {
  if (!periodStart || !periodEnd || !dateToCheck) {
    return false
  }
  const currentTime = new Date(dateToCheck).toISOString()
  const convertedPeriodStart = toISOString(
    toISODate(getDateFromTimestamp(periodStart))
  )
  const convertedPeriodEnd = toISOString(
    toISODate(getDateFromTimestamp(periodEnd))
  )

  return (
    isBefore(currentTime, convertedPeriodEnd) &&
    isAfter(currentTime, convertedPeriodStart)
  )
}

export const isValidISODate = input => {
  const valid = DateTime.fromISO(input).isValid

  return valid
}

export const getYearStringFromISODate = input =>
  DateTime.fromISO(input).toFormat('yyyy')

export const getMonthStringFromISODate = input =>
  DateTime.fromISO(input).toFormat('MM')

export const getDayStringFromISODate = input =>
  DateTime.fromISO(input).toFormat('dd')

export const isToday = isoDate => {
  const todayDate = DateTime.local().toISODate()

  if (todayDate === isoDate) {
    return true
  }
  return false
}
