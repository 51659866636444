import { fireEvent } from '#src/common/lib/events'
import { ADD_TO_CART } from '../../../../topics'
import {
  getCoffeeSubUSClassicAddToCartPayload,
  getCoffeeSubUSPremiumAddToCartPayload
} from './utils/payloads'
import {
  US_CLASSIC_PLAN_FRAGMENT,
  US_PREMIUM_PLAN_FRAGMENT
} from '../../../../subscribers/analytics/constants'

export const coffeeSubsUSGetStartedEvent =
  buttonIdentifierOrSubscriptionPlan => {
    let coffeeSubEventPayload

    if (
      buttonIdentifierOrSubscriptionPlan.includes(
        US_PREMIUM_PLAN_FRAGMENT.toLowerCase()
      )
    ) {
      coffeeSubEventPayload = getCoffeeSubUSPremiumAddToCartPayload()
    } else if (
      buttonIdentifierOrSubscriptionPlan.includes(
        US_CLASSIC_PLAN_FRAGMENT.toLowerCase()
      )
    ) {
      coffeeSubEventPayload = getCoffeeSubUSClassicAddToCartPayload()
    }

    fireEvent(ADD_TO_CART, coffeeSubEventPayload)
  }
