import { SELECT_OPTION } from '../../../../topics'

export const selectOptionAdapter = ({ elements }) => {
  const gtmObj = {
    ecommerce: {},
    elements,
    event: SELECT_OPTION
  }

  return gtmObj
}
