/* eslint-disable no-console */

// This file configures the initialization of Sentry on the browser. The config you add here will be used whenever a
// page is visited. https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { getBrowserEnvironment } from '#lib/getBrowserEnv'

function initialiseSentry() {
  try {
    const sentryEnvironment = {
      development: 'dev',
      lab: 'lab',
      production: 'prod'
    }

    const currentEnvironment =
      sentryEnvironment[getBrowserEnvironment()] ||
      sentryEnvironment['development']

    // TODO: remove if statement below once we can release to production
    // For more information on configuration see https://docs.sentry.io/platforms/javascript/configuration/
    Sentry.init({
      // SENTRY_DSN obtained from https://sentry.io/settings/pret-a-manger/projects/web/keys/
      dsn: 'https://32845d9d5f4a4bc1b5877395c3c8a8eb@o1042630.ingest.sentry.io/6041076',

      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 0.05,
      maxBreadcrumbs: 20,

      environment: currentEnvironment,

      // Note: if you want to override the automatic release value, do not set a `release` value here - use the
      // environment variable `SENTRY_RELEASE`, so that it will also get attached to your source maps

      // Using Sampling to Filter Transaction Events
      // https://docs.sentry.io/platforms/javascript/configuration/sampling/
      tracesSampler: samplingContext => {
        if (samplingContext.location.href.endsWith('/faulty-client')) {
          return 1
        }
      },

      // Filtering Error Events
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#filtering-error-events
      beforeSend: (event, hint) => {
        const error = hint.originalException as any
        if (error?.traceId) {
          event.tags = { ...event.tags, traceId: error?.traceId }
        }

        return event
      }

      // De-cluttering Sentry
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      // allowUrls: [],
      // denyUrls: [],
      // ignoreErrors: [],
    })

    console.info(`Sentry initialised for ${currentEnvironment}`)
  } catch (err) {
    console.error({ err }, 'Sentry initialiseSentry() error')
  }
}

initialiseSentry()
