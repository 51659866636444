import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { Facebook, Instagram, Twitter } from '@pretamanger/component-library'
import { socialNetworks as socialNetworksPropType } from '@proptypes'
import Translation, { hasTranslationFor } from '../translation'
import { SocialContainer, SocialLink } from './styles'

const SocialNetworks = ({ showLabel, socialNetworks }) => {
  const { locale } = useRouter()

  const socialIcons = {
    facebook: Facebook,
    instagram: Instagram,
    twitter: Twitter
  }

  const labelExists = hasTranslationFor('footer.social.label', locale)

  return (
    <SocialContainer showLabel={showLabel}>
      {showLabel && labelExists && (
        <li>
          <Translation id='footer.social.label' />
        </li>
      )}
      {socialNetworks.map(({ name, link }) => {
        const Icon = socialIcons[name]
        return (
          <li key={name}>
            <SocialLink href={link} target='_blank' rel='noopener noreferrer'>
              <span className='sr-only'>{`Pret A Manger ${name}`}</span>
              <Icon aria-hidden='true' focusable='false' />
            </SocialLink>
          </li>
        )
      })}
    </SocialContainer>
  )
}

SocialNetworks.propTypes = {
  socialNetworks: socialNetworksPropType.isRequired,
  showLabel: PropTypes.bool
}

SocialNetworks.defaultProps = {
  showLabel: false
}

export default SocialNetworks
