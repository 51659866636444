import PropTypes from 'prop-types'
import NavigationItem from './item'
import { navigationItem } from '@proptypes'

const NavigationGroup = ({
  items,
  onNavigate,
  isPrimary,
  showEmployeePrivacyPolicy,
  isAuthenticated,
  ...props
}) => (
  <ul {...props}>
    {items.map(item => (
      <NavigationItem
        key={item.id}
        item={item}
        onNavigate={onNavigate}
        showEmployeePrivacyPolicy={showEmployeePrivacyPolicy}
        isAuthenticated={isAuthenticated}
      />
    ))}
  </ul>
)

NavigationGroup.propTypes = {
  isPrimary: PropTypes.bool,
  items: PropTypes.arrayOf(navigationItem).isRequired,
  onNavigate: PropTypes.func,
  showEmployeePrivacyPolicy: PropTypes.bool,
  isAuthenticated: PropTypes.bool
}

NavigationGroup.defaultProps = {
  isPrimary: false,
  onNavigate: () => {},
  showEmployeePrivacyPolicy: false,
  isAuthenticated: false
}

export default NavigationGroup
