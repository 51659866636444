import React from 'react'
import { Overlay } from './styles'
import { LoadingIndicator } from '@pretamanger/component-library'
import Translation from '../translation'

const LoadingContainer = (
  <Overlay data-testid='overlay'>
    <LoadingIndicator size='large' on={true}>
      <LoadingIndicator.On>
        <Translation id='common.loading' />
      </LoadingIndicator.On>
    </LoadingIndicator>
  </Overlay>
)

interface Props {
  loading: boolean
}

export const LoadingOverlay: React.FC<Props> = ({ loading, children }) => (
  <>
    {loading && LoadingContainer}
    {children}
  </>
)
