import PropTypes from 'prop-types'
import { useState } from 'react'
import { Chevron } from '@pretamanger/component-library'
import Translation, { translate } from '../../../components/translation'
import {
  OrderSlotBannerWrapper,
  OrderSlotContentContainer,
  MobileSummary,
  SummaryButton,
  OrderSlotContent,
  OrderType,
  OrderLocation,
  OrderSlot,
  InlineStar,
  InlineMarker,
  InlineClock,
  ShopName,
  ShopAddress
} from './styles'
import { formatShopAddress } from '#lib/format-address'
import { useAppSelector } from '../../../../state/redux-hooks'
import { bagSelector } from '../../../../state/bag-state-slice'
import { ChangeShopLink } from '#src/checkout/components/change-shop-link/change-shop-link'
export const getFormattedOrderDetails = (orderDetails, displayTime) => {
  const {
    fulfillingShopName: name,
    fulfillingShopAddress: address,
    orderType,
    pickupTimeStart
  } = orderDetails || {}
  return {
    orderType,
    shop: formatShopAddress({ name, address }),
    time: displayTime && pickupTimeStart
  }
}

const OrderSlotBanner = ({ displayPickupTime }) => {
  const bag = useAppSelector(bagSelector)
  const [orderSlotContentVisible, setOrderSlotContentVisible] = useState(false)
  const { orderType, time, shop } = getFormattedOrderDetails(
    bag.orderDetails,
    displayPickupTime
  )

  return (
    <OrderSlotBannerWrapper
      aria-label={translate('order-slot-banner.title')?.value}
    >
      {orderType != 'outpost' && (
        <OrderSlotContentContainer>
          <MobileSummary>
            <SummaryButton
              styleType='tertiary'
              className='closed'
              onClick={() =>
                setOrderSlotContentVisible(!orderSlotContentVisible)
              }
            >
              <Translation id={`order-slot-banner.order-type.${orderType}`} />
              <Chevron
                type={orderSlotContentVisible ? 'up' : 'down'}
                colour='grey-700'
                aria-hidden='true'
              />
            </SummaryButton>
          </MobileSummary>
          <OrderSlotContent
            orderSlotContentVisibility={orderSlotContentVisible}
          >
            <OrderType>
              <Translation id={`order-slot-banner.order-type.${orderType}`} />
            </OrderType>
            <OrderLocation>
              <InlineMarker size='small' aria-hidden='true' focusable='false' />
              <InlineStar
                size='small'
                colour='grey-500'
                aria-hidden='true'
                focusable='false'
              />
              <ShopName>{shop.name}</ShopName>
              <ShopAddress>{shop.address}</ShopAddress>
            </OrderLocation>
            {time && (
              <OrderSlot>
                <InlineStar
                  size='small'
                  colour='grey-500'
                  aria-hidden='true'
                  focusable='false'
                />
                <InlineClock size='small' aria-hidden='true' />
                <Translation
                  id='order-slot-banner.collection-time'
                  tokens={{ time }}
                />
              </OrderSlot>
            )}
            <ChangeShopLink />
          </OrderSlotContent>
        </OrderSlotContentContainer>
      )}
    </OrderSlotBannerWrapper>
  )
}

OrderSlotBanner.propTypes = {
  displayPickupTime: PropTypes.bool
}

OrderSlotBanner.defaultProps = {
  displayPickupTime: true
}

export default OrderSlotBanner
