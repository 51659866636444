import cookies from 'js-cookie'
import { parseCookies } from 'nookies'
import {
  orderAheadBagIdHeader,
  subscriptionBagIdHeader,
  giftBagIdHeader
} from '#constants'

export const getBagIdFromCtx = ctx => {
  const cookies = parseCookies(ctx)
  return cookies[orderAheadBagIdHeader]
}

export const getBagId = () => cookies.get(orderAheadBagIdHeader)

export const getSubscriptionBagIdFromCtx = ctx => {
  const cookies = parseCookies(ctx)
  return cookies[subscriptionBagIdHeader]
}

export const getSubscriptionBagId = () => cookies.get(subscriptionBagIdHeader)
export const getGiftBagId = () => cookies.get(giftBagIdHeader)

/**
 * @deprecated use named export instead
 */
export default getBagId
