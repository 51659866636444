import PropTypes from 'prop-types'
import {
  FirstLevelGroup,
  LanguageSwitchContainer,
  AuthCTAContainer,
  Container
} from './styles'
import { navigationItem } from '@proptypes'
import LanguageSwitch from '../layout/language-switch'
import AuthCTA from '#src/common/app/components/auth-cta'

const Navigation = ({
  items,
  onNavigate,
  displayLanguageSwitcher,
  displayUserAccountLink,
  ariaLabel,
  isPrimary,
  showEmployeePrivacyPolicy,
  isAuthenticated
}) => {
  return (
    <nav data-testid='primary-nav' aria-label={ariaLabel}>
      <FirstLevelGroup
        items={items}
        onNavigate={onNavigate}
        isPrimary={isPrimary}
        showEmployeePrivacyPolicy={showEmployeePrivacyPolicy}
        isAuthenticated={isAuthenticated}
      />
      <Container>
        {displayUserAccountLink && (
          <AuthCTAContainer>
            <AuthCTA onClick={onNavigate} />
          </AuthCTAContainer>
        )}
        {displayLanguageSwitcher && (
          <LanguageSwitchContainer>
            <LanguageSwitch />
          </LanguageSwitchContainer>
        )}
      </Container>
    </nav>
  )
}

Navigation.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  displayLanguageSwitcher: PropTypes.bool,
  displayUserAccountLink: PropTypes.bool,
  isPrimary: PropTypes.bool,
  items: PropTypes.arrayOf(navigationItem).isRequired,
  onNavigate: PropTypes.func,
  showEmployeePrivacyPolicy: PropTypes.bool,
  isAuthenticated: PropTypes.bool
}

Navigation.defaultProps = {
  displayLanguageSwitcher: false,
  displayUserAccountLink: false,
  isPrimary: false,
  onNavigate: () => {},
  showEmployeePrivacyPolicy: false,
  isAuthenticated: false
}

export default Navigation
