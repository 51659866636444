import { removeCurrency } from './remove-currency'

export const mapCrossSellProducts = products =>
  products.map(
    ({
      categories = [],
      name,
      price,
      recommendationId,
      recommendationPosition,
      sku
    }) => {
      return {
        name,
        id: sku,
        price: removeCurrency(price.localisedPrice),
        brand: 'Pret',
        category: categories.length ? categories[0].name : '',
        list: 'Cross-sell',
        position: recommendationPosition,
        dimension4: recommendationId
      }
    }
  )
