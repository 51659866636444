import { Critical } from '@pretamanger/component-library'
import PropTypes from 'prop-types'
import { RedemptionErrorWrapper } from './styles'

const RedemptionError = ({ errorMessage }) => {
  return (
    <RedemptionErrorWrapper>
      <div>
        <Critical className='mr-3' />
      </div>
      <p className='text-errorRed text-sm md:w-2/3'>{errorMessage}</p>
    </RedemptionErrorWrapper>
  )
}

RedemptionError.propTypes = {
  errorMessage: PropTypes.string.isRequired
}

export default RedemptionError
