import { httpClient as fetch } from '#lib/http-client'
import { localeHeader } from '#src/common/constants'
import { getTokenSilently } from '../auth0/auth0-functions'
import { RequestExtend } from './types'

export const getRewardsSummary = async (
  baseUrl: string,
  locale: string
): Promise<any> => {
  const accessToken = await getTokenSilently()
  const params: RequestExtend = {
    method: 'GET',
    json: true,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      [localeHeader]: locale
    }
  }
  const response = await fetch(`${baseUrl}/v1/wallet/reward/summary`, params)

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return await response.json()
}
