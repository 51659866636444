const settings = require('../settings.json')
const localeArray = require('../locales-list.json')

const cspMap = {
  default: 'default-src',
  script: 'script-src',
  frame: 'frame-src',
  style: 'style-src',
  font: 'font-src',
  img: 'img-src'
}

const contentSecurityPolicy = locale => {
  const settingsLocale = settings[locale]
  return Object.entries(cspMap).reduce((csp, [key, value]) => {
    const addPolicy =
      settingsLocale && settingsLocale.contentSecurityPolicy[key]
    if (!addPolicy) {
      return csp
    }
    csp += `${value} ${addPolicy}`
    return `${csp}; `
  }, '')
}

const setHeaders = (locale, referrerPolicy = 'same-origin') => {
  return [
    {
      key: 'Strict-Transport-Security',
      value: '31536000'
    },
    {
      key: 'Content-Security-Policy',
      value: contentSecurityPolicy(locale)
    },
    {
      key: 'X-Frame-Options',
      value: 'SAMEORIGIN'
    },
    {
      key: 'X-XSS-Protection',
      value: '1; mode=block'
    },
    {
      key: 'X-Content-Type-Options',
      value: 'nosniff'
    },
    {
      key: 'Referrer-Policy',
      value: referrerPolicy
    }
  ]
}

const setCustomHeaders = () =>
  localeArray.reduce((acc, locale) => {
    const headers = [
      {
        source: `/${locale}/:path*`,
        locale: false,
        headers: [...setHeaders(locale)]
      },
      {
        source: `/${locale}/click-and-collect/checkout`,
        locale: false,
        headers: [...setHeaders(locale, 'origin-when-cross-origin')]
      },
      {
        source: `/${locale}/click-and-collect/checkout/order-details`,
        locale: false,
        headers: [...setHeaders(locale, 'origin-when-cross-origin')]
      }
    ]
    return [...acc, ...headers]
  }, [])

module.exports = { setCustomHeaders, contentSecurityPolicy }
