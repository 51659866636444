import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { translate } from '#src/common/components/translation'
import {
  BagContainer,
  StyledBagIcon
} from '#src/common/app/components/bag-cta/styles'

const BagButton = ({ itemCount, onClick }) => {
  const { locale } = useRouter()
  const title = translate('bag.heading.title', locale)?.value
  const bagStatus =
    itemCount === null
      ? translate('bag.loading', locale)?.value
      : translate('bag.heading.title', locale, { count: String(itemCount) })
          ?.value
  return (
    <div role='region' aria-label={title}>
      <BagContainer
        data-testid='bag-container'
        onClick={onClick}
        aria-label={bagStatus}
        itemCount={itemCount}
      >
        <StyledBagIcon itemCount={itemCount} />
      </BagContainer>
    </div>
  )
}

BagButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  itemCount: PropTypes.number
}

BagButton.defaultProps = {
  itemCount: 0
}

export default BagButton
