import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { getRewards } from '#src/common/lib/loyalty/get-rewards'
import { getPastRewards } from '#src/common/lib/loyalty/get-past-rewards'
import { getRewardsSummary } from '#src/common/lib/loyalty/get-rewards-summary'
import { Rewards, RewardSummary } from '#src/typings/rewards'

export type RewardsState = {
  rewards: Rewards
  rewardsSummary: RewardSummary
  isLoading: boolean
  error: boolean
  isLoaded: boolean
}

const initialState: RewardsState = {
  rewardsSummary: {
    noOfBonusStars: 0,
    noOfNormalStars: 0,
    noOfRewards: 0,
    totalNoOfStars: 0,
    starsLeftToReward: 0,
    noOfPendingRewards: 0,
    totalNoOfRewards: 0
  },
  rewards: {
    firstRewardExpiryDate: null,
    firstReward: false,
    rewards: []
  },
  isLoading: false,
  isLoaded: false,
  error: false
}

export const fetchRewards = createAsyncThunk<
  RewardsState,
  void,
  { state: RootState }
>('rewards/fetchRewards', async (_, { getState }) => {
  const { env = {} } = getState()
  return await getRewards(env.platformApiHost)
})

export const fetchPastRewards = createAsyncThunk<
  RewardsState,
  void,
  { state: RootState }
>('rewards/fetchPastRewards', async (_, { getState }) => {
  const { env = {} } = getState()
  return await getPastRewards(env.platformApiHost)
})

export const fetchRewardsSummary = createAsyncThunk<
  RewardsState,
  { locale: string },
  { state: RootState }
>('rewards/fetchRewardsSummary', async ({ locale }, { getState }) => {
  const { env = {} } = getState()
  return await getRewardsSummary(env.platformApiHost, locale)
})

export const rewardsStateSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRewards.pending, (state: RewardsState) => {
      state.isLoading = true
      state.error = false
    })
    builder.addCase(fetchRewards.rejected, (state: RewardsState) => {
      state.isLoading = false
      state.error = true
    })
    builder.addCase(
      fetchRewards.fulfilled,
      (state: RewardsState, action: PayloadAction<any>) => {
        state.rewards = action.payload
        state.isLoaded = true
        ;(state.isLoading = false), (state.error = false)
      }
    )
    builder.addCase(fetchPastRewards.pending, (state: RewardsState) => {
      state.isLoading = true
      state.error = false
    })
    builder.addCase(fetchPastRewards.rejected, (state: RewardsState) => {
      state.isLoading = false
      state.error = true
    })
    builder.addCase(
      fetchPastRewards.fulfilled,
      (state: RewardsState, action: PayloadAction<any>) => {
        state.rewards = action.payload
        state.isLoaded = true
        ;(state.isLoading = false), (state.error = false)
      }
    )
    builder.addCase(fetchRewardsSummary.pending, (state: RewardsState) => {
      state.isLoading = true
      state.error = false
    })
    builder.addCase(fetchRewardsSummary.rejected, (state: RewardsState) => {
      state.isLoading = false
      state.error = true
    })
    builder.addCase(
      fetchRewardsSummary.fulfilled,
      (state: RewardsState, action: PayloadAction<any>) => {
        ;(state.rewardsSummary = action.payload), (state.isLoading = false)
        state.error = false
        state.isLoaded = true
      }
    )
  }
})

export const rewardsSelector = ({ rewards }: RootState): RewardsState => rewards
