import { getLogger } from '#api/logger-with-context'
import {
  anonymousIdHeader,
  authHeader,
  localeHeader,
  marketIdHeader,
  orderAheadBagIdHeader,
  serviceFeeFeature,
  orderTypes
} from '#constants'
import { provideAnonymousId } from '#lib/provide-anonymous-id'
import platformClient, {
  targetServiceEnum
} from '#src/common/lib/platform-client'
import { getMarketDetailsFromLocale } from '#src/common/lib/get-market'
import { httpClient, getNonTransactionalHeaders } from '#lib/http-client'
import { handleBagError } from '#lib/utils'

const flagName = 'CLICK_AND_COLLECT_FEE'

const logRef = 'Bag API - Platform-API::'

const createRemoveAction = item => ({
  type: 'UPDATE_ITEM_QUANTITY',
  payload: {
    itemId: item.id,
    quantity: 0
  }
})

const createBatchActions = payload => ({
  type: 'BATCH_ACTIONS',
  payload
})

const isClickAndCollectFeeEnabled = async marketId => {
  const result = await httpClient('/api/flags', {
    method: 'POST',
    body: JSON.stringify({ name: flagName, country: marketId }),
    headers: getNonTransactionalHeaders()
  })
  return result.json()
}

const updateBag = async (bagId, locale, action, authToken) => {
  const marketId = getMarketDetailsFromLocale(locale).id
  const clickAndCollectFeeFlag = await isClickAndCollectFeeEnabled(marketId)
  const url = `/bag/${bagId}`
  const version = 'v2'
  const method = 'PUT'
  let result
  try {
    result = await platformClient(url, {
      method,
      version,
      headers: {
        [localeHeader]: locale,
        [anonymousIdHeader]: provideAnonymousId(),
        [marketIdHeader]: marketId,
        [serviceFeeFeature]: clickAndCollectFeeFlag,
        ...(authToken && { [authHeader]: `Bearer ${authToken}` })
      },
      body: JSON.stringify({ action })
    })
  } catch (error) {
    handleBagError(error, locale)
  }
  return result
}

const _setGuestCustomerDetails = type => async (bagId, locale, customer) => {
  const log = getLogger()
  log.info({ bag: { bagId } }, `${logRef} start setGuestCustomerDetails`)
  const action = {
    type,
    payload: { customer }
  }
  const updatedBag = await updateBag(bagId, locale, action)
  log.info({ bag: updatedBag }, `${logRef} end setGuestCustomerDetails`)
  return updatedBag
}

export const createBag = async (locale, { authToken, ...options }) => {
  const log = getLogger()
  const marketId = getMarketDetailsFromLocale(locale).id
  const clickAndCollectFeeFlag = await isClickAndCollectFeeEnabled(marketId)

  log.info({ ...options }, `${logRef} start createBag`)

  const bag = await platformClient('/bag', {
    method: 'POST',
    version: 'v2',
    headers: {
      [localeHeader]: locale,
      [anonymousIdHeader]: provideAnonymousId(),
      [marketIdHeader]: marketId,
      [serviceFeeFeature]: clickAndCollectFeeFlag,
      ...(authToken && { [authHeader]: `Bearer ${authToken}` })
    },
    body: JSON.stringify(options)
  })
  log.info({ bag }, `${logRef} end createBag`)
  return bag
}

export const getBagById = async (locale, bagId) => {
  const log = getLogger()
  log.info({ bagId }, `${logRef} start getBagById`)

  const bag = await platformClient(`/bag/${bagId}`, {
    method: 'GET',
    headers: {
      [localeHeader]: locale
    }
  })
  log.info({ bag }, `${logRef} end getBagById`)
  return bag
}

export const createSubscriptionBag = async (
  locale,
  authToken,
  subscriptionSku,
  shippingAddress,
  coffeeSubsCommerceToolsRemoved
) => {
  const log = getLogger()
  log.info(`${logRef} start createSubscriptionBag`)
  const body = {
    ...(subscriptionSku && { subscriptionSku }),
    shippingAddress
  }
  const url = '/bag/create-subscription'
  const bag = await platformClient(url, {
    version: coffeeSubsCommerceToolsRemoved ? 'v3' : 'v2',
    method: 'POST',
    headers: {
      [localeHeader]: locale,
      [anonymousIdHeader]: provideAnonymousId(),
      [marketIdHeader]: getMarketDetailsFromLocale(locale).id,
      ...(authToken && { [authHeader]: `Bearer ${authToken}` })
    },
    body: JSON.stringify(body)
  })

  log.info({ bag }, `${logRef} end createSubscriptionBag`)

  return bag
}

export const createGiftBag = async ({ locale, authToken, payload }) => {
  const log = getLogger()
  log.info(`${logRef} start createGiftBag`)
  const body = payload
  const url = '/bags/create-gift'
  const bag = await platformClient(url, {
    version: 'v2',
    method: 'POST',
    headers: {
      [localeHeader]: locale,
      [marketIdHeader]: getMarketDetailsFromLocale(locale).id,
      ...(authToken && { [authHeader]: `Bearer ${authToken}` })
    },
    body: JSON.stringify(body)
  })

  log.info({ bag }, `${logRef} end createGiftBag`)

  return bag
}

export const createZeroValueOrder = async (
  bagId,
  locale,
  customer,
  authToken
) => {
  const log = getLogger()
  log.info(`${logRef} start createZeroValueOrder`)
  const url = authToken
    ? '/bag/authenticated/zero-payments'
    : '/bag/zero-payments'
  const action = {
    type: 'UPDATE_GUEST_CUSTOMER_DETAILS',
    payload: { customer }
  }
  const bag = await platformClient(url, {
    targetService: targetServiceEnum.CHECKOUT_API_SERVICE,
    method: 'POST',
    body: JSON.stringify({ action }),
    headers: {
      [localeHeader]: locale,
      [orderAheadBagIdHeader]: bagId,
      ...(authToken && {
        [anonymousIdHeader]: provideAnonymousId(),
        [authHeader]: `Bearer ${authToken}`
      })
    }
  })
  log.info({ bag }, `${logRef} end createZeroValueOrder`)
  return bag
}

export const addItemToBag = async (bagId, locale, payload, authToken) => {
  const log = getLogger()
  log.info({ bagId, sku: payload.sku }, `${logRef} start addItemToBag`)
  const action = {
    type: 'ADD_ITEM',
    payload
  }
  const updatedBag = await updateBag(bagId, locale, action, authToken)
  log.info({ bag: updatedBag }, `${logRef} end addItemToBag`)
  return updatedBag
}

export const addItemsToBag = async (bagId, locale, payload, authToken) => {
  const log = getLogger()
  log.info(
    { bag: { bagId }, skuList: payload },
    `${logRef} start addItemsToBag`
  )
  const action = {
    type: 'ADD_ITEMS',
    payload
  }
  const updatedBag = await updateBag(bagId, locale, action, authToken)
  log.info({ bag: updatedBag }, `${logRef} end addItemsToBag`)
  return updatedBag
}

export const addCustomisedItemToBag = async (
  bagId,
  locale,
  payload,
  authToken
) => {
  const log = getLogger()
  log.info({ bag: { bagId } }, `${logRef} start addCustomisedItemToBag`)
  const action = {
    type: 'ADD_CUSTOMISED_ITEM',
    payload
  }
  const updatedBag = await updateBag(bagId, locale, action, authToken)
  log.info({ bag: updatedBag }, `${logRef} end addCustomisedItemToBag`)
  return updatedBag
}

export const removeItems = async (bagId, locale, items) => {
  const log = getLogger()
  log.info({ bag: { bagId } }, `${logRef} start removeItems`)
  const action = createBatchActions(items.map(createRemoveAction))
  const updatedBag = await platformClient(`/bag/${bagId}`, {
    method: 'POST',
    body: JSON.stringify({ action }),
    headers: {
      [localeHeader]: locale
    }
  })
  log.info({ bag: updatedBag }, `${logRef} end removeItems`)
  return updatedBag
}

export const setItemQuantity = async (bagId, locale, payload, authToken) => {
  const log = getLogger()
  log.info({ bag: { bagId } }, `${logRef} start setItemQuantity`)
  const action = {
    type: 'UPDATE_ITEM_QUANTITY',
    payload
  }
  const updatedBag = await updateBag(bagId, locale, action, authToken)
  log.info({ bag: updatedBag }, `${logRef} end setItemQuantity`)
  return updatedBag
}

export const removeDiscount = async (bagId, locale) => {
  const log = getLogger()
  log.info({ bag: { bagId } }, `${logRef} start removeDiscount`)
  const action = {
    type: 'REMOVE_DISCOUNT',
    payload: {}
  }
  const updatedBag = await updateBag(bagId, locale, action)
  log.info({ bag: updatedBag }, `${logRef} end removeDiscount`)
  return updatedBag
}

export const setGuestCustomerDetails = _setGuestCustomerDetails(
  'UPDATE_GUEST_CUSTOMER_DETAILS'
)

export const setSubscriptionGuestCustomerDetails = _setGuestCustomerDetails(
  'UPDATE_SUBSCRIPTION_GUEST_CUSTOMER_DETAILS'
)

export const setTimeSlot = async (
  bagId,
  locale,
  pickupTimeStart,
  pickupTimeEnd,
  authToken
) => {
  const log = getLogger()
  log.info({ bag: { bagId } }, `${logRef} start setTimeSlot`)
  const action = {
    type: 'SET_TIMESLOT',
    payload: { pickupTimeStart, pickupTimeEnd }
  }
  const updatedBag = await updateBag(bagId, locale, action, authToken)
  log.info({ bag: updatedBag }, `${logRef} end setTimeSlot`)
  return updatedBag
}

export const setFulfillingShop = async (
  bagId,
  locale,
  shopId,
  orderType,
  outpostId,
  authToken
) => {
  const log = getLogger()
  const hasCorrectOrderType = orderTypes.includes(orderType)
  log.info({ bag: { bagId }, shopId }, `${logRef} start setFulfillingShop`)
  const action = {
    type: 'SET_SHOP',
    payload: {
      shopId,
      ...(hasCorrectOrderType ? { orderType } : {}),
      ...(outpostId && { outpostId })
    }
  }
  const updatedBag = await updateBag(bagId, locale, action, authToken)
  log.info({ bag: updatedBag }, `${logRef} end setFulfillingShop`)
  return updatedBag
}

export const refreshBag = async (bagId, locale, authToken) => {
  const log = getLogger()
  log.info({ bag: { bagId } }, `${logRef} start refreshBag`)
  const action = { type: 'RECALCULATE_BAG' }
  const updatedBag = await updateBag(bagId, locale, action, authToken)
  log.info({ bag: updatedBag }, `${logRef} end refreshBag`)
  return updatedBag
}

export const setVoucherCodeStatus = async (
  bagId,
  locale,
  voucherCodeStatus
) => {
  const log = getLogger()
  log.info({ bag: { bagId } }, `${logRef} start setVoucherCodeStatus`)
  const action = {
    type: 'SET_VOUCHER_CODE_STATUS',
    payload: { voucherCodeStatus }
  }
  const updatedBag = await updateBag(bagId, locale, action)
  log.info({ bag: updatedBag }, `${logRef} end setVoucherCodeStatus`)
  return updatedBag
}

export const updateCutleryOption = async (
  bagId,
  locale,
  isCutleryAdded,
  authToken
) => {
  const log = getLogger()
  const url = `/bags/${bagId}/with-cutlery`
  const version = 'v2'
  const method = 'PUT'

  const payload = {
    addCutlery: isCutleryAdded
  }
  log.info(
    { bag: { bagId }, isCutleryAdded },
    `${logRef} start updateCutleryOption`
  )
  try {
    const updatedBag = await platformClient(url, {
      method,
      version,
      headers: {
        ...(authToken && {
          [authHeader]: `Bearer ${authToken}`
        }),
        [localeHeader]: locale
      },
      body: JSON.stringify(payload)
    })
    log.info({ bag: updatedBag }, `${logRef} end updateCutleryOption`)
    return updatedBag
  } catch (error) {
    handleBagError(error, locale)
  }
}
