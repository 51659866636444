import curry from 'ramda/src/curry'
import {
  localeMap,
  fallbackLocaleCode,
  localeToCurrencyMap,
  localeToCookieLanguageMap,
  marketDetails
} from '#constants'

export const mapLocale = (locale, reverse) => {
  const mappedLocale = localeMap.find(
    l => l[reverse ? 'code' : 'alias'] === locale
  )
  return mappedLocale
    ? mappedLocale[reverse ? 'alias' : 'code']
    : normaliseLocale(locale)
}

export const normaliseLocale = (locale = fallbackLocaleCode) => {
  const parts = locale.split('-')
  const lang = parts.shift()
  const country = parts.pop()
  const territory = parts[0]
  if (country) {
    const arrayOfParts = [lang.toLowerCase(), country.toUpperCase()]
    if (territory) {
      arrayOfParts.splice(
        1,
        0,
        `${territory.charAt(0).toUpperCase()}${territory
          .slice(1)
          .toLowerCase()}`
      )
    }
    return arrayOfParts.join('-')
  } else {
    return locale
  }
}

export const checkIfLocaleFormatIsValid = locale => {
  const localeMatched = locale.match('(^|[a-z])-[A-Z]{2}')
  return localeMatched
}

const checkIfLocaleIsValid = locale => {
  const localeMatched = locale.match('(^|[a-z])-[A-Z]{2}')
  if (!localeMatched) {
    throw new Error('Locale ' + locale + ' does not match expected format')
  }
}

export const getCountryFromLocale = locale => {
  checkIfLocaleIsValid(locale)
  const countryCode = locale.split('-')[1]
  return countryCode
}

export const getLanguageFromLocale = locale => {
  checkIfLocaleIsValid(locale)
  const countryCode = locale.split('-')[0]
  return countryCode
}

export const localisePrice = (
  price,
  locale,
  { style = 'currency', minimumFractionDigits } = {}
) => {
  const rawPrice = price.centAmount
    ? price.centAmount / 10 ** price.fractionDigits
    : 0

  return new Intl.NumberFormat(locale, {
    style,
    minimumFractionDigits,
    currency: price.currencyCode || localeToCurrencyMap[locale]
  }).format(rawPrice)
}

export const setPriceForLocale = curry((locale, price) => ({
  centAmount: price.centAmount,
  currencyCode: price.currencyCode,
  localisedPrice: localisePrice(price, locale)
}))

export const getLocaleToCookieLanguage = locale => {
  return (
    localeToCookieLanguageMap[locale] ||
    localeToCookieLanguageMap[fallbackLocaleCode]
  )
}

export const applePayLocalePriceFormatter = (price = '', locale) =>
  locale === marketDetails.FR.locale ? price.replace(/,/, '.') : price
