import { UserRoles } from '#src/state/profile-state-slice'

export const getButtonCopy = (total: number = 0): string => {
  if (total === 0) {
    return 'rewards.unlocked.start'
  } else if (total === 1) {
    return 'rewards.unlocked.singular'
  }
  return 'rewards.unlocked.multiple'
}

// Employee Gold Card Filter
export const USER_ROLES = {
  EMPLOYEE: 'EMPLOYEE',
  CUSTOMER: 'CUSTOMER'
}
export const isRoleHasAccess = (userRoles: Array<UserRoles>, role: string) => {
  return (
    userRoles &&
    !!userRoles.find((roleInfo: UserRoles) => roleInfo.name === role)
  )
}
