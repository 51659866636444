/* eslint-disable camelcase */
import {
  SUBSCRIPTION_PAUSE_REMOVED,
  SUBSCRIPTION_CANCELLATION_REMOVED,
  SUBSCRIPTION_CHANGED,
  SUBSCRIPTION_REACTIVATED,
  BEGIN_CHECKOUT,
  ADD_PAYMENT_INFO,
  PAYMENT_FAILURE,
  SUBSCRIPTION_ACTIVATED,
  ADD_TO_CART
} from '#src/common/lib/events/topics'

const paymentMethods = {
  ['card']: 'Credit Card'
}

export const subscriptionAdapter = ({ ...elements }) => {
  let gtmObj = {}
  switch (elements.event) {
    case SUBSCRIPTION_CHANGED:
      gtmObj = {
        change_type: elements.change_type,
        event: elements.event
      }
      break
    case SUBSCRIPTION_CANCELLATION_REMOVED:
    case SUBSCRIPTION_PAUSE_REMOVED:
    case SUBSCRIPTION_REACTIVATED:
      gtmObj = {
        event: elements.event,
        subscription_plan: elements.subscription_plan,
        subscription_country: elements.subscription_country,
        subscription_price: elements.subscription_price,
        journey: elements.journey
      }
      if (elements.event === SUBSCRIPTION_REACTIVATED) {
        gtmObj = {
          ...gtmObj,
          payment_type: paymentMethods[elements.payment_type]
        }
      }

      break
    case BEGIN_CHECKOUT:
    case ADD_PAYMENT_INFO:
    case PAYMENT_FAILURE:
    case SUBSCRIPTION_ACTIVATED:
    case ADD_TO_CART:
      gtmObj = {
        ...elements
      }
      break
    default:
      gtmObj = {
        reason: elements.reason,
        event: elements.event
      }
      break
  }

  return gtmObj
}
