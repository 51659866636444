import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as bagApi from '#lib/api/bag'
import { getTokenSilently } from '#lib/auth0/auth0-functions'
import { BagState } from './types'
import { RootState } from './store'

export interface SubscriptionBagState {
  bag?: BagState
  isError: boolean
  isLoading: boolean
  hasExistingBag: boolean
}

const getProductQuantities = (lineItems = []) =>
  lineItems.reduce((totalCount, item) => {
    const currentCount = totalCount[item.productId] || 0
    totalCount[item.productId] = currentCount + item.quantity
    return totalCount
  }, {})

const addProductQuantities = bag => {
  if (bag.lineItems) {
    bag.productQuantities = getProductQuantities(bag.lineItems)
  }
  return bag
}

const initialState: SubscriptionBagState = {
  isError: false,
  isLoading: false,
  hasExistingBag: false
}

export const createSubscriptionBag = createAsyncThunk<
  BagState,
  {
    locale: string
    subscriptionSku?: string
    shippingAddress: any
    setBagId: (bagId: string) => void
    setSubscriptionBagError: (errorMessage: string) => void
    coffeeSubsCommerceToolsRemoved: boolean
  },
  { state: RootState }
>(
  'subscriptionBag/add',
  async (
    {
      locale,
      subscriptionSku,
      shippingAddress,
      setBagId,
      setSubscriptionBagError,
      coffeeSubsCommerceToolsRemoved
    },
    { getState }
  ) => {
    const { account } = getState()
    const authToken = account?.isAuthenticated ? await getTokenSilently() : null

    try {
      const createdBag = await bagApi.createSubscriptionBag(
        locale,
        authToken,
        subscriptionSku,
        shippingAddress,
        coffeeSubsCommerceToolsRemoved
      )
      setBagId(createdBag.bagId)
      return addProductQuantities(createdBag)
    } catch (err) {
      setSubscriptionBagError(
        err?.responseJson?.error || 'create subscription bag error'
      )
    }
  }
)

export interface CustomerAddress {
  streetNumber: string
  streetName: string
  city: string
  country: string
  postalCode: string
}

export interface CustomerDetails {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: CustomerAddress
  feedback: boolean
  marketing: boolean
  visitFrequency: string
}

export const updateSubscriptionCustomerDetails = createAsyncThunk<
  BagState,
  { customerDetails: CustomerDetails; locale: string },
  { state: RootState }
>(
  'subscriptionBag/updateCustomerDetails',
  async ({ customerDetails, locale }, { getState }) => {
    const { subscriptionBag } = getState()
    const updatedBag = await bagApi.setSubscriptionGuestCustomerDetails(
      subscriptionBag.bag.bagId,
      locale,
      customerDetails
    )
    return updatedBag
  }
)

export const loadSubscriptionBag = createAsyncThunk<
  BagState,
  { locale: string; currentBagId: string }
>('subscriptionBag/load', async ({ locale, currentBagId }) => {
  const currentBag = await bagApi.getBagById(locale, currentBagId)
  return addProductQuantities(currentBag)
})

export const subscriptionBagStateSlice = createSlice({
  name: 'subscriptionBag',
  initialState,
  reducers: {
    bagLoading: state => {
      state.isLoading = true
    }
  },
  extraReducers: builder => {
    builder.addCase(createSubscriptionBag.fulfilled, (state, action) => {
      state.isLoading = false
      state.hasExistingBag = true
      state.bag = action.payload
    })
    builder.addCase(createSubscriptionBag.pending, (state, _) => {
      state.isLoading = true
    })
    builder.addCase(createSubscriptionBag.rejected, (state, _) => {
      state.isLoading = false
      state.isError = true
    })

    builder.addCase(
      updateSubscriptionCustomerDetails.fulfilled,
      (state, action) => ({
        ...state,
        bag: action.payload,
        isLoading: false
      })
    )
    builder.addCase(updateSubscriptionCustomerDetails.pending, (state, _) => {
      state.isLoading = true
    })
    builder.addCase(updateSubscriptionCustomerDetails.rejected, (state, _) => {
      state.isLoading = false
    })

    builder.addCase(loadSubscriptionBag.fulfilled, (state, action) => {
      state.isLoading = false
      state.hasExistingBag = true
      state.bag = action.payload
    })
    builder.addCase(loadSubscriptionBag.pending, (state, _) => {
      state.isLoading = true
    })
    builder.addCase(loadSubscriptionBag.rejected, (state, _) => {
      state.isLoading = false
      state.hasExistingBag = false
    })
  }
})

export const subscriptionBagSelector = ({
  subscriptionBag
}: RootState): SubscriptionBagState => subscriptionBag
