import { client } from '../auth0/client'
import { getTokenSilently } from '../auth0/auth0-functions'
import { getEnvVars } from '#src/common/lib/get-env-vars'

export const getAvailablePlans = async ({ planId, currencyCode }) => {
  const token = await getTokenSilently()
  const { coffeeSubsManageBaseUrl } = await getEnvVars()

  const { availablePlans } = (await client(
    `/v1/manage/available-plans/${currencyCode}?currentPlanId=${planId}`,
    {
      apiUrl: coffeeSubsManageBaseUrl,
      token
    }
  )) as any
  return availablePlans
}
