import React, { useEffect } from 'react'
import { fireGiftingPurchaseEvent } from '../fire-gifting-purchase-event'
import { OrderConfirmation } from '#src/coffee-subscription/gifting/types'

export interface GiftingOrderConfirmationPurchaseEventProps {
  isAccountLoading: boolean
  orderConfirmation: OrderConfirmation
  shouldEnforceSingleFire?: boolean
  fireEvent?: typeof fireGiftingPurchaseEvent
}

let hasPageViewEventFired = false

export const GiftingOrderConfirmationPurchaseEvent: React.FC<GiftingOrderConfirmationPurchaseEventProps> =
  ({
    isAccountLoading,
    orderConfirmation,
    shouldEnforceSingleFire = false,
    fireEvent = fireGiftingPurchaseEvent
  }) => {
    useEffect(() => {
      if (
        !hasPageViewEventFired &&
        !isAccountLoading &&
        orderConfirmation?.lineItems?.length
      ) {
        fireEvent({ ...orderConfirmation })
        if (shouldEnforceSingleFire) {
          hasPageViewEventFired = true
        }
      }
    }, [
      fireEvent,
      isAccountLoading,
      orderConfirmation,
      shouldEnforceSingleFire
    ])

    return null
  }
