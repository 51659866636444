import ChevronLink from '#src/common/components/chevron-link'
import LocaleLink from '#src/common/components/locale-link'

const LinkHyperLink = (
  node,
  children,
  openHyperLinksInNewWindow,
  additionalOptions = {}
) => {
  const href = node.data.uri
  const { chevronLinks = false } = additionalOptions

  if (chevronLinks) {
    return <ChevronLink text={children} link={href} />
  }

  return (
    <LocaleLink href={href}>
      <a
        data-testid='link-hyper-link'
        rel='noopener noreferrer'
        {...(openHyperLinksInNewWindow ? { target: '_blank' } : {})}
      >
        {children}
      </a>
    </LocaleLink>
  )
}

export default LinkHyperLink
